
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {USER} from '../actions/user';

class User extends  BaseReducer {
    constructor() {
        super({
            token: null,
            data: null,
            cookiesAcceptance: [],
        });

        this.actionMap = {
            [USER.LOGIN_SUCCESS]: this.loginSuccess.bind(this),
            [USER.LOGOUT]: this.logout.bind(this),
            [`${USER.GET_CURRENT_USER}_SUCCESS`]: this.getCurrentUser.bind(this),
            [USER.REFRESH_TOKEN_SUCCESS]: this.loginSuccess.bind(this),
            [USER.CHANGE_COOKIE_ACCEPTANCE]: this.changeCookieAcceptance.bind(this),
        };
    }

    logout(state, action) {
        return {
            ...state,
            token: null,
            data: null,
        };
    }

    loginSuccess(state, action) {
        return {
            ...state,
            token: action.payload,
        };
    }

    getCurrentUser(state, action) {
        const payload = action.payload;
        delete payload.resultStatus;
        return {
            ...state,
            data: payload
        };
    }

    changeCookieAcceptance(state, action) {
        return Object.assign({}, state, {
            cookiesAcceptance: action.cookies
        });
    }
}

export default new User().reducer;
