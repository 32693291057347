
/* injects from baggage-loader */

import idx from 'idx';

import { IClubDataValidationRequest } from '../contracts/store';
import {apiActionCreator} from '../utils/api/apiActionCreator';
import {API_URL, API_URL_V2} from '../utils/api/constants';
import {getSearchFilterHash} from '../utils/search/searchFilterHash';
import {IEventFilterParams} from '../contracts/event';
import {getFilterWithCategoryId} from '../selectors/shows';
import { isBoxOfficeUser } from '../selectors/user';
import {PAGE_SIZE} from '../constants/shows';
import {setSearchVouchers} from './voucher';

export const SHOW = {
    GET_SHOWS_REQUEST: 'GET_SHOWS_REQUEST',
    GET_SHOWS_SUCCESS: 'GET_SHOWS_SUCCESS',
    GET_SHOWS_FAILURE: 'GET_SHOWS_FAILURE',
    GET_SHOWS_BY_EVENT: 'GET_SHOWS_BY_EVENT',
    GET_SHOW: 'GET_SHOW',
    GET_SHOW_SEARCH_PRICE_RANGE_REQUEST: 'GET_SHOW_SEARCH_PRICE_RANGE_REQUEST',
    GET_SHOW_SEARCH_PRICE_RANGE_SUCCESS: 'GET_SHOW_SEARCH_PRICE_RANGE_SUCCESS',
    GET_SHOW_SEARCH_PRICE_RANGE_FAILURE: 'GET_SHOW_SEARCH_PRICE_RANGE_FAILURE',
    VALIDATE_CLUB_DATA: 'VALIDATE_CLUB_DATA',
    UPDATE_SEARCH_FRAGMENT: 'UPDATE_SEARCH_FRAGMENT',
    SHOW_HAS_PRESALE: {
        GET: 'SHOW_HAS_PRESALE',
        REQUEST: 'SHOW_HAS_PRESALE_REQUEST',
        SUCCESS: 'SHOW_HAS_PRESALE_SUCCESS',
        FAILURE: 'SHOW_HAS_PRESALE_FAILURE',
        RESET_ERROR: 'SHOW_HAS_PRESALE_RESET_ERROR',
    },
    GET_PERSONAL_DISCOUNTS: 'GET_PERSONAL_DISCOUNTS',
    GET_SEARCH_SIMPLE_REQUEST: 'GET_SEARCH_SIMPLE_REQUEST',
    GET_SEARCH_SIMPLE_SUCCESS: 'GET_SEARCH_SIMPLE_SUCCESS',
    GET_SEARCH_SIMPLE_FAILURE: 'GET_SEARCH_SIMPLE_FAILURE',
};

export function searchByFragmentSimple(query: string, shouldMaybeSkip: boolean = true, forceReload: boolean = false) {
    return (dispatch, getState) => {
        if (query.length <= 2) { // less then two letters wont to go server
            return dispatch(updateSearchFragment(query));
        }

        const state = getState();
        const body: IEventFilterParams = {
            fragment: query,
        };
        body.boxOffice = isBoxOfficeUser(state);
        body.take = body.take || 10;
        body.skip = body.skip || 0;

        const searchHash = getSearchFilterHash(body);
        const skip = idx(state, _ => _.shows.page[searchHash]);
        if (skip && shouldMaybeSkip) {
            body.skip = state.shows.page[searchHash].length;
        }

        dispatch(updateSearchFragment(query));
        dispatch(apiActionCreator({
            endpoint: API_URL_V2 + '/event/show/searchbyfragmentsimple',
            method: 'GET',
            type: [
                {
                    type: SHOW.GET_SEARCH_SIMPLE_REQUEST,
                    payload: (action, state, res) => ({searchHash}),
                },
                {
                    type: SHOW.GET_SEARCH_SIMPLE_SUCCESS,
                    payload: (action, state, res) => {
                        return res.json().then((json) => {
                            json.filter = body;
                            json.searchHash = searchHash;
                            json.forceReload = forceReload;
                            return json;
                        });
                    }
                },
                {
                    type: SHOW.GET_SEARCH_SIMPLE_FAILURE,
                    payload: (action, state, res) => ({searchHash}),
                },
            ],
            body,
            bailout: (state) => {
                if (forceReload) {
                    return false;
                }
            }
        }));
    };
}

export function searchByFragment(query: string, shouldMaybeSkip: boolean = true, forceReload: boolean = false, onSuccess: any = () => {}) {
    return (dispatch, getState) => {
        if (query.length <= 2) { // less then two letters wont to go server
            return dispatch(updateSearchFragment(query));
        }

        const state = getState();
        const body: IEventFilterParams = {
            fragment: query,
        };
        body.boxOffice = isBoxOfficeUser(state);
        body.take = body.take || PAGE_SIZE;
        body.skip = body.skip || 0;

        const searchHash = getSearchFilterHash(body);
        const skip = idx(state, _ => _.shows.page[searchHash]);
        if (skip && shouldMaybeSkip) {
            body.skip = state.shows.page[searchHash].length;
        }

        dispatch(updateSearchFragment(query));
        dispatch(apiActionCreator({
            endpoint: API_URL + '/event/show/searchbyfragment',
            method: 'GET',
            type: [
                {
                    type: SHOW.GET_SHOWS_REQUEST,
                    payload: (action, state, res) => ({searchHash}),
                },
                {
                    type: SHOW.GET_SHOWS_SUCCESS,
                    payload: (action, state, res) => {
                        return res.json().then((json) => {
                            json.filter = body;
                            json.searchHash = searchHash;
                            json.forceReload = forceReload;
                            dispatch(setSearchVouchers(json.giftVouchers, searchHash));
                            onSuccess();
                            return json;
                        });
                    }
                },
                {
                    type: SHOW.GET_SHOWS_FAILURE,
                    payload: (action, state, res) => ({searchHash}),
                },
            ],
            body,
            bailout: (state) => {
                if (forceReload) {
                    return false;
                }
                const shows = state.shows && state.shows.page && state.shows.page[searchHash];
                if (!shows || shows.length === 0) {
                    return false;
                }
                const vouchers = state.vouchers && state.vouchers.search && state.vouchers.search[searchHash];
                if (!vouchers || vouchers.length === 0) {
                    return false;
                }
                return shows.length > body.skip + body.take || body.take > shows.length;
            }
        }));
    };
}


export function getShows(_body?: IEventFilterParams) {
    return (dispatch, getState) => {
        const searchHash = getSearchFilterHash(_body);
        const body = Object.assign({}, _body);

        body.boxOffice = isBoxOfficeUser(getState());
        body.take = body.take || PAGE_SIZE;
        body.skip = body.skip || 0;

        if (body.skip === 0) {
            dispatch(getShowSearchPriceRange(body, searchHash));
        }
        dispatch(apiActionCreator({
            endpoint: API_URL + '/event/show',
            method: 'GET',
            type: [
                {
                    type: SHOW.GET_SHOWS_REQUEST,
                    payload: (action, state, res) => ({searchHash}),
                },
                {
                    type: SHOW.GET_SHOWS_SUCCESS,
                    payload: (action, state, res) => {
                        return res.json().then((json) => {
                            json.filter = body;
                            json.searchHash = searchHash;
                            return json;
                        });
                    }
                },
                {
                    type: SHOW.GET_SHOWS_FAILURE,
                    payload: (action, state, res) => ({searchHash}),
                },
            ],
            body,
            bailout: (state) => {
                const shows = state.shows && state.shows.page && state.shows.page[searchHash];
                if (!shows) {
                    return false;
                }
                return shows.length > body.skip + body.take;
            }
        }));
    };
}

export function getShowsByCategoryId(categoryId) {
    return (dispatch, getState) => {
        const body = getFilterWithCategoryId(categoryId);
        const searchHash = getSearchFilterHash(body);
        const state = getState();
        const skip = idx(state, _ => _.shows.page[searchHash]);
        if (skip) {
            body.skip = state.shows.page[searchHash].length;
        }
        dispatch(getShows(body));
    };
}

export function getShowByEventId(eventShortId: number) {
    return (dispatch, getState) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/event/show',
            method: 'GET',
            type: SHOW.GET_SHOWS_BY_EVENT,
            body: {
                eventShortId,
                boxOffice: isBoxOfficeUser(getState())
            },
            bailout: (state) => {
                if (!state.shows.events[eventShortId] || !state.events.byId[eventShortId]) {
                    return false;
                }
                return state.shows.events[eventShortId].length === state.events.byId[eventShortId].numberOfShows;
            }
        }));
    };
}

export function getShow(showShortId: number, onErrorCb?: (args) => void) {
    return (dispatch, getState) => {
        const _isBoxOfficeUser = isBoxOfficeUser(getState());
        dispatch(apiActionCreator({
            endpoint: `${API_URL}/event/show/${showShortId}?boxOffice=${_isBoxOfficeUser}`,
            method: 'GET',
            type: SHOW.GET_SHOW,
            onError: onErrorCb,
            bailout: (state) => {
                return !!state.shows.byId[showShortId];
            }
        }));
    };
}

export function searchShows(body?: IEventFilterParams) {
    return (dispatch, getState) => {
        const filters: IEventFilterParams = body;
        const searchHash = getSearchFilterHash(filters);
        const state = getState();
        const skip = idx(state, _ => _.shows.page[searchHash]);
        if (skip) {
            filters.skip = state.shows.page[searchHash].length;
        }
        dispatch(getShows(filters));
    };
}

export function getShowSearchPriceRange(body: IEventFilterParams = {}, searchHash?: string) {
    const filters = body;
    return apiActionCreator({
        endpoint: API_URL + '/event/show/pricefilter',
        method: 'GET',
        type: [
            SHOW.GET_SHOW_SEARCH_PRICE_RANGE_REQUEST,
            {
                type: SHOW.GET_SHOW_SEARCH_PRICE_RANGE_SUCCESS,
                payload: (action, state, res) => {
                    return res.json().then((json) => {
                        json.searchHash = searchHash;
                        return json;
                    });
                }
            },
            SHOW.GET_SHOW_SEARCH_PRICE_RANGE_FAILURE,
        ],
        body: filters
    });
}

export function validateClubData(
    body: IClubDataValidationRequest,
    onSuccessCallback?: (args) => void,
    onErrorCallback?: (args) => void,
) {
    return (dispatch, getState) => {
        dispatch(apiActionCreator({
            endpoint: `${API_URL}/event/show/hasclub`,
            method: 'GET',
            type: SHOW.VALIDATE_CLUB_DATA,
            body,
            onSuccess: onSuccessCallback,
            onError: onErrorCallback,
        }));
    };
}

export function updateSearchFragment(fragment: string) {
    return {
        type: SHOW.UPDATE_SEARCH_FRAGMENT,
        searchFragment: fragment,
    };
}

export function showHasPreSale(showId: string, preSaleCode: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/event/show/haspresale`,
        method: 'GET',
        type: [
            SHOW.SHOW_HAS_PRESALE.REQUEST,
            {
                type: SHOW.SHOW_HAS_PRESALE.SUCCESS,
                payload: (action, state, res) => {
                    return res.json().then((json) => {
                        // if (json === true) {
                        //     onShowHasPreSale();
                        // }
                        return {
                            showHasPreSale: json,
                            preSaleCode,
                            showId
                        };
                    });
                }
            },
            SHOW.SHOW_HAS_PRESALE.FAILURE,
        ],
        body: {
            showId,
            presaleCode: preSaleCode,
        }
    });
}

export function resetPreSaleError(showId: string) {
    return {
        type: SHOW.SHOW_HAS_PRESALE.RESET_ERROR,
        showId
    };
}

export function getPersonalShowDiscounts() {
    return apiActionCreator({
        endpoint: `${API_URL}/event/show/personaldiscounts`,
        method: 'GET',
        type: SHOW.GET_PERSONAL_DISCOUNTS
    });
}
