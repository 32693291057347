
/* injects from baggage-loader */

import ApolloClient from 'apollo-boost';
import {GRAPH_QL_URI} from './constants';
import {log} from '../logger';

export function getGraphQLClient(promise) {
    return new ApolloClient({
        uri: GRAPH_QL_URI,
        request: async operation => {
            try {
                const token = await promise.catch((error) => {
                    log(error);
                });
                if (token) {
                    operation.setContext({
                        headers: {
                            Authorization: token ? `Bearer ${token}` : ''
                        }
                    });
                }
            } catch (error) {
                log(error);
            }
        }
    });
}
