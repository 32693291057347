
/* injects from baggage-loader */

declare const cashRegister: any;

export class CashRegister {
    static printReceipt = (receipt, { onSuccess = () => { }, onError = (e) => { }, printCardInfo = false }) => {
        try {
            cashRegister.printReceipt(receipt, printCardInfo);
            onSuccess && onSuccess();
        } catch (e) {
            onError && onError(e);
        }
    }

    static printTickets = (tickets, {onSuccess = () => {}, onError = (e) => {}}) => {
        try {
            cashRegister.printTickets(tickets);
            onSuccess && onSuccess();
        } catch (e) {
            onError && onError(e);
        }
    }
}
