
/* injects from baggage-loader */

import * as moment from 'moment';
import * as queryString from 'query-string';
import {RSAA, getJSON} from 'redux-api-middleware';
import {apiActionCreator} from '../utils/api/apiActionCreator';
import {Translation} from '../utils/Translation/Translation';
import { isBoxOfficeMirror, isBoxOfficeAds } from '../selectors/boxOffice';
import {hasScreenMirroring, isClubMember} from '../selectors/user';
import browserStorageUtils from '../utils/browserStorage/utils';
import {TOKEN_KEY, REMEMBER_ME, SESSION_ID, COOKIES_ACCEPTANCE} from '../utils/browserStorage/constants';
import {IUser} from '../contracts/user';
import {API_URL, URL} from '../utils/api/constants';
import {getFavorites} from './favorite';
import {v4 as uuid} from 'uuid';
import {moveCart} from './cart';
import {clearClubLodgeEventBlocks} from './event';
import {hideTopModal} from './modal';
import {reset} from 'redux-form';
import {history} from '../history';
import {urls} from '../routes';
import {log} from '../utils/logger';
import {getPersonalShowDiscounts} from './show';

const browserStorage = browserStorageUtils.getAvailableStorage();

const CURRENT_LANG = Translation.getCurrentLanguage();

export const USER = {
    INIT_USER_REQUEST: 'INIT_USER_REQUEST',
    INIT_USER_SUCCESS: 'INIT_USER_SUCCESS',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
    REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
    LOGOUT: 'LOGOUT',

    REGISTER_USER: 'REGISTER_USER',
    EXISTS_USER: 'EXISTS_USER',
    ACTIVATE_USER: 'ACTIVATE_USER',
    GET_CURRENT_USER: 'GET_CURRENT_USER',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD_USER',
    REMIND_PASSWORD: 'REMIND_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    CLEAR_INVOICE_INFO: 'CLEAR_INVOICE_INFO',
    UPDATE_DATA_USER_REQUEST: 'UPDATE_DATA_USER_REQUEST',
    UPDATE_DATA_USER_SUCCESS: 'UPDATE_DATA_USER_SUCCESS',
    UPDATE_DATA_USER_FAILURE: 'UPDATE_DATA_USER_FAILURE',
    CHANGE_COOKIE_ACCEPTANCE: 'CHANGE_COOKIE_ACCEPTANCE',
};

export function initUser(): any {
    const browserStorage = browserStorageUtils.getAvailableStorage();
    // create sessionId if it's not exists
    const sessionId = browserStorage.getItem(SESSION_ID);
    if (!sessionId) {
        const UUID = uuid();
        browserStorage.setItem(SESSION_ID, UUID);
    }
    // refresh user information if we have token
    const userToken = browserStorage.getItem(TOKEN_KEY);
    const rememberMe = browserStorage.getItem(REMEMBER_ME);
    const cookiesAcceptance = browserStorage.getItem(COOKIES_ACCEPTANCE) || [];
    return (dispatch, getState) => {
        dispatch({
            type: USER.INIT_USER_REQUEST
        });
        dispatch(changeCookiesAcceptance(cookiesAcceptance));
        if (userToken && userToken.access_token) {
            if (rememberMe === true || moment.utc(userToken['.expires']) > moment.utc(new Date())) {
                if (isBoxOfficeMirror(getState())) {
                    dispatch({
                        type: USER.LOGIN_SUCCESS,
                        payload: userToken,
                    });
                    dispatch(getCurrentUser());
                } else {
                    dispatch(refreshAccessToken(userToken));
                }
            } else {
                dispatch(logoutUser());
            }
        }
        dispatch({
            type: USER.INIT_USER_SUCCESS
        });
    };
}

export function updateDataUser(data: IUser) {
    return (dispatch) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/purchaser',
            method: 'PUT',
            type: [
                USER.UPDATE_DATA_USER_REQUEST,
                {
                    type: USER.UPDATE_DATA_USER_SUCCESS,
                    payload: () => {
                        dispatch(getCurrentUser());
                    }
                },
                USER.UPDATE_DATA_USER_FAILURE
            ],
            body: data
        }));
    };
}

export function clearInvoiceInfo() {
    return (dispatch) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/purchaser/invoiceInfo',
            method: 'DELETE',
            type: USER.CLEAR_INVOICE_INFO,
            onSuccess: () => {
                dispatch(getCurrentUser());
            }
        }));
    };
}

export function remindPassword(email: string) {
    return (dispatch) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/purchaser/requestPasswordReminder',
            method: 'POST',
            type: USER.REMIND_PASSWORD,
            body: email,
            onSuccess: () => {
                dispatch(hideTopModal());
            }
        }));
    };
}
export function resetPassword(passwordReminderId, newPassword) {
    return (dispatch) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/purchaser/resetPassword',
            method: 'POST',
            type: USER.RESET_PASSWORD,
            body: {
                passwordReminderId,
                newPassword
            },
            onSuccess: () => {
                dispatch(hideTopModal());
            }
        }));
    };
}
export function activateUser(activationId: string) {
    return apiActionCreator({
        endpoint: API_URL + '/purchaser/activate',
        method: 'POST',
        type: USER.ACTIVATE_USER,
        body: activationId
    });
}

export function changePasswordUser(currentPassword, newPassword) {
    return (dispatch) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/purchaser/changePassword',
            method: 'POST',
            type: USER.CHANGE_PASSWORD,
            body: {
                currentPassword,
                newPassword
            },
            onSuccess: () => {
                dispatch(reset('user_change_password'));
            }
        }));
    };
}

export function logoutUser() {
    return (dispatch, getState) => {
        const state = getState();

        browserStorage.setItem(TOKEN_KEY, null);
        browserStorage.setItem(REMEMBER_ME, null);
        browserStorage.setItem(SESSION_ID, uuid());
        history.push(urls.get('home'));

        if (isClubMember(state)) {
            dispatch(clearClubLodgeEventBlocks());
        }

        dispatch({
            type: USER.LOGOUT
        });
    };
}


export function refreshAccessToken(userToken: any): any {
    const sessionId = browserStorage.getItem(SESSION_ID);
    return (dispatch, getState) => {
        if (isBoxOfficeMirror(getState()) || isBoxOfficeAds(getState())) {
            return;
        }
        const body = {
            client_id: userToken.client_id,
            grant_type: 'refresh_token',
            refresh_token: userToken.refresh_token,
        };
        dispatch({
            [RSAA]: {
                endpoint: URL + '/Token',
                method: 'POST',
                types: [
                    USER.REFRESH_TOKEN_REQUEST,
                    {
                        type: USER.REFRESH_TOKEN_SUCCESS,
                        payload: (action, state, res, meta) => {
                            const response = getJSON(res);
                            response.then((payload) => {
                                browserStorage.setItem(TOKEN_KEY, payload);
                                if (state.user && !state.user.data) {
                                    dispatch(getCurrentUser());
                                    dispatch(getFavorites());
                                    dispatch(moveCart(sessionId));
                                    dispatch(getPersonalShowDiscounts());
                                }
                                setTimeout(() => {
                                    dispatch(refreshAccessToken(payload));
                                }, (payload.expires_in * 1000) / 2);
                            });
                            return response;
                        }
                    },
                    {
                        type: USER.REFRESH_TOKEN_FAILURE,
                        payload: (action, state, res, meta) => {
                            const tokenData = browserStorage.getItem(TOKEN_KEY);
                            const response = getJSON(res);
                            log({
                                tokenData,
                                response,
                            });
                            dispatch(logoutUser());
                            return response;
                        }
                    }
                ],
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Accept-Language': CURRENT_LANG,
                    'Session-Id': sessionId
                },
                body: queryString.stringify(body),
            }
        });
    };
}

export function loginUser(username: string, password: string, rememberMe: boolean) {
    const sessionId = browserStorage.getItem(SESSION_ID);
    return (dispatch) => {
        const body = {
            client_id: uuid(),
            grant_type: 'password',
            password,
            username,
        };
        dispatch({
            [RSAA]: {
                endpoint: URL + '/Token',
                method: 'POST',
                types: [
                    USER.LOGIN_REQUEST,
                    {
                        type: USER.LOGIN_SUCCESS,
                        payload: (action, state, res) => {
                            const response = getJSON(res);
                            response.then((payload) => {
                                browserStorage.setItem(TOKEN_KEY, payload);
                                if (rememberMe) {
                                    browserStorage.setItem(REMEMBER_ME, true);
                                }
                                dispatch(getCurrentUser());
                                dispatch(getFavorites());
                                dispatch(moveCart(sessionId, true));
                                dispatch(hideTopModal());
                                dispatch(getPersonalShowDiscounts());
                                browserStorage.setItem(SESSION_ID, uuid());

                                setTimeout(() => {
                                    dispatch(refreshAccessToken(payload));
                                }, (payload.expires_in * 1000) / 2);
                            });
                            return response;
                        }

                    },
                    USER.LOGIN_FAILURE
                ],
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    // BBC: Bent jau pagal dabartinę logiką Autohization headeris nebūtinas (nežinau, ką Rolandas numatęs ateityje).
                    //      Bet jei nurodot, tai Authorization headeryje client_id turi būti toks pat kaip ir contente (tas UUID).
                    // 'Authorization': `Basic ${btoa(`${body.client_id}:${password}`)}`,
                    'Accept-Language': CURRENT_LANG,
                    'Session-Id': sessionId
                },
                body: queryString.stringify(body),
            }
        });
    };
}

export function loginUserViaFacebook(token: string): any {
    const sessionId = browserStorage.getItem(SESSION_ID);
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: URL + '/Token',
                method: 'POST',
                types: [
                    USER.LOGIN_REQUEST,
                    {
                        type: USER.LOGIN_SUCCESS,
                        payload: (action, state, res) => {
                            const response = getJSON(res);
                            response.then((payload) => {
                                browserStorage.setItem(TOKEN_KEY, payload);
                                if (state.user && !state.user.data) {
                                    dispatch(getCurrentUser());
                                    dispatch(getFavorites());
                                    dispatch(moveCart(sessionId, true));
                                    dispatch(getPersonalShowDiscounts());
                                }

                                dispatch(hideTopModal());
                                browserStorage.setItem(SESSION_ID, uuid());

                                setTimeout(() => {
                                    dispatch(refreshAccessToken(payload));
                                }, (payload.expires_in * 1000) / 2);
                            });
                            return response;
                        }

                    },
                    USER.LOGIN_FAILURE
                ],
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Authorization': 'Basic MTo=',
                    'Accept-Language': CURRENT_LANG,
                    'Session-Id': sessionId
                },
                body: `token=${encodeURIComponent(token)}&type=facebook&grant_type=external`
            }
        });
    };
}

export function loginUserViaExternal(token: string, authType: 'facebook' | 'google'): any {
    const sessionId = browserStorage.getItem(SESSION_ID);
    return (dispatch) => {
        dispatch({
            [RSAA]: {
                endpoint: URL + '/Token',
                method: 'POST',
                types: [
                    USER.LOGIN_REQUEST,
                    {
                        type: USER.LOGIN_SUCCESS,
                        payload: (action, state, res) => {
                            const response = getJSON(res);
                            response.then((payload) => {
                                browserStorage.setItem(TOKEN_KEY, payload);
                                if (state.user && !state.user.data) {
                                    dispatch(getCurrentUser());
                                    dispatch(getFavorites());
                                    dispatch(moveCart(sessionId, true));
                                    dispatch(getPersonalShowDiscounts());
                                }

                                dispatch(hideTopModal());
                                browserStorage.setItem(SESSION_ID, uuid());

                                setTimeout(() => {
                                    dispatch(refreshAccessToken(payload));
                                }, (payload.expires_in * 1000) / 2);
                            });
                            return response;
                        }

                    },
                    USER.LOGIN_FAILURE
                ],
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Authorization': 'Basic MTo=',
                    'Accept-Language': CURRENT_LANG,
                    'Session-Id': sessionId
                },
                body: `token=${encodeURIComponent(token)}&type=${authType}&grant_type=external`
            }
        });
    };
}

export function registerUser(email: string, password: string, cityId: number, birthYear: number, sendCommercialAccepted: boolean) {
    return (dispatch) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/purchaser',
            method: 'POST',
            type: USER.REGISTER_USER,
            body: {
                email,
                password,
                cityId,
                birthYear,
                sendCommercialAccepted,
                rulesAccepted: true,
                lc: CURRENT_LANG
            },
            onSuccess: () => {
                dispatch(hideTopModal());
            }
        }));
    };
}
export function getCurrentUser() {
    return (dispatch, getState) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/purchaser',
            method: 'GET',
            type: USER.GET_CURRENT_USER,
            onSuccess: (args) => {
                if (args && args.isClubMember) {
                    dispatch(clearClubLodgeEventBlocks());
                }
            }
        }));
    };
}
export function existsUser(email: string) {
    return apiActionCreator({
        endpoint: API_URL + '/purchaser/exists',
        method: 'GET',
        type: USER.EXISTS_USER,
        body: {
            email
        }
    });
}

export function changeCookiesAcceptance(cookies: Array<string>) {
    return {
        type: USER.CHANGE_COOKIE_ACCEPTANCE,
        cookies,
    };
}
