
/* injects from baggage-loader */

import * as queryString from 'query-string';
import {getSearchFilterHash} from '../utils/search/searchFilterHash';
import {IReduxStore} from '../contracts/store';
import {EventFilterParamsKeys, IEventFilterParams} from '../contracts/event';
import {stripTags} from '../utils/string';

export function getFilterWithCategoryId(categoryId): IEventFilterParams {
    const queryParams: any = getShowFilters();

    if (isNaN(categoryId)) {
        return queryParams;
    }

    if (queryParams.categories) {
        queryParams.categories.push(parseInt(categoryId, 10));
    } else {
        queryParams.categories = [parseInt(categoryId, 10)];
    }
    return queryParams;
}

export function getShowFilters(): IEventFilterParams {
    let queryParams: object = {};
    if (window.location.search.length) {
        queryParams = queryString.parse(window.location.search);
    }
    for (const param in queryParams) {
        if (!EventFilterParamsKeys.includes(param as never)) {
            delete queryParams[param];
        }
    }
    return queryParams;
}

export function getShowsByFilter(state: IReduxStore, filter: IEventFilterParams) {
    const searchHash = getSearchFilterHash(filter);
    return state.shows.page[searchHash] || [];
}

export function getEventBlockByFilter(state: IReduxStore, filter: IEventFilterParams) {
    const searchHash = getSearchFilterHash(filter);
    return state.shows.eventBlocks[searchHash] || [];
}

export function getEventGroupsByFilter(state: IReduxStore, filter: IEventFilterParams) {
    const searchHash = getSearchFilterHash(filter);
    return (state.shows.searchResults.eventGroups && state.shows.searchResults.eventGroups[searchHash]) || [];
}

export function getPreSaleCode(state: IReduxStore, showId: string) {
    return state.shows.preSaleCodes[showId];
}

export function getSearchResultsByFilter(state: IReduxStore, filter: IEventFilterParams) {
    const searchHash = getSearchFilterHash(filter);
    return (state.shows.searchResults.page && state.shows.searchResults.page[searchHash]) || [];
}

export function getShowWithoutTags(state: IReduxStore, showShortId) {
    if (showShortId) {
        const show = state.shows.byId[showShortId];
        if(!show) return show;
        return {
            ...show,
            eventTitle: stripTags(show.eventTitle),
            location: show.location ? {
                ...show.location,
                name: stripTags(show.location.name),
            } : null,
            city: show.city ? {
                ...show.city,
                name: stripTags(show.city.name),
            } : null,
        }
    }
    return null;
}
