
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {TICKET} from '../actions/ticket';
import {IDiscounts} from '../contracts/tickets';
import {VENUE} from '../actions/venue';
import {CART} from '../actions/cart';

class Discounts extends BaseReducer {
    static defaultState: IDiscounts = {
        byId: {},
        byTicketId: {},
        ids: [],
        activeDiscountsByTicket: {}
    };
    constructor() {
        super(Discounts.defaultState);
        this.actionMap = {
            [`${TICKET.ADD_TICKET}_SUCCESS`]: this.addDiscounts.bind(this),
            [`${TICKET.UPDATE_TICKETS}_SUCCESS`]: this.addDiscounts.bind(this),
            [`${TICKET.REMOVE_TICKET}_SUCCESS`]: this.maybeRemoveDiscounts.bind(this),
            // [`${TICKET.RESET_TICKETS}`]: this.removeDiscounts.bind(this),
            [`${TICKET.RESET_API_TICKETS}_SUCCESS`]: this.removeDiscounts.bind(this),
            [`${CART.ADD}_SUCCESS`]: this.setActiveDiscount.bind(this),
            [`${CART.GET}_SUCCESS`]: this.setActiveDiscount.bind(this),
            [`${CART.SET_DISCOUNT}_SUCCESS`]: this.setActiveDiscount.bind(this),
            [`${CART.DELETE}_SUCCESS`]: this.setActiveDiscount.bind(this),
            [`${CART.GET_DISCOUNT}_SUCCESS`]: this.addTicketDiscounts.bind(this),
            [`${TICKET.REMOVE_DISCOUNT}_SUCCESS`]: this.removeActiveDiscount.bind(this),
        };
    }

    setActiveDiscount(state, action) {
            let activeDiscounts = {}
            if (action.payload.shows) {
                action.payload.shows.forEach(show => {
                    show.priceCategories.forEach(priceCategory => {
                        priceCategory.tickets.forEach(ticket => {
                            if (ticket.discount) {
                                activeDiscounts[ticket.id]= ticket.discount;
                            }
                        })
                    })
                })
            }
            return {
                ...state,
                activeDiscountsByTicket: activeDiscounts
            }
    }

    removeActiveDiscount(state, action) {
        return {
            ...state,
            activeId: null
        };
    }

    addDiscounts(state, action) {
        if (!(action.payload.discounts && action.payload.discounts.length)) {
            return state;
        }
        const discountIds = action.payload.discounts.map((discount) => discount.id);
        let discountsById = {};
        discountsById = action.payload.discounts.reduce((discounts, discount) => {
            discounts[discount.id] = discount;
            return discounts;
        }, discountsById);

        return {
            ...state,
            ids: discountIds,
            byId: {
                ...state.byId,
                ...discountsById
            }
        };
    }

    maybeRemoveDiscounts(state, action) {
        if (action.payload.tickets && action.payload.tickets.length <= 0) {
            return this.removeDiscounts(state, action);
        }
        return this.addDiscounts(state, action);
    }

    removeDiscounts(state, action) {
        return Discounts.defaultState;
    }

    addTicketDiscounts(state, action) {
        if (!(action.payload.discounts && action.payload.discounts.length)) {
            return state;
        }
        const discountArrays = action.payload.discounts.map((discount) => discount.discounts);
        const discountIds = discountArrays.flat(1).map((discount) => discount.id);
        console.log('discountIds', discountIds);
        let discountsById = {};
        discountsById = action.payload.discounts.reduce((discounts, discount) => {
            discounts[discount.ticketId] = discount.discounts;
            return discounts;
        }, discountsById);

        return {
            ...state,
            ids: discountIds,
            byTicketId: {
                ...state.byTicketId,
                ...discountsById
            },
        };
    }
}

export default new Discounts().reducer;
