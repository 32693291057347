
/* injects from baggage-loader */

import {RSAA, getJSON} from 'redux-api-middleware';
import {apiActionCreator} from '../utils/api/apiActionCreator';
import {API_URL} from '../utils/api/constants';

export const CLASSIFICATION = {
    GET_CITIES: 'GET_CITIES',
    GET_CITIES_WITH_SHOWS: 'GET_CITIES_WITH_SHOWS',
    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_SEO_LINKS: 'GET_SEO_LINKS',
};

// we are using promise here so we can be sure that API is working
// get cities are used for initialization of application
export function getCities(): any {
    return (dispatch) => new Promise((resolve) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/class/city',
            method: 'GET',
            type: CLASSIFICATION.GET_CITIES,
            onSuccess: resolve
        }));
    });
}

export function getCitiesWithShows() {
    return apiActionCreator({
        endpoint: API_URL + '/class/city?withShows=true',
        method: 'GET',
        type: CLASSIFICATION.GET_CITIES_WITH_SHOWS,
    });
}

export function getCategories() {
    return apiActionCreator({
        endpoint: API_URL + '/class/event/category',
        method: 'GET',
        type: CLASSIFICATION.GET_CATEGORIES
    });
}

export function getSeoLinks() {
    return apiActionCreator({
        endpoint: API_URL + '/link',
        method: 'GET',
        type: CLASSIFICATION.GET_SEO_LINKS
    });
}
