
/* injects from baggage-loader */

import * as React from 'react';
import {connect} from 'react-redux';
import { isAuthenticated, isBoxOfficeUser } from '../../../../selectors/user';
import {Redirect} from 'react-router-dom';
import { urls } from '../../../../routes';

/**
 * Higher order component to pass only non-authenticated users
 * @param Component
 * @returns {ComponentClass<Omit<TInjectedProps, keyof {isAuthenticated: boolean} & DispatchProp<any> & {}> & {}> & {WrappedComponent: Component<TInjectedProps>}}
 */
export function notSecureComponent(Component) {
    class NotSecuredComponent extends React.Component<{isAuthenticated: boolean; isBoxOfficeUser: boolean}> {

        render() {
            let continueUrl = urls.get('home');
            if (window.location.search.length > 0) {
                const params = new URLSearchParams(window.location.search);
                if (params.has('continue')) {
                    continueUrl = params.get('continue');
                }
            }
            return (
                !this.props.isAuthenticated ? <Component {...this.props} /> : <Redirect to={continueUrl}/>
            );
        }
    }

    const mapStateToProps = (state) => ({
        isAuthenticated: isAuthenticated(state)
    });
    return connect(mapStateToProps)(NotSecuredComponent as any);
}
