
/* injects from baggage-loader */

const DEFAULT_LANGUAGE = 'lt';
const FALLBACK_LANGUAGE = 'lt';
const ACTIVE_LANGUAGES = ['lt', 'en'];
const LANGUAGE_LOCALE_MAP = {lt: 'lt_LT', en: 'en_US'};
const ACTIVE_LANGUAGES_OBJ = {lt: '', en: ''};

export { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE, ACTIVE_LANGUAGES, LANGUAGE_LOCALE_MAP, ACTIVE_LANGUAGES_OBJ };
