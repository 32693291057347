
/* injects from baggage-loader */

import { IReduxStore } from '../contracts/store';
import { ICart } from '../contracts/cart';
import { IOrder } from '../contracts/order';

export function getCartTicketCount(cart: ICart|IOrder): number {
    if (!cart || !cart.shows || cart.shows.length <= 0) {
        return 0;
    }

    let total = 0;
    cart.shows.forEach((show) => {
        show.priceCategories.forEach((priceCategory) => {
            total += priceCategory.tickets.length;
        });
    });

    return total;
}

export function isCartEmpty(cart: ICart): boolean {
    return !cart || cart.number === 0;
}

export function getCartGiftVoucherCount(cart: ICart|IOrder): number {
    if ( !cart || !cart.giftVouchers || Object.keys(cart.giftVouchers).length <= 0) {
        return 0;
    }

    let total = 0;
    Object.keys(cart.giftVouchers).forEach((voucherCategoryGuid) => {
        total += cart.giftVouchers[voucherCategoryGuid].length;
    });

    return total;
}

export function cartIncludesOnlyVouchers(cart: ICart|IOrder): boolean {
    return !getCartTicketCount(cart) && !!getCartGiftVoucherCount(cart);
}

export function getCartItemCount(state: IReduxStore) {
    return getCartTicketCount(state.cart) + getCartGiftVoucherCount(state.cart);
}
