
/* injects from baggage-loader */

import {RSAA, getJSON} from 'redux-api-middleware';
import {apiActionCreator} from '../utils/api/apiActionCreator';
import {API_URL} from '../utils/api/constants';
import { isBoxOfficeUser } from '../selectors/user';

export const EVENT = {
    GET_EVENT_BLOCK: 'GET_EVENT_BLOCK',
    GET_CLUB_EVENT_BLOCKS: 'GET_CLUB_EVENT_BLOCKS',
    GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
    GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
    GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',
    GET_EVENT: 'GET_EVENT',
    GET_EVENTS: 'GET_EVENTS',
    CLEAR_CLUB_LODGE_EVENT_BLOCKS: 'CLEAR_CLUB_LODGE_EVENT_BLOCKS',
    GET_GROUP_EVENTS: 'GET_GROUP_EVENTS',
};

export function getEventsBlock(forceUpdate: boolean = false) {
    return (dispatch, getState) => {
        const _isBoxOfficeUser = isBoxOfficeUser(getState());
        dispatch(apiActionCreator({
            endpoint: `${API_URL}/event/block?boxOffice=${_isBoxOfficeUser}`,
            method: 'GET',
            type: EVENT.GET_EVENT_BLOCK,
            bailout: (state) => {
                return !forceUpdate && !!(state.events && state.events.home && state.events.home.length);
            }
        }));
    };
}

export function getClubLodgeEventsBlocks(forceUpdate: boolean = false) {
    return apiActionCreator({
        endpoint: API_URL + '/event/block/club',
        method: 'GET',
        type: EVENT.GET_CLUB_EVENT_BLOCKS,
        bailout: (state) => {
            return !forceUpdate && !!(state.events && state.events.club && state.events.club.length);
        }
    });
}

export function clearClubLodgeEventBlocks() {
    return {
        type: EVENT.CLEAR_CLUB_LODGE_EVENT_BLOCKS
    };
}

export function getEvent(shortId) {
    return (dispatch, getState) => {
        const _isBoxOfficeUser = isBoxOfficeUser(getState());
        dispatch(apiActionCreator({
            endpoint: API_URL + `/event/${encodeURIComponent(shortId)}?boxOffice=${_isBoxOfficeUser}`,
            method: 'GET',
            type: EVENT.GET_EVENT,
            bailout: (state) => {
                return !!(state.events && state.events.byId && state.events.byId[shortId]);
            }
        }));
    };
}

export function getGroupEvents(groupId: string) {
    return apiActionCreator({
        endpoint: API_URL + '/event',
        method: 'GET',
        body: {
            eventGroupId: groupId,
        },
        type: EVENT.GET_GROUP_EVENTS
    });
}
