
/* injects from baggage-loader */

import { createBrowserHistory } from 'history';
import {pushPageView} from './utils/dataLayer/pageView';
const history = createBrowserHistory();

history.listen((location, action) => {
    pushPageView();
});

export { history };
