
/* injects from baggage-loader */

import {getSearchFilterHash} from '../utils/search/searchFilterHash';
import BaseReducer from './baseReducer';
import {SHOW} from '../actions/show';
import {EVENT} from '../actions/event';
import {USER} from '../actions/user';
import {normalizeEventBlock} from '../utils/event/normalizeEventBlock';

class Shows extends BaseReducer {
    constructor() {
        super({
            byId: {},
            events: {},
            page: {},
            searchFragment: '',
            searchPriceRange: {},
            preSaleCodes: {},
            personalDiscounts: [],
            eventBlocks: {},
            searchResults: {},
        });
        this.actionMap = {
            [SHOW.GET_SHOWS_SUCCESS]: this.getShows.bind(this),
            [`${SHOW.GET_SHOWS_BY_EVENT}_SUCCESS`]: this.getShowsByEvent.bind(this),
            [`${SHOW.GET_SHOW}_SUCCESS`]: this.getShow.bind(this),
            [SHOW.GET_SHOW_SEARCH_PRICE_RANGE_SUCCESS]: this.getShowSearchPriceRange.bind(this),
            [SHOW.UPDATE_SEARCH_FRAGMENT]: this.updateSearchFragment.bind(this),
            [`${EVENT.GET_EVENT_BLOCK}_SUCCESS`]: this.getEventBlock.bind(this),
            [`${EVENT.GET_CLUB_EVENT_BLOCKS}_SUCCESS`]: this.getEventBlock.bind(this),
            [SHOW.SHOW_HAS_PRESALE.SUCCESS]: this.setPreSaleCode.bind(this),
            [SHOW.SHOW_HAS_PRESALE.RESET_ERROR]: this.resetPreSaleCode.bind(this),
            [`${SHOW.GET_PERSONAL_DISCOUNTS}_SUCCESS`]: this.getPersonalDiscounts.bind(this),
            [USER.LOGOUT]: this.resetPersonalDiscounts.bind(this),
            [SHOW.GET_SEARCH_SIMPLE_SUCCESS]: this.getSearchSimple.bind(this),
        };
    }

    getEventBlock(state, action) {
        if (!action.payload.eventBlocks) {
            return state;
        }
        let shows = {};
        action.payload.eventBlocks.forEach((eventBlock) => {
            if (eventBlock.shows) {
                shows = eventBlock.shows.reduce((shows, show) => {
                    shows[show.shortId] = show;
                    return shows;
                }, shows);
            }
        });
        return {
            ...state,
            byId: {
                ...state.byId,
                ...shows
            }
        };
    }

    resetPreSaleCode(state, action) {
        return {
            ...state,
            preSaleCodes: {
                ...state.preSaleCode,
                [action.showId]: null
            },
        };
    }

    setPreSaleCode(state, action: { payload: { showId: number; showHasPreSale: boolean; preSaleCode: string; } }) {
        return {
            ...state,
            preSaleCodes: {
                ...state.preSaleCode,
                [action.payload.showId]: action.payload.showHasPreSale && action.payload.preSaleCode
            },
        };
    }

    updateSearchFragment(state, action) {
        return {
            ...state,
            searchFragment: action.searchFragment,
        };
    }

    getShows(state, action) {
        if (!action.payload.shows) {
            return state;
        }
        const shows = action.payload.shows.reduce((shows, show) => {
            shows[show.shortId] = show;
            return shows;
        }, {});

        const filter = {...action.payload.filter};
        const forceReload = !!action.payload.forceReload;

        const pageHash = getSearchFilterHash(filter || {});
        const pageState = {
            ...state.page,
            [pageHash]: [
                ...(forceReload ? [] : state.page[pageHash] || []),
                ...action.payload.shows.map((show) => show.shortId)
            ],
        };

        const eventBlocks = {
            [pageHash]: []
        };
        action.payload.eventBlocks.forEach(eventBlock => {
            eventBlocks[pageHash].push(normalizeEventBlock(eventBlock));
        });

        return {
            ...state,
            byId: {
                ...state.byId,
                ...shows
            },
            page: pageState,
            eventBlocks: {
                ...state.eventBlocks,
                ...eventBlocks
            }
        };
    }

    getSearchSimple(state, action) {
        if (!action.payload.items) {
            return state;
        }
        const items = action.payload.items.reduce((shows, show) => {
            shows[show.shortId] = show;
            return shows;
        }, {});

        const filter = {...action.payload.filter};
        const forceReload = !!action.payload.forceReload;

        const pageHash = getSearchFilterHash(filter || {});
        const pageState = {
            ...state.page,
            [pageHash]: [
                ...(forceReload ? [] : state.page[pageHash] || []),
                ...action.payload.items.map(x=>x.shortId)
            ],
        };


        return {
            ...state,
            searchResults: {
                byId: {
                    ...state.byId,
                    ...items
                },
                page: pageState,
            }
        };
    }

    getShow(state, action) {
        const eventShows = (state.events[action.payload.eventShortId]) ? [...state.events[action.payload.eventShortId]] : [];
        if (!eventShows.includes(action.payload.shortId)) {
            eventShows.unshift(action.payload.shortId);
        }

        return {
            ...state,
            byId: {
                ...state.byId,
                [action.payload.shortId]: action.payload
            },
            events: {
                ...state.events,
                [action.payload.eventShortId]: [...eventShows]
            }
        };
    }

    getShowsByEvent(state, action) {
        if (!action.payload.shows.length) {
            return state;
        }
        let eventShows = [];
        if (state.events[action.payload.shows[0].eventShortId]) {
            eventShows = [...state.events[action.payload.shows[0].eventShortId]];
        }

        if (eventShows.length === 0) {
            eventShows = action.payload.shows.map((show) => show.shortId);
        } else {
            action.payload.shows.forEach((show) => {
                if (!eventShows.includes(show.shortId)) {
                    eventShows.push(show.shortId);
                }
            });
        }

        const shows = action.payload.shows.reduce((shows, show) => {
            shows[show.shortId] = show;
            return shows;
        }, {});

        return {
            ...state,
            byId: {
                ...state.byId,
                ...shows
            },
            events: {
                ...state.events,
                [action.payload.shows[0].eventShortId]: [...eventShows]
            }
        };
    }

    getShowSearchPriceRange(state, action) {
        if (
            0 >= action.payload.to ||
            action.payload.from > action.payload.to
        ) {
            return state;
        }

        const searchHash = action.payload.searchHash || 0;

        return {
            ...state,
            searchPriceRange: {
                ...state.searchPriceRange,
                [searchHash]: {
                    from: action.payload.from,
                    to: action.payload.to,
                }
            }
        };
    }

    getPersonalDiscounts(state, action) {
        return {
            ...state,
            personalDiscounts: [
                ...action.payload.personalDiscounts
            ]
        };
    }

    resetPersonalDiscounts(state) {
        return {
            ...state,
            personalDiscounts: []
        };
    }
}

export default new Shows().reducer;
