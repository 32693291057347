
/* injects from baggage-loader */

import {RSAA, getJSON, ApiError} from 'redux-api-middleware';
import {Translation} from '../Translation/Translation';
import browserStorageUtils from '../browserStorage/utils';
import {TOKEN_KEY, SESSION_ID} from '../browserStorage/constants';
import * as queryString from 'query-string';
import {IReduxStore} from '../../contracts/store';
import uuid from 'uuid';
import { log } from '../logger';

const browserStorage = browserStorageUtils.getAvailableStorage();

interface IApiActionCreator {
    endpoint: string;
    type: string | Array<string | {
        type: string,
        payload: (...args) => void;
    }>;
    onSuccess?: (args) => void;
    onError?: (args, res) => void;
    method: string;
    body?: object | string;
    headers?: object;
    bailout?: (state: IReduxStore) => boolean;
}

const CURRENT_LANG = Translation.getCurrentLanguage();
const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Accept-Language': CURRENT_LANG
};

export const apiActionCreator = ({endpoint, type, method, body, onSuccess, onError, ...rest}: IApiActionCreator): any => {
    return (dispatch, getState) => {
        const state = getState();
        let userToken = state.user.token;
        let sessionId = browserStorage.getItem(SESSION_ID);
        if (!sessionId) {
            sessionId = uuid();
            log(`sessionId is null, storage type: ${browserStorage.type}`)
            browserStorage.setItem(SESSION_ID, sessionId);
        }
        if (!userToken) {
            userToken = browserStorage.getItem(TOKEN_KEY);
        }
        
        let types = [];
        if (typeof type === 'string' ) {
            types = ['REQUEST', 'SUCCESS', 'FAILURE'].map((postfix) => `${type}_${postfix}`);
            if (onSuccess && typeof onSuccess === 'function') {
                // SUCCESS type replaced with callback
                types[1] = {
                    type: `${type}_SUCCESS`,
                    payload: (action, state, res) => {
                        const response = getJSON(res);
                        response.then((payload) => {
                            onSuccess(payload);
                        });
                        return response;
                    }
                };
            }
            if (onError && typeof onError === 'function') {
                // ERROR type replaced with callback
                types[2] = {
                    type: `${type}_FAILURE`,
                    payload: (action, state, res) => {
                        return getJSON(res).then(
                            (payload) => {
                                onError(payload, res);
                                // keep default error response
                                return new ApiError(res.status, res.statusText, payload);
                            }
                        );
                    }
                };
            }
        } else {
            types = type;
        }

        // include token in headers if exists
        if (userToken && userToken.access_token) {
            headers['Authorization'] = `Bearer ${userToken.access_token}`;
        } else if (headers['Authorization']) {
            delete headers['Authorization'];
        }

        headers['Session-Id'] = sessionId;

        // build get query from body here
        if (method.toLowerCase() === 'get' && typeof body  !== 'undefined') {
            endpoint = `${endpoint}?${queryString.stringify(body)}`;
            body = undefined;
        }

        function getCookie(name) {
            const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
            return v ? v[2] : null;
        }
        const g4aMeasurementId = state.settings && state.settings.g4aMeasurementId;

        headers['x-ga-client-id'] = getCookie('_ga');

        if (g4aMeasurementId) {
            headers['x-ga-session_id'] = getCookie(`_ga_${g4aMeasurementId.split('-')[1]}`);
        }

        const queueittoken = getCookie('queueittoken');
        if (queueittoken) {
            headers['x-queueittoken'] = getCookie('queueittoken');      
        } 

        body = JSON.stringify(body);

        const requestObject = {
            endpoint,
            method,
            types,
            body,
            headers: Object.assign(headers, rest.headers || {}), // merge headers if passed
            ...rest
        };

        return dispatch({
            [RSAA]: requestObject
        });
    };
};
