
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {FAVORITE} from '../actions/favorite';

class Favorites extends BaseReducer {
    constructor() {
        super([]);
        this.actionMap = {
            [`${FAVORITE.GET}_SUCCESS`]: this.getFavorites.bind(this),
            [`${FAVORITE.ADD}_SUCCESS`]: this.addFavorite.bind(this),
            [`${FAVORITE.DELETE}_SUCCESS`]: this.deleteFavorite.bind(this)
        };
    }

    getFavorites(state, action) {
        return action.payload.favorites;
    }

    addFavorite(state, action) {
        return [
            ...state,
            action.payload.favorite
        ];
    }

    deleteFavorite(state, action) {
        const removeFavorite = action.payload.favorite;
        const removeItemIndex = state.findIndex((favorite) => {
            return (favorite.eventShortId === removeFavorite.eventShortId && favorite.showShortId === removeFavorite.showShortId);
        });
        return [
            ...state.slice(0, removeItemIndex),
            ...state.slice(removeItemIndex + 1)
        ];
    }
}

export default new Favorites().reducer;
