
/* injects from baggage-loader */

export const HISTORY = {
    PUSH: 'PUSH_HISTORY'
};

export function logRoute(pathname) {
    return {
        type: HISTORY.PUSH,
        pathname
    };
}
