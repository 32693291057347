
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {GROUP} from '../actions/group';
import {IGroup} from '../contracts/group';

class Groups extends BaseReducer {
    constructor() {
        super({
            ids: [],
            byId: {},
        });
        this.actionMap = {
            [`${GROUP.GET_ALL}_SUCCESS`]: this.getGroups.bind(this),
            [`${GROUP.GET}_SUCCESS`]: this.getGroup.bind(this),
        };
    }

    getGroups(state, action: { payload: { groups: Array<IGroup> }}) {
        if (!action.payload.groups || action.payload.groups.length <= 0) {
            return state;
        }
        const groups = {};
        const ids = [];
        action.payload.groups.forEach((group) => {
            ids.push(group.shortId);
            groups[group.shortId] = group;
        });
        return {
            ids,
            byId: groups,
        };
    }

    getGroup(state, action) {
        if (action.payload.resultStatus !== 'OK') {
            return state;
        }
        const ids = [...state.ids];
        if (!ids.includes(action.payload.shortId)) {
            ids.push(action.payload.shortId);
        }
        return {
            ids,
            byId: {
                ...state.byId,
                [action.payload.shortId]: action.payload,
            }
        };
    }

}

export default new Groups().reducer;
