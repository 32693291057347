
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {TICKET} from '../actions/ticket';
import {ITickets} from '../contracts/tickets';
import {VENUE} from '../actions/venue';
import {HOURLY_SLOTS} from '../actions/hourlySlots'

class Tickets extends BaseReducer {
    static defaultState = {
        byId: {},
        ids: [],
        recaptchaToken: ''
    } as ITickets;
    constructor() {
        super(Tickets.defaultState);
        this.actionMap = {
            [`${TICKET.ADD_TICKET}_SUCCESS`]: this.addTicket.bind(this),
            [`${TICKET.UPDATE_TICKETS}_SUCCESS`]: this.addTicket.bind(this),
            [`${TICKET.REMOVE_TICKET}_SUCCESS`]: this.removeTicket.bind(this),
            [`${TICKET.SET_DISCOUNT}_SUCCESS`]: this.applyDiscount.bind(this),
            [`${TICKET.REMOVE_DISCOUNT}_SUCCESS`]: this.applyDiscount.bind(this),
            [TICKET.RESET_TICKETS]: this.resetTickets.bind(this),
            [`${TICKET.RESET_API_TICKETS}_SUCCESS`]: this.resetTickets.bind(this),
            [`${VENUE.GET}_SUCCESS`]: this.addTicket.bind(this),
            [`${TICKET.CHANGE_TICKET}_SUCCESS`]: this.addTicket.bind(this),
            [TICKET.ADD_RECAPTCHA_TOKEN]: this.addRecaptchaToken.bind(this),
            [`${HOURLY_SLOTS.GET_HOURLY_SLOTS}_SUCCESS`]: this.addTicket.bind(this),
        };
    }

    resetTickets(state, action) {
        return Tickets.defaultState;
    }

    applyDiscount(state, action) {
        const ticketsById = this.getTicketsById(action.payload.tickets);

        return {
            ...state,
            byId: {
                ...state.byId,
                ...ticketsById
            },
        };
    }

    addTicket(state, action) {
        if (!action.payload.tickets) {
            return state;
        }
        const ticketIds = action.payload.tickets.map((ticket) => ticket.id);
        const ticketsById = this.getTicketsById(action.payload.tickets);

        return {
            ...state,
            ids: ticketIds,
            byId: {
                ...state.byId,
                ...ticketsById
            },
        };
    }

    removeTicket(state, action) {
        if (!action.payload.tickets) {
            return state;
        }
        const ticketIds = action.payload.tickets.map((ticket) => ticket.id);
        const ticketsById = this.getTicketsById(action.payload.tickets);

        return {
            ...state,
            ids: ticketIds,
            byId: {
                ...state.byId,
                ...ticketsById
            },
        };
    }

    private getTicketsById(tickets) {
        let ticketsById = {};
        ticketsById = tickets.reduce((tickets, ticket) => {
            tickets[ticket.id] = ticket;
            return tickets;
        }, ticketsById);

        return ticketsById;
    }

    addRecaptchaToken(state, action) {
        return {
            ...state,
            recaptchaToken: action.token
        }
    }
}

export default new Tickets().reducer;
