
/* injects from baggage-loader */

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function stripTags(str: string): string {
    if (!str) {
        return '';
    }
    return str.replace(/<\/?[^>]+(>|$)/g, '');
}
