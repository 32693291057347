
/* injects from baggage-loader */

export enum BoxOfficeFeatures {
    BOXOFFICE = 'BOXOFFICE', // stacionarios kasos vartotojas
    MOBILEBOXOFFICE = 'MOBILEBOXOFFICE', // mobilios kasos vartotojas
    DUALMONITOR = 'DUALMONITOR', // ekrano dubliavimas
    PRINTA4TICKET = 'PRINTA4TICKET',
    BOXOFFICEREPORT = 'BOXOFFICEREPORT',
    PAYINGBYINTEGRATEDPROVIDER = 'PAYINGBYINTEGRATEDPROVIDER',
    CARTRESERVATIONDOWNLOAD = 'CARTRESERVATIONDOWNLOAD',
    CANRETURNTICKETS = 'CANRETURNTICKETS',
    CANPAYFORRESERVATION = 'CANPAYFORRESERVATION',
    DISPLAYBOXOFFICEADS = 'DISPLAYBOXOFFICEADS',
    CANAUTOPRINT = 'CANAUTOPRINT', // kasoje galima automatiškai atspausdinti bilietus
    CALCULATERETURN = 'CALCULATERETURN', // apskaičiuoti gražą
    AUTOPAYMENT = 'AUTOPAYMENT',
}

export interface IInvoice {
    isIndividual: boolean;
    name: string;
    address: string;
    companyCode: string;
    companyVatCode: string;
    invoiceEmail: string;
    other?: string;
}

export interface IUser {
    email: string;
    cityId: number;
    city: string; // do we need it?
    birthYear: number;
    sendCommercialAccepted: boolean;
    rulesAccepted: boolean;
    isClubMember: boolean;
    lc?: string;
    invoice: IInvoice;
    boxOfficeFeatures: Array<BoxOfficeFeatures>;
    addId?: string;
}
