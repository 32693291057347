
/* injects from baggage-loader */

import { isBoxOfficeUser } from '../selectors/user';
import { isBoxOfficeMirror, isBoxOfficeAds } from '../selectors/boxOffice';
import { ReactElement } from "react";

export const MESSAGE = {
    API: 'API_MESSAGE',
    SET_MESSAGE: 'SET_MESSAGE',
    CLEAR: 'CLEAR_MESSAGES'
};

export function setApiMessage(actionType: string, errorType: string, code: number, message: string) {
    return {
        type: MESSAGE.API,
        payload: {
            actionType,
            errorType,
            code,
            message
        }
    };
}

export function setMessage(title: string, message: string, actionType: string = null) {
    const payload: any = {};
    if (title) {
        payload.title = title;
    }
    if (message) {
        payload.message = message;
    }
    if (actionType) {
        payload.actionType = actionType;
    }
    return {
        type: MESSAGE.SET_MESSAGE,
        payload
    };
}

export interface IMessagePayload {
    title?: string;
    message?: string | ReactElement;
    code?: number;
    actionType?: string;
    urlParams?: {[key: string]: any};
}
export function setMessagePayload(payload: IMessagePayload) {
    return {
        type: MESSAGE.SET_MESSAGE,
        payload
    };
}

export function clearMessages() {
    return {
        type: MESSAGE.CLEAR
    };
}

export function timeOutPopup() {
    return (dispatch, getState) => {
        if (isBoxOfficeMirror(getState()) || isBoxOfficeAds(getState()))  {
            return;
        }
        const _isBoxOfficeUser = isBoxOfficeUser(getState());
        if (_isBoxOfficeUser) {
            dispatch({
                type: MESSAGE.SET_MESSAGE,
                payload: {
                    actionType: 'BOX_USER_TIME_OUT'
                }
            });
        } else {
            dispatch({
                type: MESSAGE.SET_MESSAGE,
                payload: {
                    actionType: 'TIME_OUT'
                }
            });
        }
    };
}
