
/* injects from baggage-loader */


export const TIMER = {
    UPDATE_TIMER: 'UPDATE_TIMER',
    UPDATE_CART_TIMER: 'UPDATE_CART_TIMER'
};

export function updateTimer(timeLeft) {
    return {
        type: TIMER.UPDATE_TIMER,
        timeLeft
    };
}

export function updateCartTimer(timeLeft) {
    return {
        type: TIMER.UPDATE_CART_TIMER,
        timeLeft
    };
}
