
/* injects from baggage-loader */

import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface INewWindowProps {
    children?: React.ReactNode;
    url?: string;
    name?: string;
    title?: string;
    features?: {[k: string]: any};
    center?: string;
    copyStyles?: boolean;
    autoClose?: boolean;
    onUnload?: () => void;
    onBlock?: () => void;
}

interface INewWindowState {
    mounted: boolean;
}

export class NewWindow extends React.PureComponent<INewWindowProps, INewWindowState> {

    static defaultProps = {
        url: '',
        name: 'kakava.lt',
        title: 'kakava.lt',
        features: {
            width: 1920,
            height: 900,
            minimizable: true,
            alwaysRaised: true,
            replace: true,
            resizable: true,
            scrollbars: true,
            location: false,
            menubar: false,
            fullscreen: true,
            left: 0,
            top: 0,
        },
        onBlock: null,
        onUnload: null,
        copyStyles: false,
        autoClose: true,
    };

    container = null;
    window = null;
    windowCheckerInterval = null;
    released = null;

    constructor(props) {
        super(props);

        this.container = document.createElement('div');
        this.window = null;
        this.windowCheckerInterval = null;
        this.released = false;
        this.state = {
            mounted: false
        };

        if (props.autoClose) {
            window.addEventListener('beforeunload', (e) => {
                this.window.close();
            }, false);
        }
    }

    /**
     * Render the NewWindow component.
     */
    render() {
        if (!this.state.mounted) {
            return null;
        }

        // return ReactDOM.createPortal(this.props.children, this.container);
        return '';
    }

    componentDidMount() {
        this.openChild();
        this.setState({ mounted: true });
    }

    /**
     * Create the new window when NewWindow component mount.
     */
    openChild() {
        const { url, title, name, features, onBlock, center } = this.props;

        features.width = window.innerWidth;
        features.height = window.innerHeight;

        this.window = window.open(url, name, toWindowFeatures(features));

        this.windowCheckerInterval = setInterval(() => {
            if (!this.window || this.window.closed) {
                this.release();
            }
        }, 50);

        if (this.window) {
            if (this.window.addEventListener) {
                this.window.addEventListener('beforeunload', () => this.release(), false);
            } else if (this.window.attachEvent) {
                this.window.attachEvent('beforeunload', () => this.release());
            }
        } else {
            if (typeof onBlock === 'function') {
                onBlock.call(null);
            }
        }
    }

    componentWillUnmount() {
        if (this.window && this.props.autoClose) {
            this.window.close();
        }
    }

    release() {
        if (this.released) {
            return;
        }
        this.released = true;

        clearInterval(this.windowCheckerInterval);

        const { onUnload } = this.props;

        if (typeof onUnload === 'function') {
            onUnload.call(null);
        }
    }
}

function toWindowFeatures(obj) {
    return Object.keys(obj)
        .reduce((features, name) => {
            const value = obj[name];
            if (typeof value === 'boolean') {
                features.push(`${name}=${value ? 'yes' : 'no'}`);
            } else {
                features.push(`${name}=${value}`);
            }
            return features;
        }, [])
        .join(',');
}
