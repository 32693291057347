
/* injects from baggage-loader */

import {IReduxStore} from '../../contracts/store';

import { queueBOMirrorMessage, sendBOMirrorMessage } from './boxOffice';
import { isBoxOfficeUrl } from '../../utils/boxOffice/boxOffice';
import { isBoxOfficeMirror } from '../../selectors/boxOffice';
import { hasScreenMirroring } from '../../selectors/user';

export const boxOfficeMirrorMiddleware = store => next => action => {
    if (isBoxOfficeUrl()) {
        const state: IReduxStore = store.getState();
        if (hasScreenMirroring(state) && !isBoxOfficeMirror(state)) {
            if (
                (typeof action.error !== 'undefined' && action.error === true) ||
                action.type.includes( '@@redux-form' ) ||
                action.type.includes( '_REQUEST' ) ||
                action.type.includes( 'loading-bar' ) ||
                action.type === 'SET_MODAL' ||
                action.type === 'SET_MESSAGE' ||
                action.type === 'API_MESSAGE' ||
                action.type === 'UPDATE_TIMER' ||
                action.type === 'UPDATE_CART_TIMER'
            ) {
                return next(action);
            }

            queueBOMirrorMessage(action);
        }
    }

    return next(action);
};
