
/* injects from baggage-loader */

import {combineReducers} from 'redux';
import {loadingBarReducer} from 'react-redux-loading-bar';
import {reducer as reduxForm} from 'redux-form';
import modal from './modal';
import user from './user';
import events from './events';
import shows from './shows';
import staticData from './static';
import favorites from './favorites';
import venue from './venue';
import messages from './messages';
import tickets from './tickets';
import discounts from './discounts';
import timer from './timer';
import cart from './cart';
import paymentTypes from './paymentTypes';
import orders from './orders';
import loading from './loading';
import routeHistory from './routeHistory';
import boxOffice from './boxOffice';
import vouchers from './vouchers';
import language from './language';
import groups from './groups';
import settings from './settings';
import hourlySlots from './hourlySlots';

export default combineReducers({
    form: reduxForm,
    loadingBar: loadingBarReducer,
    modal,
    user,
    events,
    shows,
    groups,
    favorites,
    venue,
    messages,
    staticData,
    tickets,
    timer,
    discounts,
    cart,
    orders,
    paymentTypes,
    loading,
    routeHistory,
    boxOffice,
    vouchers,
    language,
    settings,
    hourlySlots,
});
