
/* injects from baggage-loader */

import * as React from 'react';
import { useSelector} from 'react-redux';
import { Helmet, HelmetProps } from 'react-helmet';

import { siteMeta } from '../../../../constants/siteMeta';
import { isBoxOfficeUrl } from '../../../../utils/boxOffice/boxOffice';
import { isBoxOfficeMirror, isBoxOfficeAds } from '../../../../selectors/boxOffice';
import {useVisibleDisplay} from '../../../../utils/hooks/useVisibleDisplay';

export function HelmetTitleHOC(props: { title: string; } & HelmetProps) {
    const _isBoxOfficeUrl = isBoxOfficeUrl();
    const _isBoxOfficeMirror = useSelector(isBoxOfficeMirror);
    const _isBoxOfficeAds = useSelector(isBoxOfficeAds);

    const isVisibleDisplay = useVisibleDisplay(_isBoxOfficeUrl);

    if (_isBoxOfficeUrl) {
        if (_isBoxOfficeMirror) {
            return <Helmet title={props.title + ' | box office mirror'} defer={false} />;
        } else if (_isBoxOfficeAds) {
            return <Helmet title={props.title + ' | box office ads'} defer={false} />;
        } else {
            if (isVisibleDisplay) {
                return (
                    <Helmet defer={false}>
                        <title>{props.title + ' | box office'}</title>
                    </Helmet>
                );
            } else {
                // <Helmet defer=false /> was not working properly.
                document.title = 'kakava.lt hidden';
                return null;
            }
        }
    }

    return (
        <Helmet title={props.title} defer={false}>
            <meta property='og:title' content={props.title + siteMeta.titleSuffix} />
        </Helmet>
    );
}
