
/* injects from baggage-loader */

import {
    IMultilingualSlugs,
    ICategory,
    ILocation,
    ICity,
    MarksEnum,
    StatusEnum,
    IPicture,
    IScarcity,
    ICustomMark
} from './store';

enum OrderEnum {
    DATE = 'DATE',
    PRICE = 'PRICE'
}
enum DirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}

export interface IPromoter {
    id: string;
    name: string;
    pictureUrl: string;
    address: string;
    companyCode: string;
    vatCode: string;
    email: string;
    webAddress: string;
    phone?: string;
    brandName?: string;
}

export interface IEventGroup {
    id: string;
    shortId: number;
    name: string;
    nameSlug: string;
    nameSlugs: Array<{language: string; value: string}>;
    startDate?: string;
    endDate?: string;
    promoterName?: string;
    eventUrl?: string;
}

export interface IEventFilterParams {
    cities?: Array<number>;
    categories?: Array<number>;
    dateFrom?: string;
    dateTo?: string;
    priceFrom?: number;
    priceTo?: number;
    marks?: Array<MarksEnum>;
    order?: OrderEnum;
    direction?: DirectionEnum;
    skip?: number;
    take?: number;
    boxOffice?: boolean;
    returnInBoxOffice?: boolean;
    fragment?: string; // search by fragment parameter
}
// fill all keys from IEventFilterParams
export const EventFilterParamsKeys: Array<keyof IEventFilterParams> = [
    'cities', 'categories', 'dateFrom', 'dateTo', 'priceFrom', 'priceTo',
    'marks', 'order', 'direction', 'skip', 'take', 'boxOffice', 'returnInBoxOffice',
    'fragment'
];

export interface IEvent {
    id: string;
    shortId: number;
    title: string;
    titleSlug: string;
    titleSlugs: Array<IMultilingualSlugs>;
    categories: Array<ICategory>;
    eventGroup: IEventGroup;
    status: string;
    priceFrom: number;
    priceWithDiscount: number;
    ticketsLeft: number;
    locations: Array<ILocation>;
    cities: Array<ICity>;
    date: string;
    endDate: string;
    marks: Array<MarksEnum>;
    frontEndStatus: Array<StatusEnum>;
    scarcity: IScarcity;
    picture: IPicture;
    pictureGallery: Array<string>;
    videos: Array<string>;
    eventDescription: string;
    hasEnglishDescription: boolean;
    artistDescription: string;
    numberOfShows: number;
    promoter: IPromoter;
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string;
    customMarks?: Array<ICustomMark>;
    isRecaptchaEnabled: boolean;
    isHourlySales: boolean;
}

export interface IEvents {
    byId: {[k: number]: IEvent};
    home: Array<IEventBlock>;
    club: Array<IEventBlock>;
    search: Array<string>;
    groups: {
        [groupId: string]: Array<number>;
    };
}

export interface IEventBlock {
    pictureUrl: string;
    rowNumber: number;
    filterByMark?: MarksEnum;
    filterByCategoryId?: number;
    title: string;
    events: Array<number>;
    shows?: Array<number>;
    clubBlock?: boolean;
    lodgeBlock?: boolean;
    emptyText: string;
}
