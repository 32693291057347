
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {MESSAGE} from '../actions/message';
import {USER} from '../actions/user';
import {ORDER} from '../actions/order';
import { NEWSLETTER } from '../actions/newsletter';

class Messages extends BaseReducer {
    constructor() {
        super([]);
        this.actionMap = {
            [MESSAGE.API]: this.setMessage.bind(this),
            [MESSAGE.SET_MESSAGE]: this.setMessage.bind(this),
            [MESSAGE.CLEAR]: this.clearMessages.bind(this),
            [`${USER.ACTIVATE_USER}_SUCCESS`]: this.successMessage.bind(this),
            [`${USER.REMIND_PASSWORD}_SUCCESS`]: this.successMessage.bind(this),
            [`${USER.RESET_PASSWORD}_SUCCESS`]: this.successMessage.bind(this),
            [USER.UPDATE_DATA_USER_SUCCESS]: this.successMessage.bind(this),
            [`${USER.REGISTER_USER}_SUCCESS`]: this.successMessage.bind(this),
            [`${NEWSLETTER.ACTIVATE_NEWSLETTER}_SUCCESS`]: this.successMessage.bind(this),
            [`${NEWSLETTER.ACTIVATE_NEWSLETTER}_FAILURE`]: this.setMessage.bind(this),
            [`${ORDER.RESEND}_SUCCESS`]: this.successMessage.bind(this),
            [`${USER.CHANGE_PASSWORD}_SUCCESS`]: this.successMessage.bind(this)
        };
    }

    successMessage(state, action) {
        return this.setMessage(state, {
            payload: {
                actionType: action.type,
                code: 200
            }
        });
    }

    setMessage(state, action) {
        return [
            ...state,
            action.payload
        ];
    }

    clearMessages(state, action) {
        return this.defaultState;
    }
}

export default new Messages().reducer;
