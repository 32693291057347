
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {HOURLY_SLOTS} from '../actions/hourlySlots';

class HourlySlots extends BaseReducer {
    static defaultState = null;
    constructor() {
        super(HourlySlots.defaultState);
        this.actionMap = {
            [`${HOURLY_SLOTS.GET_HOURLY_SLOTS}_SUCCESS`]: this.getHourlySlots.bind(this),
        };
    }

    clearHourlySlots() {
        return HourlySlots.defaultState;
    }

    getHourlySlots(state, action) {
        return action.payload.hourlySaleDays;
    }
}

export default new HourlySlots().reducer;
