
/* injects from baggage-loader */

import * as moment from 'moment';
import browserStorageUtils from '../utils/browserStorage/utils';

// @todo memoize
import {IUser, BoxOfficeFeatures} from '../contracts/user';
import {REMEMBER_ME, TOKEN_KEY} from '../utils/browserStorage/constants';

export function getUser(state): IUser {
    if (state.user && state.user.data) {
        return state.user.data;
    } else {
        return null;
    }
}

export function isAuthenticated(state): boolean {
    const browserStorage = browserStorageUtils.getAvailableStorage();
    const user = getUser(state);
    const userToken = browserStorage.getItem(TOKEN_KEY);
    if (user && userToken && (userToken as any).access_token) {
        const rememberMe = browserStorage.getItem(REMEMBER_ME);
        return (!!rememberMe === true) || moment.utc(userToken['.expires']) > moment.utc(new Date());
    } else {
        return false;
    }
}

export function isClubMember(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.isClubMember;
}

export function isBoxOfficeUser(state): boolean {
    return isStationaryBoxOfficeUser(state) || isMobileBoxOfficeUser(state);
}

export function isStationaryBoxOfficeUser(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.BOXOFFICE);
}

export function isMobileBoxOfficeUser(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.MOBILEBOXOFFICE);
}

export function hasPOSAds(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.DISPLAYBOXOFFICEADS);
}

export function hasScreenMirroring(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.DUALMONITOR);
}

export function canPrintA4Tickets(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.PRINTA4TICKET);
}

export function canDownloadPOSReport(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.BOXOFFICEREPORT);
}

export function canDownloadCartReservation(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.CARTRESERVATIONDOWNLOAD);
}

export function hasPOSIntegration(state): boolean {
    return isMobileBoxOfficeUser(state) || canPrintA4Tickets(state);
}

export function hasPOSIntegratedPayments(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.PAYINGBYINTEGRATEDPROVIDER);
}

export function canAutoPrint(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.CANAUTOPRINT);
}

export function canCalculateReturn(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.CALCULATERETURN);
}
export function canReturnTickets(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.CANRETURNTICKETS);
}

export function canPayForReservation(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.CANPAYFORRESERVATION);
}

export function hasAutoPayment(state): boolean {
    const user = getUser(state);
    if (!user) {
        return false;
    }
    return user.boxOfficeFeatures && user.boxOfficeFeatures.includes(BoxOfficeFeatures.AUTOPAYMENT);
}
