
/* injects from baggage-loader */

import * as React from 'react';
import {Query} from '@apollo/react-components';
import idx from 'idx';
import {getThemeName, removeTheme, updateTheme, isThemeRequired, isThemeLoaded} from '../../../../utils/theme';
import {IThemeVariables, ITheme, themeQuery, buildThemeFilter} from '../../../../queries/theme';
import {log} from '../../../../utils/logger';

export function themeLoader(Component) {
    return class extends React.Component {
        render() {
            const wrappedComponent = <Component {...this.props} />;

            if (isThemeRequired() && !isThemeLoaded()) {
                const themeName = getThemeName();
                return (
                    <Query<ITheme, IThemeVariables> query={themeQuery} variables={{filter: buildThemeFilter(themeName)}}>
                        {({loading, data, error}) => {
                            if (loading) {
                                return null;
                            }
                            if (error) {
                                log(error);
                                removeTheme();
                                return null;
                            }
                            if (data.queryMultichannelthemeContents.length <= 0) {
                                removeTheme();
                            } else {
                                const themeParams = idx(data, _ => _.queryMultichannelthemeContents[0].data);
                                const theme = updateTheme(themeParams);
                            }
                            return wrappedComponent;
                        }}
                    </Query>
                );
            }

            return wrappedComponent;
        }
    };
}
