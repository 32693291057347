
/* injects from baggage-loader */

// import * as fileDownload from 'js-file-download';
import { isBoxOfficeUser, hasPOSIntegration, canAutoPrint } from '../selectors/user';

const fileDownload = require('js-file-download');
import print from 'print-js';
import {API_URL} from '../utils/api/constants';
import downloadFile from '../utils/downloadFile';
import {apiActionCreator} from '../utils/api/apiActionCreator';
import {IOrder, IOrderResendRequest, OrderStatus} from '../contracts/order';
import {hideTopModal} from './modal';
import {urls} from '../routes';
import {history} from '../history';
import i18next from 'i18next';

const ORDER_REFRESH = 5 * 1000; // 5sec to check incomplete orders
const ORDER_REFRESH_BOXOFFICE = 1000; // 1sec to check incomplete orders for boxoffice

export const ORDER = {
    GET: 'GET_ORDER',
    GET_INVOICE_REQUEST: 'GET_ORDER_INVOICE_REQUEST',
    GET_INVOICE_SUCCESS: 'GET_ORDER_INVOICE_SUCCESS',
    GET_INVOICE_FAILURE: 'GET_ORDER_INVOICE_FAILURE',
    GET_APPLE_TICKET_REQUEST: 'GET_APPLE_TICKET_REQUEST',
    GET_APPLE_TICKET_SUCCESS: 'GET_APPLE_TICKET_SUCCESS',
    GET_APPLE_TICKET_FAILURE: 'GET_APPLE_TICKET_FAILURE',
    GET_APPLE_TICKETS_REQUEST: 'GET_APPLE_TICKETS_REQUEST',
    GET_APPLE_TICKETS_SUCCESS: 'GET_APPLE_TICKETS_SUCCESS',
    GET_APPLE_TICKETS_FAILURE: 'GET_APPLE_TICKETS_FAILURE',
    GET_TICKET_PDF_REQUEST: 'GET_TICKET_PDF_REQUEST',
    GET_TICKET_PDF_SUCCESS: 'GET_TICKET_PDF_SUCCESS',
    GET_TICKET_PDF_FAILURE: 'GET_TICKET_PDF_FAILURE',
    GET_ALL_TICKETS_PDF_REQUEST: 'GET_ALL_TICKETS_PDF_REQUEST',
    GET_ALL_TICKETS_PDF_SUCCESS: 'GET_ALL_TICKETS_PDF_SUCCESS',
    GET_ALL_TICKETS_PDF_FAILURE: 'GET_ALL_TICKETS_PDF_FAILURE',
    GET_DAY_REPORT_PDF_REQUEST: 'GET_DAY_REPORT_PDF_REQUEST',
    GET_DAY_REPORT_PDF_SUCCESS: 'GET_DAY_REPORT_PDF_SUCCESS',
    GET_DAY_REPORT_PDF_FAILURE: 'GET_DAY_REPORT_PDF_FAILURE',
    RESEND: 'RESEND_TICKET',
    GET_RECEIPT: 'GET_RECEIPT',
    CLEAR_RECEIPT: 'CLEAR_RECEIPT',
};

export function getOrder(skip: number = 0, take: number = 0, orderId: string = null) {
    return (dispatch, getState) => {
        const _isBoxOfficeUser = isBoxOfficeUser(getState());
        const _hasPOSIntegration = hasPOSIntegration(getState());
        const _canAutoPrint = canAutoPrint(getState());
        dispatch(apiActionCreator({
            endpoint: API_URL + '/order',
            method: 'GET',
            type: ORDER.GET,
            body: {
                skip,
                take,
                boxOffice: _isBoxOfficeUser,
            },
            onSuccess: (payload) => {
                const hasIncompletePayment = payload.orders.some((order: IOrder) => {
                    return order.workInProgress === true;
                });
                let wrongOrderReturned = false;
                if (orderId !== null) {
                    wrongOrderReturned = !payload.orders.some((order: IOrder) => {
                        return order.id === orderId;
                    });
                }
                if (hasIncompletePayment || wrongOrderReturned) {
                    setTimeout(() => {
                        if (
                            urls.get('user:tickets') === history.location.pathname ||
                            urls.get('tickets') === history.location.pathname ||
                            urls.get('cart:payment') === history.location.pathname // if in cart payment, useful for mobile box office
                        ) {
                            dispatch(getOrder(skip, take, orderId));
                        }
                    }, _isBoxOfficeUser ? ORDER_REFRESH_BOXOFFICE : ORDER_REFRESH);
                } else if (_hasPOSIntegration) {
                    const orderId = payload.orders[0].id;
                    dispatch(getOrderReceipt(orderId));
                }
                if (_canAutoPrint && !hasIncompletePayment && !wrongOrderReturned) {
                    dispatch(autoPrint(payload.orders[0].id));
                }
            }
        }));
    };
}

export function getOrderInvoice(orderGuid: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/order/${orderGuid}/invoice`,
        method: 'GET',
        type: [
            ORDER.GET_INVOICE_REQUEST,
            {
                type: ORDER.GET_INVOICE_SUCCESS,
                payload: (action, state, res) => {
                    return res.blob().then((blob) => {
                        fileDownload(blob, i18next.t('kakava-lt.pdf'));
                    });
                }
            },
            ORDER.GET_INVOICE_FAILURE
        ]
    });
}

export function getAppleTicket(ticketId: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/order/ticket/${ticketId}/applePass`,
        method: 'GET',
        type: [
            ORDER.GET_APPLE_TICKET_REQUEST,
            {
                type: ORDER.GET_APPLE_TICKET_SUCCESS,
                payload: (action, state, res) => {
                    return res.blob().then((blob) => {
                        downloadFile(blob, i18next.t('kakava-tickets.pkpass'))
                    });
                }
            },
            ORDER.GET_APPLE_TICKET_FAILURE
        ]
    })
}
export function getAppleTickets(orderId: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/order/${orderId}/applePass`,
        method: 'GET',
        type: [
            ORDER.GET_APPLE_TICKETS_REQUEST,
            {
                type: ORDER.GET_APPLE_TICKETS_SUCCESS,
                payload: (action, state, res) => {
                    return res.blob().then((blob) => {
                        downloadFile(blob, i18next.t('kakava-tickets.pkpasses'))
                    });
                }
            },
            ORDER.GET_APPLE_TICKETS_FAILURE
        ]
    })
}

export function getTicketPdf(ticketId: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/order/ticket/${ticketId}`,
        method: 'GET',
        type: [
            ORDER.GET_TICKET_PDF_REQUEST,
            {
                type: ORDER.GET_TICKET_PDF_SUCCESS,
                payload: (action, state, res) => {
                    return res.blob().then((blob) => {
                        fileDownload(blob, i18next.t('kakava-lt.pdf'));
                    });
                }
            },
            ORDER.GET_TICKET_PDF_FAILURE
        ]
    });
}

export function getGiftVoucherPdf(voucherId: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/order/giftVoucher/${voucherId}`,
        method: 'GET',
        type: [
            ORDER.GET_TICKET_PDF_REQUEST,
            {
                type: ORDER.GET_TICKET_PDF_SUCCESS,
                payload: (action, state, res) => {
                    return res.blob().then((blob) => {
                        fileDownload(blob, i18next.t('kakava-lt.pdf'));
                    });
                }
            },
            ORDER.GET_TICKET_PDF_FAILURE
        ]
    });
}

export function getOrderItemsPdf(ticketIds: Array<string> = [], giftVoucherIds: Array<string> = []) {
    const body = {
        ticketId: ticketIds,
        giftVoucherId: giftVoucherIds
    };
    return apiActionCreator({
        endpoint: `${API_URL}/order/purchaseitems`,
        method: 'GET',
        type: [
            ORDER.GET_TICKET_PDF_REQUEST,
            {
                type: ORDER.GET_TICKET_PDF_SUCCESS,
                payload: (action, state, res) => {
                    return res.blob().then((blob) => {
                        fileDownload(blob, i18next.t('kakava-lt.pdf'));
                    });
                }
            },
            ORDER.GET_TICKET_PDF_FAILURE
        ],
        body,
    });
}

export function getOrderPdf(orderId: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/order/${orderId}/tickets`,
        method: 'GET',
        type: [
            ORDER.GET_ALL_TICKETS_PDF_REQUEST,
            {
                type: ORDER.GET_ALL_TICKETS_PDF_SUCCESS,
                payload: (action, state, res) => {
                    return res.blob().then((blob) => {
                        fileDownload(blob, i18next.t('kakava-lt.pdf'));
                    });
                }
            },
            ORDER.GET_ALL_TICKETS_PDF_FAILURE
        ]
    });
}

export function resendTicketsAndVouchers(body: IOrderResendRequest) {
    return (dispatch) => {
        dispatch(hideTopModal());
        dispatch(apiActionCreator({
            endpoint: API_URL + '/order/ticket/resend',
            method: 'POST',
            type: ORDER.RESEND,
            body,
            onSuccess: () => {
                dispatch(getOrder());
            }
        }));
    };
}

export function getOrderReceipt(orderId: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/order/${orderId}/receipt`,
        method: 'GET',
        type: ORDER.GET_RECEIPT,
    });
}

function autoPrint(orderId: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/order/${orderId}/tickets`,
        method: 'GET',
        type: [
            ORDER.GET_ALL_TICKETS_PDF_REQUEST,
            {
                type: ORDER.GET_ALL_TICKETS_PDF_SUCCESS,
                payload: (action, state, res) => {
                    return res.blob().then((blob) => {
                        const url = URL.createObjectURL(blob);
                        print(url);
                    });
                }
            },
            ORDER.GET_ALL_TICKETS_PDF_FAILURE
        ]
    });
}

export function clearReceipt() {
    return {
        type: ORDER.CLEAR_RECEIPT
    };
}
