
/* injects from baggage-loader */

import sessionStorage from './sessionStorage';
import localStorage from './localStorage';
import cookieStorage from './cookieStorage';

function getAvailableStorage() {
    if (localStorage.isAvailable) {
        return { ...localStorage };
    }

    return { ...cookieStorage };
}

function getSessionStorage() {
    if (sessionStorage.isAvailable) {
        return { ...sessionStorage };
    }
    return { ...cookieStorage };
}

export default {
    getAvailableStorage,
    getSessionStorage
};
