
/* injects from baggage-loader */

import {history} from '../history';
import { Translation } from '../utils/Translation/Translation';

export function isRecaptchaEnabled(state): boolean {
    const currentLanguage = Translation.getCurrentLanguage();
    let eventId;
    if (currentLanguage==='lt') {
        eventId = history.location.pathname.split('/')[2];
    } else {
        eventId = history.location.pathname.split('/')[3];
    }
    if (eventId) {
        const recaptchaEnabled = state.events && state.events.byId[eventId] && state.events.byId[eventId].isRecaptchaEnabled;
        return state.settings.recaptchaEnabled && recaptchaEnabled;
    }
    return state.settings.recaptchaEnabled;
}
