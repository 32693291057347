
/* injects from baggage-loader */

import 'core-js/es6';
import 'core-js/es7';
import 'whatwg-fetch';
import 'url-search-params-polyfill';
import './utils/Translation/init';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ApolloProvider} from '@apollo/react-common';
import { GoogleOAuthProvider } from '@react-oauth/google';
import configureStore from './store/configureStore';
import {getGraphQLClient} from './utils/api/graphqlClient';
import Router from './router';
import {getCategories, getCities, getCitiesWithShows, getSeoLinks} from './actions/classification';
import {initUser} from './actions/user';
import {getCart} from './actions/cart';
import {getSettings} from './actions/settings';
import {getGraphQLToken} from './actions/graphql';
import {history} from './history';
import {logRoute} from './actions/routeHistory';

import '../styles/sass/main';
import {storeRegistry} from './store/storeRegistry';
import {getVenueLink} from './actions/venue';
import {initTheme} from './utils/theme';
import { getShowSearchPriceRange } from './actions/show';
import { Trans } from 'react-i18next';

import { GOOGLE_CLIENT_ID } from './constants/env';

const store = configureStore();
storeRegistry.register(store);

history.listen((location) => {
    store.dispatch(logRoute(location.pathname + location.search));
});

initTheme();

store.dispatch(initUser());
store.dispatch(getCities());
store.dispatch(getCitiesWithShows());
store.dispatch(getCategories());
store.dispatch(getVenueLink());
store.dispatch(getCart());
store.dispatch(getShowSearchPriceRange());
store.dispatch(getSeoLinks());
store.dispatch(getSettings())

const graphQLTokenPromise = store.dispatch(getGraphQLToken());

if (!document.referrer || !document.referrer.includes('tiketa.')) {
    ReactDOM.render(
        (
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <Provider store={store}>
                    <ApolloProvider client={getGraphQLClient(graphQLTokenPromise)}>
                        <I18nextProvider i18n={i18n}>
                            <Router />
                        </I18nextProvider>
                    </ApolloProvider>
                </Provider>
            </GoogleOAuthProvider>
        ),
        document.getElementById('root')
    );
} else {
    ReactDOM.render((
        <div className='page-home'>
            <div className='message flex-nowrap'>
                <div className='text-container flex-column'>
                    <h1><Trans>Ši nuoroda negalioja</Trans></h1>
                    <br />
                    <p className='text'><Trans>Bilietus į savo norimą renginį <br /> kviečiame įsigyti renginių namuose <br /> internete:</Trans></p>
                    <a href='https://kakava.lt' className="logo"></a>
                    <a href='https://kakava.lt' className="button"><Trans>Įsigyti bilietą</Trans></a>
                </div>
            </div>
        </div>
    ), document.getElementById('root'));
}

