
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {TICKET} from '../actions/ticket';
import {VOUCHER} from '../actions/voucher';
import {TIMER} from '../actions/timer';
import {CART} from '../actions/cart';
import {VENUE} from '../actions/venue';
import {USER} from '../actions/user';

class Timer extends BaseReducer {
    constructor() {
        super({
            reservation: 0,
            cart: 0
        });
        this.actionMap  = {
            [`${TICKET.ADD_TICKET}_SUCCESS`]: this.updateReservationTimer.bind(this),
            [`${TICKET.UPDATE_TICKETS}_SUCCESS`]: this.updateReservationTimer.bind(this),
            [`${TICKET.REMOVE_TICKET}_SUCCESS`]: this.updateReservationTimer.bind(this),
            [`${VOUCHER.SELECT_VOUCHERS}_SUCCESS`]: this.updateReservationTimer.bind(this),
            [`${TICKET.SET_DISCOUNT}_SUCCESS`]: this.updateReservationTimer.bind(this),
            [`${TICKET.REMOVE_DISCOUNT}_SUCCESS`]: this.updateReservationTimer.bind(this),
            [`${VENUE.GET}_SUCCESS`]: this.updateReservationTimer.bind(this),
            [TIMER.UPDATE_TIMER]: this.updateReservationTimerLocally.bind(this),
            [`${CART.GET}_SUCCESS`]: this.updateCartTimer.bind(this),
            [`${CART.ADD}_SUCCESS`]: this.updateCartTimer.bind(this),
            [`${CART.ADD_VOUCHERS}_SUCCESS`]: this.updateCartTimer.bind(this),
            [`${CART.DELETE}_SUCCESS`]: this.updateCartTimer.bind(this),
            [`${CART.DELETE_VOUCHERS}_SUCCESS`]: this.updateCartTimer.bind(this),
            [TIMER.UPDATE_CART_TIMER]: this.updateCartTimerLocally.bind(this),
            [`${CART.GET}_FAILURE`]: this.clearCartTimer.bind(this),
            [`${CART.BOX_OFFICE_REQUEST_PURCHASE}_SUCCESS`]: this.clearCartTimer.bind(this),
            [USER.LOGOUT]: this.clearCartTimer.bind(this)
        };
    }

    updateReservationTimer(state, action) {
        const tickets = action.payload.tickets;
        if (!tickets || tickets.length <= 0) {
            return {
                reservation: 0,
                cart: state.cart
            };
        }
        return {
            reservation: tickets[0].timeLeft,
            cart: state.cart
        };
    }

    updateReservationTimerLocally(state, action) {
        return {
            reservation: action.timeLeft,
            cart: state.cart
        };
    }

    updateCartTimer(state, action) {
        return {
            reservation: state.reservation,
            cart: action.payload.timeLeft
        };
    }

    updateCartTimerLocally(state, action) {
        return {
            reservation: state.reservation,
            cart: action.timeLeft
        };
    }

    clearCartTimer(state) {
        return {
            reservation: state.reservation,
            cart: 0
        };
    }
}

export default new Timer().reducer;
