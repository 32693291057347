
/* injects from baggage-loader */

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

import { HelmetTitleHOC } from '../common/components/utils/HelmetTitleHoc';
import {useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {updateCartTimer} from '../../actions/timer';


export const SuccessfulPurchaseSplashScreen = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateCartTimer(0));
    }, []);

    return (
        <>
            <HelmetTitleHOC title={i18next.t('Kasa')} />
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            <section className='page-bom-ticket-purchase-success'>
                <div className='title'>
                    <Trans>Bilietai jau tavo!</Trans>
                </div>
            </section>
        </>
    );
};
