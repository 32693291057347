
/* injects from baggage-loader */

import {API_URL} from './api/constants';
import * as StackTrace from 'stacktrace-js';
import browserStorageUtils from './browserStorage/utils';
import {SESSION_ID} from './browserStorage/constants';
import {debounce} from './debounce';
import {storeRegistry} from '../store/storeRegistry';
import {IReduxStore} from '../contracts/store';

const browserStorage = browserStorageUtils.getAvailableStorage();

class Logger {
    url: string;

    constructor(url) {
        this.url = url;
        this.log = this.log.bind(this);
        this.sendMessageToBackend = debounce(this.sendMessageToBackend.bind(this));
        this.init();
    }

    log(message: string | object): void {
        if (process.env.NODE_ENV === 'production') {
            this.sendMessageToBackend(message);
        }
        console.log(message);
    }

    private init() {
        this.initErrorListener();
    }

    private sendMessageToBackend(message: string | object) {
        let logMessage = message;
        if (typeof message === 'string') {
            logMessage = {message};
        }
        const store = storeRegistry.getStore();
        const state: IReduxStore = store.getState();
        const body = {messages: [
            {
                severity: 'ERROR',
                message: JSON.stringify({
                    user: {
                        sessionId: browserStorage.getItem(SESSION_ID),
                        userEmail: state && state.user && state.user.data && state.user.data.email
                    },
                    system: {
                        userAgent: navigator.userAgent,
                        availHeight: window.screen.availHeight,
                        availWidth: window.screen.availWidth,
                        location: window.location.href
                    },
                    routeHistory: state && state.routeHistory,
                    data: logMessage
                })
            }
        ]};
        this.backendCallHandler(body);
    }

    private initErrorListener() {
        window.addEventListener('error', (event) => {
            if (event.error) {
                StackTrace.fromError(event.error).then((stackFrames) => {
                    const mappedStackTrace = stackFrames.map((sf) => {
                        return sf.toString();
                    }).join('\n');

                    this.log(`${event.message} STACK: ${mappedStackTrace}`);
                });
            } else {
                this.log(`${event.message} STACK: none`);
            }
        });
    }
    private backendCallHandler(body) {
        const options = {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'})
        };
        body && Object.assign(options, {body: JSON.stringify(body)});

        fetch(this.url, options);
    }

}

const log = new Logger(API_URL + '/log').log;
export { log };
