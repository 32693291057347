
/* injects from baggage-loader */

import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { urls } from '../../routes';
import { isAuthenticated } from '../../selectors/user';
import { createLoadingSelector } from '../../selectors/loading';
import { isBoxOfficeUrl } from '../../utils/boxOffice/boxOffice';
import { isBoxOfficeMirror, isBoxOfficeAds } from '../../selectors/boxOffice';

interface IBoxOfficeLoginRedirect {
    isGettingUser: boolean;
    isAuthenticated: boolean;
    isBoxOfficeMirror: boolean;
    isBoxOfficeAds: boolean;
}
class BoxOfficeLoginRedirect extends React.PureComponent<IBoxOfficeLoginRedirect> {

    render() {
        const shouldRedirect = !this.props.isGettingUser && !this.props.isAuthenticated && !this.props.isBoxOfficeMirror && !this.props.isBoxOfficeAds && isBoxOfficeUrl();
        if (shouldRedirect) {
            return <Redirect to={urls.get('user:login')} />;
        }
        return null;
    }
}

const loginSelector = createLoadingSelector(['REFRESH_TOKEN', 'LOGIN', 'GET_CURRENT_USER']);

const mapStateToProps = (state) => {
    return {
        isGettingUser: loginSelector(state),
        isAuthenticated: isAuthenticated(state),
        isBoxOfficeMirror: isBoxOfficeMirror(state),
        isBoxOfficeAds: isBoxOfficeAds(state),
    };
};

const BoxOfficeLoginRedirectConnected = connect<IBoxOfficeLoginRedirect>(mapStateToProps)(BoxOfficeLoginRedirect);
export {BoxOfficeLoginRedirectConnected as BoxOfficeLoginRedirect};
