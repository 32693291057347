
/* injects from baggage-loader */

import {
    dataLayer,
    getHostname,
    getPageTitle,
    getPageType,
    getPageUrl,
    getPathname,
} from './dataLayer';
import { Translation } from '../Translation/Translation';

export const pageView = () => {
    return {
        event: 'pageView',
        page: {
            url: getPageUrl(),
            hostname: getHostname(),
            path: getPathname(),
            title: getPageTitle(), // @TODO gets previews title
            language: Translation.getCurrentLanguage(),
            type: getPageType(),
        },
    };
};

export const pushPageView = () => {
    // @fixme timeout is for document.title
    setTimeout(() => {
        dataLayer.push(pageView());
    }, 100);
};
