
/* injects from baggage-loader */

export function normalizeEventBlock(eventBlock) {
    const blockEvent = {
        pictureUrl: eventBlock.pictureUrl,
        title: eventBlock.title,
        clubBlock: eventBlock.clubBlock,
        lodgeBlock: eventBlock.lodgeBlock,
        filterByMark: eventBlock.filterByMark,
        filterByCategoryId: eventBlock.filterByCategoryId,
        rowNumber: parseInt(eventBlock.rowNumber, 10),
        emptyText: eventBlock.emptyText,
        events: [],
        shows: [],
    };
    if (eventBlock.events || eventBlock.shows) {
        blockEvent.events = (eventBlock.events || []).map(({event}) => event.shortId);
        blockEvent.shows = (eventBlock.shows || []).map((show) => show.shortId);
    }
    return blockEvent;
}
