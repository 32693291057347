
/* injects from baggage-loader */

export class EveryPay {
    url: string;
    constructor(url: string) {
        this.url = url;
    }

    submit() {
        window.location.href = this.url;
    }
}