
/* injects from baggage-loader */

import * as React from 'react';
import {connect} from 'react-redux';
import { Trans } from 'react-i18next';

import {MessageModal} from './MessageModal';
import {clearMessages} from '../../../../actions/message';
import {messages} from './messages';
import {MODALS, setModal, hideAllModals} from '../../../../actions/modal';
import {IMessage} from '../../../../contracts/messages';
import idx from 'idx';


interface IMessagesContainer {
    messages: Array<IMessage>;
    clearMessages: () => void;
    setModal: (modalType: MODALS, args?: object) => void;
}

// const DEFAULT_TITLE = 'Klaida';
const DEFAULT_MESSAGE = <Trans>O ne! Kažkas nutiko. Klaida yra dalis progreso, atkreipsime į ją dėmesį, o tu pamėgink dar kartą.</Trans>;

class MessagesContainer extends React.Component<IMessagesContainer> {
    messages = null;
    constructor(props) {
        super(props);
        this.messages = messages({
            clearMessages: props.clearMessages,
            setModal: props.setModal,
            hideAllModals: props.hideAllModals,
        });
    }

    toggle = () => {
        this.props.clearMessages();
    }

    getMessages() {
        if (!this.props.messages.length) {
            return null;
        }
        return this.props.messages.map((message) => {
            const hideModal = idx(this, _ => _.messages[message.actionType]['default']['hideModal']);
            if (hideModal === true) {
                return null;
            }
            const messageGroup = this.messages[message.actionType] && this.messages[message.actionType][message.errorType || 'default'];
            if (messageGroup) {
                return Object.assign({}, messageGroup, message);
            } else if (message['title'] && message['message']) {
                return message;
            } else if (message['message']) {
                return {
                    code: message['code'] || null,
                    message: message['message']
                };
            } else {
                return {
                    title: message['title'] || null,
                    message: DEFAULT_MESSAGE
                };
            }
        });
    }

    render() {
        const messages = this.getMessages();
        if (!messages) {
            return null;
        }

        return messages.map((message, index) => {
            // maybe we need to ignore this popup
            if (message === null) {
                return null;
            }
            const messageButton = message.urlParams ? message.button(message.urlParams) : message.button;
            return (
                <MessageModal
                    key={index}
                    code={message.code || null}
                    title={message.title || null}
                    message={message.message}
                    onClose={this.toggle}
                    button={messageButton || null}
                />
            );
        });
    }
}

const mapStateToProps = (state) => {
    return {
        messages: state.messages
    };
};
const mapDispatchToProps = {
    clearMessages,
    setModal,
    hideAllModals,
};

const MessagesContainerConnected = connect(mapStateToProps, mapDispatchToProps)(MessagesContainer as any);

export {MessagesContainerConnected as MessagesContainer};
