
/* injects from baggage-loader */

import * as React from 'react';
import {connect} from 'react-redux';
import { isAuthenticated, isBoxOfficeUser } from '../../../../selectors/user';
import {Redirect} from 'react-router-dom';
import { urls } from '../../../../routes';
import {history} from '../../../../history';

/**
 * Higher order component to secure route
 * @param Component
 * @returns {ComponentClass<Omit<TInjectedProps, keyof {isAuthenticated: boolean} & DispatchProp<any> & {}> & {}> & {WrappedComponent: Component<TInjectedProps>}}
 */
export function secureComponent(Component) {
    class SecuredComponent extends React.Component<{isAuthenticated: boolean; isBoxOfficeUser: boolean; isGettingUser: boolean}> {
        render() {
            const redirectTo = `${urls.get('user:login')}?continue=${history.location.pathname}`;
            return (
                this.props.isAuthenticated && !this.props.isBoxOfficeUser ? <Component {...this.props} /> : <Redirect to={redirectTo}/>
            );
        }
    }

    const mapStateToProps = (state) => ({
        isAuthenticated: isAuthenticated(state),
        isBoxOfficeUser: isBoxOfficeUser(state)
    });
    return connect(mapStateToProps)(SecuredComponent as any);
}
