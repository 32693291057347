
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import { history } from '../history';
import { HISTORY } from '../actions/routeHistory';

class RouteHistory extends BaseReducer {
    static defaultState = [];
    constructor() {
        super(RouteHistory.defaultState);
        this.actionMap = {
            [HISTORY.PUSH]: this.logHistory.bind(this)
        };
    }

    logHistory(state, action) {
        return [
            ...state,
            action.pathname + history.location.search
        ];
    }
}

export default new RouteHistory().reducer;
