
/* injects from baggage-loader */

import {apiActionCreator} from '../utils/api/apiActionCreator';
import {API_URL} from '../utils/api/constants';

export const GROUP = {
    GET_ALL: 'GET_GROUPS',
    GET: 'GET_GROUP',
};

export function getGroups() {
    return apiActionCreator({
        endpoint: API_URL + '/event/group',
        method: 'GET',
        type: GROUP.GET_ALL,
        bailout: (state) => {
            return !!(state.groups && state.groups.ids && state.groups.ids.length > 1);
        }
    });
}

export function getGroup(shortId: number) {
    return apiActionCreator({
        endpoint: `${API_URL}/event/group/${shortId}`,
        method: 'GET',
        type: GROUP.GET,
        bailout: (state) => {
            return !!(state.groups && state.groups.byId && state.groups.byId[shortId]);
        }
    });
}
