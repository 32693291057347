
/* injects from baggage-loader */

import {apiActionCreator} from '../utils/api/apiActionCreator';
import {API_URL} from '../utils/api/constants';
import { isBoxOfficeUser } from '../selectors/user';

export const HOURLY_SLOTS = {
    GET_HOURLY_SLOTS: 'GET_HOURLY_SLOTS'
};

export function getHourlySlots(eventId: number, cityId: number) {
    return (dispatch, getState) => {
        const _isBoxOfficeUser = isBoxOfficeUser(getState());
        dispatch(apiActionCreator({
            endpoint: `${API_URL}/event/show/hourlyslots?boxOffice=${_isBoxOfficeUser}&eventId=${eventId}&cityId=${cityId}`,
            method: 'GET',
            type: HOURLY_SLOTS.GET_HOURLY_SLOTS,
        }));
    };
}
