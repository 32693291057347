
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {ORDER} from '../actions/order';
import { reindexAndGroupCartVouchers } from '../utils/vouchers/utils';
import {CART} from '../actions/cart';

class Orders extends BaseReducer {
    static defaultState = null;
    constructor() {
        super(Orders.defaultState);
        this.actionMap = {
            [`${ORDER.GET}_SUCCESS`]: this.getOrder.bind(this),
            [`${CART.BOX_OFFICE_REQUEST_PURCHASE}_SUCCESS`]: this.clearOrders.bind(this),
        };
    }

    clearOrders() {
        return Orders.defaultState;
    }

    getOrder(state, action) {
        const orders = action.payload.orders;
        orders.map((order) => {
            order.giftVouchers = reindexAndGroupCartVouchers(order.giftVouchers);
            return order;
        });
        return orders;
    }
}

export default new Orders().reducer;
