
/* injects from baggage-loader */

import * as React from 'react';
import {useSelector} from 'react-redux';
import {isBoxOfficeAds, isBoxOfficeMirror} from '../../selectors/boxOffice';

export function useVisibleDisplay(_isBoxOfficeUrl) {
    const [visible, setVisible] = React.useState(true);
    const _isBoxOfficeMirror = useSelector(isBoxOfficeMirror);
    const _isBoxOfficeAds = useSelector(isBoxOfficeAds);

    let hidden;
    let visibilityChange;
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof (document as any).msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }

    React.useEffect(() => {
        if (_isBoxOfficeUrl && !(_isBoxOfficeMirror || _isBoxOfficeAds)) {
            document.addEventListener(visibilityChange, handleVisibilityChange, false);
        }
        return () => {
            if (_isBoxOfficeUrl && !(_isBoxOfficeMirror || _isBoxOfficeAds)) {
                document.removeEventListener(visibilityChange, handleVisibilityChange);
            }
        };
    }, [_isBoxOfficeUrl]);

    function handleVisibilityChange() {
        if (document[hidden]) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    }

    return visible;
}
