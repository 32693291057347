
/* injects from baggage-loader */

import { createSelector } from 'reselect';
import { IReduxStore } from '../contracts/store';
import { IFee, ITicket } from '../contracts/tickets';
import { IOrder } from '../contracts/order';

export interface ITotalTickets {
    fees: Array<IFee>;
    withoutDiscount: number;
    discount: number;
    grandTotal: number;
}

const getTickets = (state: IReduxStore) => state.tickets;

export const sumTickets = createSelector([getTickets], (tickets) => {
    const total = {
        fees: [],
        withoutDiscount: 0,
        discount: 0,
        grandTotal: 0
    };

    if (tickets.ids && tickets.ids.length > 0) {
        tickets.ids.forEach(ticketId => {
            const ticket: ITicket = tickets.byId[ticketId];
            const fees = (ticket.fees || []).reduce((a, b: IFee) => {
                return a + b.sumTotal;
            }, 0);

            ticket.fees.forEach((fee) => {
                const index = total.fees.findIndex((_fee) => {
                    return _fee.taxTypeId === fee.taxTypeId;
                });
                if (index !== -1) {
                    total.fees[index].sumTotal += fee.sumTotal;
                } else {
                    const feeClone = Object.assign({}, fee);
                    total.fees.push(feeClone);
                }
            });

            total.withoutDiscount += ticket.price;
            total.discount += (ticket.price - ticket.priceWithDiscount) || 0;
            total.grandTotal += (typeof ticket.priceWithDiscount === 'undefined' ? ticket.price : ticket.priceWithDiscount) + fees;
        });
    }

    return total;
});

export function getLastOrder(orders: Array<IOrder>) {
    const lastOrder = orders && orders.length > 0 && orders[0];
    if (lastOrder) {
        return lastOrder;
    }
    return null;
}

export function getOrderTicketCount(cart: IOrder): number {
    if (!cart || !cart.shows || cart.shows.length <= 0) {
        return 0;
    }

    let total = 0;
    cart.shows.forEach((show) => {
        total += show.tickets.length;
    });

    return total;
}

export function getRecaptchaToken(state: IReduxStore): string {
    return state.tickets.recaptchaToken;
}
