
/* injects from baggage-loader */

import * as React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { urls } from '../../routes';
import { history } from '../../history';
import { storeRegistry } from '../../store/storeRegistry';
import { isAuthenticated } from '../../selectors/user';
import { createLoadingSelector } from '../../selectors/loading';
import { NewWindow } from '../../utils/newWindow';
import {
    getBoxOfficeMirrorUrl,
    addBOMirrorEventListener,
    removeBOMirrorEventListener,
    addSeriesBOMirrorEventListener,
    sendBOMirrorMessage
} from '../../utils/boxOffice/boxOffice';
import { isBoxOfficeMirror, isBoxOfficeAds } from '../../selectors/boxOffice';
import { hasScreenMirroring } from '../../selectors/user';

import { HISTORY } from '../../actions/routeHistory';
import { VENUE } from '../../actions/venue';
import { BOX_OFFICE } from '../../actions/boxOffice';

interface IProps {
    isGettingUser: () => {};
    isAuthenticated: boolean;
    hasScreenMirroring: boolean;
    isBoxOfficeMirror: boolean;
    isBoxOfficeAds: boolean;
}

interface IState {
    mirrorScrollEventDispatcherAttached: boolean;
}

class BoxOfficeMirrorWindow extends React.Component<IProps, IState> {
    state = {
        mirrorScrollEventDispatcherAttached: false,
    };
    store = null;

    constructor(props) {
        super(props);

        if (this.props.isBoxOfficeMirror) {
            addBOMirrorEventListener(this.boxOfficeMirrorQueuedEventHandler);
            addSeriesBOMirrorEventListener(this.boxOfficeMirrorSeriesEventHandler);
        }
    }

    componentDidMount() {
        if (this.props.isBoxOfficeMirror) {
            this.store = storeRegistry.getStore();
        }
    }

    componentDidUpdate() {
        if (!this.state.mirrorScrollEventDispatcherAttached && this.props.hasScreenMirroring && !this.props.isBoxOfficeMirror && !this.props.isBoxOfficeAds) {
            window.addEventListener('scroll', this.dispatchWindowScrollToMirror);
            this.setState({
                mirrorScrollEventDispatcherAttached: true
            });
        }
    }

    componentWillUnmount() {
        if (this.props.hasScreenMirroring && !this.props.isBoxOfficeMirror && !this.props.isBoxOfficeAds) {
            removeBOMirrorEventListener(this.boxOfficeMirrorQueuedEventHandler);
            removeBOMirrorEventListener(this.boxOfficeMirrorSeriesEventHandler);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isGettingUser || this.props.isBoxOfficeMirror && !nextProps.isBoxOfficeMirror ) {
            return false;
        }
        if (this.props.isBoxOfficeAds || nextProps.isBoxOfficeAds) {
            return false;
        }

        return true;
    }

    dispatchWindowScrollToMirror(event) {
        // ie11 hax for non existent window.innerHeight
        const windowInnerHeight = window.innerHeight || Math.max(document.documentElement.clientHeight, document.body.clientHeight);
        const payload = {
            verticalPosition: (window.pageYOffset / (document.documentElement.scrollHeight - windowInnerHeight)).toFixed(2),
        };

        sendBOMirrorMessage({ type: BOX_OFFICE.WINDOW_SCROLL, payload });
    }

    handleWindowScroll(payload) {
        const windowInnerHeight = window.innerHeight || Math.max(document.documentElement.clientHeight, document.body.clientHeight);
        const normalizedScrollY = Math.round((document.documentElement.scrollHeight - windowInnerHeight) * payload.verticalPosition);
        window.scrollTo(0, normalizedScrollY);
    }

    boxOfficeMirrorQueuedEventHandler = (action) => {
        switch (action.type) {
            case VENUE.VENUE_COMPONENT_POSITION_CHANGE:
            case VENUE.VENUE_COMPONENT_PIVOT_CHANGE:
            case VENUE.VENUE_COMPONENT_ZOOM_CHANGE:
            case VENUE.VENUE_COMPONENT_SHELL_CHANGE:
                break;
            case HISTORY.PUSH:
                if (action.pathname.includes(urls.get('cart:payment') ) && action.pathname !== urls.get('cart')) {
                    history.push(urls.get('cart'));
                } else if (action.pathname === urls.get('tickets')) {
                    history.push(urls.get('box-office:ticket-purchase-success'));
                } else {
                    history.push(action.pathname);
                }
                break;
            default:
                this.store.dispatch({ ...action });
        }
    }

    boxOfficeMirrorSeriesEventHandler = (action) => {
        switch (action.type) {
            case BOX_OFFICE.WINDOW_SCROLL:
                this.handleWindowScroll( action.payload );
                break;
        }
    }

    render() {
        const shouldDisplayMirror = this.props.isAuthenticated && !this.props.isGettingUser && this.props.hasScreenMirroring && !this.props.isBoxOfficeMirror && !this.props.isBoxOfficeAds;
        return shouldDisplayMirror && <NewWindow title='box office ads' name='box-office-screen-mirror' url={getBoxOfficeMirrorUrl()} />;
    }
}

const loginSelector = createLoadingSelector(['LOGIN', 'GET_CURRENT_USER']);

const mapStateToProps = (state) => {
    return {
        isGettingUser: loginSelector(state),
        isAuthenticated: isAuthenticated(state),
        hasScreenMirroring: hasScreenMirroring(state),
        isBoxOfficeMirror: isBoxOfficeMirror(state),
        isBoxOfficeAds: isBoxOfficeAds(state),
    };
};

const BoxOfficeMirrorWindowConnected = connect<IProps>(mapStateToProps)(BoxOfficeMirrorWindow);
export {BoxOfficeMirrorWindowConnected as BoxOfficeMirrorWindow};
