
/* injects from baggage-loader */

import * as queryString from 'query-string';
import idx from 'idx';

import { history } from '../history';
import browserStorageUtils from './browserStorage/utils';
import {THEME, THEME_REFERRER} from './browserStorage/constants';
import {IThemeGQLParams} from '../queries/theme';
import { IPriceCategory } from '../contracts/tickets';

export class ThemeManager {
    static unlisten = null;
    static setRouteListener = (fn): void => { ThemeManager.unlisten = fn; };
    static removeRouteListener = (): void => ThemeManager.unlisten();
}

export interface IThemeParams {
    theme: string;
    headerHtml?: string;
    cartButtonTemplate?: string;
    footerHtml?: string;
    homeUrl?: string;
    stylesFile?: string;
    jsLibraries?: string;
    scripts?: string;
    colorPrimary?: string;
    colorPrimaryHover?: string;
    enableEventPage?: boolean;
    referrerUrl?: string;
    favIcon?: number;
    sandglassImage?: number;
    trimEventTitle?: boolean;
}

const sessionStorage = browserStorageUtils.getSessionStorage();

export const getTheme = (): IThemeParams => {
    const themeParams = sessionStorage.getItem(THEME);
    const themeReferrer = sessionStorage.getItem(THEME_REFERRER);
    if (!themeParams) {
        return null;
    }
    if (!themeParams.theme) {
        return null;
    }
    return Object.assign( themeParams, {referrerUrl: themeReferrer} );
};

export const getThemeName = (): string => {
    const themeParams = getTheme();
    return themeParams ? themeParams.theme : null;
};

export const isThemeRequired = (): boolean => {
    if (queryString.length <= 0) {
        return false;
    }
    const query = queryString.parse(window.location.search);
    if (!query.theme) {
        return false;
    }
    return true;
};

export const isThemeLoaded = (): boolean => {
    const themeParams = getTheme();
    return !!(themeParams && themeParams.theme && themeParams.headerHtml);
};

/**
 * @deprecated
 * @returns {any}
 */
export const getThemedEvent = () => {
    const themeParams = sessionStorage.getItem(THEME);
    if (!themeParams) {
        return false;
    }
    if (!themeParams.theme) {
        return false;
    }
    return themeParams;
};

const USE_CUSTOM_THEME_SORT = {  // only prod IDs
    1102: (priceCategories) => [
        priceCategories.find(pc => pc.id === 1),
        ...priceCategories
            .filter(pc => ![1, 2, 3, 4, 5, 6, 7, 9].includes(pc.id))
            .sort((a, b) => b.price - a.price),
        priceCategories.find(pc => pc.id === 2),
        priceCategories.find(pc => pc.id === 3),
        priceCategories.find(pc => pc.id === 4),
        priceCategories.find(pc => pc.id === 5),
        priceCategories.find(pc => pc.id === 9),
        priceCategories.find(pc => pc.id === 6),
        priceCategories.find(pc => pc.id === 7),
    ],
    1380: (priceCategories) => priceCategories.sort((a, b) => a.id - b.id),
    2613: (priceCategories) => priceCategories.sort((a, b) => a.id - b.id),
    2764: (priceCategories) => [
        priceCategories.find(pc => pc.id === 1),
        priceCategories.find(pc => pc.id === 2),
        priceCategories.find(pc => pc.id === 4),
        priceCategories.find(pc => pc.id === 15),
        priceCategories.find(pc => pc.id === 5),
        priceCategories.find(pc => pc.id === 6),
        priceCategories.find(pc => pc.id === 7),
        priceCategories.find(pc => pc.id === 8),
        priceCategories.find(pc => pc.id === 9),
        priceCategories.find(pc => pc.id === 10),
        priceCategories.find(pc => pc.id === 11),
        priceCategories.find(pc => pc.id === 12),
        priceCategories.find(pc => pc.id === 13),
        priceCategories.find(pc => pc.id === 14),
        priceCategories.find(pc => pc.id === 17),
    ],
};

const HIDDEN_STARTING_PRICE_EVENTS = [1020, 1096, 1097, 1248]; // only prod IDs

export const hasHiddenStartingPrice = (showId: number): boolean => {
    return HIDDEN_STARTING_PRICE_EVENTS.includes(showId);
};

export const getCustomThemeSort = (showId: number, priceCategories: Array<IPriceCategory>) => {
    return USE_CUSTOM_THEME_SORT[showId](priceCategories);
};

export const hasCustomThemeSort = (showId: number): boolean => {
    return Object.keys(USE_CUSTOM_THEME_SORT).includes(String(showId));
};

export const isGranatosTheme = () => {
    const themeParams = getTheme();
    if (themeParams && themeParams.theme && themeParams.theme === 'granatos') {
        return true;
    }
    return false;
};

const normalizeTheme = (themeParams: IThemeGQLParams): IThemeParams => {
    const themeReferrer = sessionStorage.getItem(THEME_REFERRER);
    return {
        theme: idx(themeParams, _ => _.name.iv),
        homeUrl: idx(themeParams, _ => _.homeUrl.iv),
        headerHtml: idx(themeParams, _ => _.headerHtml.iv),
        cartButtonTemplate: idx(themeParams, _ => _.cartButtonTemplate.iv),
        footerHtml: idx(themeParams, _ => _.footerHtml.iv),
        stylesFile: idx(themeParams, _ => _.stylesFile.iv[0].url),
        scripts: idx(themeParams, _ => _.scripts.iv),
        colorPrimary: idx(themeParams, _ => _.colorPrimary.iv),
        colorPrimaryHover: idx(themeParams, _ => _.colorPrimaryHover.iv),
        jsLibraries: idx(themeParams, _ => _.jsLibraries.iv),
        enableEventPage: idx(themeParams, _ => _.enableEventPage.iv),
        favIcon: idx(themeParams, _ => _.favIcon.iv[0].id),
        sandglassImage: idx(themeParams, _ => _.sandglassImage.iv[0].id),
        referrerUrl: themeReferrer,
        trimEventTitle: idx(themeParams, _ => _.trimEventTitle.iv)
    };
};

export const setTheme = (themeParamsArg: IThemeParams) => {
    let themeParams = themeParamsArg;

    if (themeParams.theme === getThemeName() ) {
        themeParams = Object.assign(getTheme(), themeParams);
    }

    sessionStorage.setItem(THEME, themeParams);

    if (themeParams.referrerUrl) {
        sessionStorage.setItem(THEME_REFERRER, themeParams.referrerUrl);
    } else {
        sessionStorage.removeItem(THEME_REFERRER);
    }

    ThemeManager.setRouteListener(
        history.listen((location) => {
            if (!location.search.includes('theme=' + themeParams.theme)) {
                const search = queryString.parse(location.search);
                search.theme = themeParams.theme;
                if (themeParams.referrerUrl) {
                    search.theme_referrer = themeParams.referrerUrl;
                }
                history.replace(location.pathname + '?' + queryString.stringify(search));
            }
        })
    );
};

export const updateTheme = (themeParams: IThemeGQLParams): IThemeParams => {
    const theme = normalizeTheme(themeParams);
    sessionStorage.setItem(THEME, theme);
    return theme;
};

export const removeTheme = (redirect: boolean = true) => {
    sessionStorage.removeItem(THEME);
    sessionStorage.removeItem(THEME_REFERRER);

    const location = window.location;
    if (redirect && (location.search.includes('theme=') || location.search.includes('theme_referrer='))) {
        const search = queryString.parse(location.search);
        search.theme = undefined;
        search.theme_referrer = undefined;
        ThemeManager.removeRouteListener();
        setTimeout( () => history.replace(location.pathname + '?' + queryString.stringify(search)) );
    }
};

export const initTheme = () => {
    if (queryString.length <= 0) {
        removeTheme();
        return;
    }
    const query = queryString.parse(window.location.search);
    if (!query.theme) {
        removeTheme();
        return;
    }
    const params = {
        theme: query.theme,
        referrerUrl: query.theme_referrer
    };

    setTheme(params);
};
