
/* injects from baggage-loader */

import { STORAGE_PREFIX, SESSION_STORAGE } from './constants';

const {
    sessionStorage,
} = window;

const isAvailable = checkIsAvailable();

function checkIsAvailable() {
    const key = 'isAvailable';
    try {
        setItem(key, true);
        return getItem(key);
    } catch (err) {
        return false;
    }
}

function getItem(key) {
    try {
        return JSON.parse(sessionStorage.getItem(STORAGE_PREFIX + key));
    } catch (err) {
        return null;
    }
}

function setItem(key, value) {
    sessionStorage.setItem(STORAGE_PREFIX + key, JSON.stringify(value));
    return value;
}

function removeItem(key) {
    sessionStorage.removeItem(STORAGE_PREFIX + key);
    return null;
}


export default {
    getItem,
    setItem,
    removeItem,
    isAvailable,
    type: SESSION_STORAGE,
};
