
/* injects from baggage-loader */

import {Translation} from './Translation/Translation';

export function price(cents: number, showCents: boolean = false) {
    if (isNaN(cents)) {
        return '';
    }
    if (cents / 100 === Math.round(cents / 100) && showCents === false) {
        return cents / 100;
    }

    return (cents / 100).toLocaleString(Translation.getCurrentLanguage(), {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export function centsToEuros(priceInCents, round = false): number {
    return round ? Math.round(priceInCents / 100) : priceInCents / 100;
}

export function parsePhone(value) {
    if (!value) {
        return '';
    }
    if (value.length > 20) {
        value = value.slice(0, 20);
    }
    return value;
};
