
/* injects from baggage-loader */

import * as React from 'react';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import {useDispatch} from 'react-redux';
import {loginUserViaExternal} from '../../../../actions/user';
import {setMessagePayload} from '../../../../actions/message';
import {Trans} from 'react-i18next';
import {log} from '../../../../utils/logger';

const GoogleLogin = () => {
    const dispatch = useDispatch();
    const login = useGoogleLogin({
        onSuccess: (response: TokenResponse) => dispatch(loginUserViaExternal(response.access_token, 'google')),
        onError: error => {
            log(error);
            dispatch(setMessagePayload({
                actionType: 'GOOGLE_LOGIN_FAILURE'
            }))
        }
      });
  return (
    <button className='btn btn-google-login' onClick={() => login()}>
        <img className='icon mr-2' src='/assets/images/icon-google-login.svg' alt='' /> <Trans>Prisijunk su Google</Trans>
    </button>
  )
}

export { GoogleLogin};
