
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import { SHOW } from '../actions/show';

class Loading extends BaseReducer {
    static defaultState = {
        GET_SHOWS: [],
    };
    constructor() {
        super(Loading.defaultState);
    }

    reducer(state, action) {
        state = state || this.defaultState;
        const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action.type);
        if (!matches) {
            return state;
        }
        const [requestName, requestBaseName, requestState] = matches;

        if (requestBaseName === 'GET_SHOWS') {
            const searchHash = action.payload.searchHash;

            switch (requestName) {
                case SHOW.GET_SEARCH_SIMPLE_REQUEST:
                case SHOW.GET_SHOWS_REQUEST:
                    return {
                        ...state,
                        [requestBaseName]: [
                            ...state[requestBaseName].filter(el => el !== searchHash),
                            searchHash,
                        ]
                    };
                case SHOW.GET_SEARCH_SIMPLE_SUCCESS:
                case SHOW.GET_SEARCH_SIMPLE_FAILURE:
                case SHOW.GET_SHOWS_SUCCESS:
                case SHOW.GET_SHOWS_FAILURE:
                    return {
                        ...state,
                        [requestBaseName]: state[requestBaseName].filter(el => el !== searchHash),
                    };
            }
        }

        return {
            ...state,
            [requestBaseName]: requestState === 'REQUEST',
        };
    }
}

export default new Loading().reducer;
