
/* injects from baggage-loader */

import { IShow } from '../../contracts/show';
import * as moment from 'moment';
import { MarksEnum } from '../../contracts/store';
import { urls } from '../../routes';
import { IEvent } from '../../contracts/event';
import { DataLayerList } from './contracts';
import { ICartShow } from '../../contracts/cart';
import { price } from '../number';
import { IOrderShow } from '../../contracts/order';

export const dataLayer = (window as any).dataLayer || [];

export const getVariant = (show: IShow | ICartShow) => {
  const date = moment(show.startDateTime);
  return `${show.city.name}, ${date.format('Y-MM-DD HH:mm:ss')}`;
};

export const getOrderVariant = (show: IOrderShow) => {
  const date = moment(show.startDate);
  return `${show.venueCity}, ${date.format('Y-MM-DD HH:mm:ss')}`;
};

export const translateMarkToList = (mark: MarksEnum): DataLayerList | null => {
  if (mark === MarksEnum.ALL) {
    return 'recommended';
  }
  if (mark === MarksEnum.POPULAR) {
    return 'popular';
  }
  if (mark === MarksEnum.NEW_SHOW || mark === MarksEnum.NEW_EVENT) {
    return 'news';
  }
  return null;
};

export const getPageUrl = (): string => {
  return window.location.href;
};

export const getHostname = (): string => {
  return window.location.host;
};

export const getPathname = (): string => {
  return window.location.pathname;
};

export const getPageTitle = (): string => {
  return document.title;
};

export const getPageType = (): string => {
  const { name } = urls.resolve(getPathname());
  switch (name) {
    case 'home':
      return 'home';
    case 'events:filter':
    case 'events':
    case 'groups':
      return 'listing';
    case 'search':
      return 'search';
    case 'event':
    case 'event:noshow':
    case 'group':
      return 'detail';
    case 'event:tickets:club-lodge:pos':
    case 'event:tickets:club-lodge':
    case 'event:tickets':
      return 'order';
    case 'cart':
    case 'cart:id':
      return 'cart';
    case 'cart:payment':
    case 'cart:payment:error':
    case 'cart:payment:error:type':
      return 'checkout';
    case 'tickets':
    case 'user:tickets':
    case 'box-office:ticket-purchase-success':
      return 'purchase';
    default:
      return 'other';
  }
};

export const isShow = (show: any): show is IShow => {
  return typeof show.eventShortId === 'number';
};

export const getBrandFromCartShow = (
  cartShow: ICartShow | IOrderShow
): string => {
  return cartShow.brandName;
};
export const getBrand = (showOrEvent: IShow | IEvent): string => {
  return showOrEvent.promoter.brandName;
};

export const buildProducts = (cartShows: Array<ICartShow>) => {
  const products = [];
  cartShows.forEach((cartShow) => {
    const priceCategories = cartShow.priceCategories;

    (priceCategories || []).forEach((priceCategory) => {
      const ticket = priceCategory.tickets[0];
      products.push({
        id: cartShow.eventShortId,
        name: cartShow.eventTitleForAnalytics,
        price: price(ticket.priceWithDiscount || ticket.price, true),
        brand: getBrandFromCartShow(cartShow),
        category: cartShow.categories.length && cartShow.categories[0],
        variant: getVariant(cartShow),
        quantity: priceCategory.tickets.length,
      });
    });
  });
  return products;
};

export interface IProductItemObject {
  item_id: number;
  item_name: string;
  price: string | number;
  item_category: string;
  item_variant: string;
  item_brand: string;
  quantity: number;
}

interface ICartProducts {
  items: Array<IProductItemObject>;
  priceTotal: string | number;
}

export const buildCartProducts = (
  cartShows: Array<ICartShow>
): ICartProducts => {
  const items: Array<IProductItemObject> = [];
  const priceTotalArray: Array<number> = [];
  cartShows.forEach((cartShow) => {
    const priceCategories = cartShow.priceCategories;

    (priceCategories || []).forEach((priceCategory) => {
      const ticket = priceCategory.tickets[0];
      items.push({
        item_id: cartShow.eventShortId,
        item_name: cartShow.eventTitleForAnalytics,
        price: price(ticket.priceWithDiscount || ticket.price, true),
        item_brand: getBrandFromCartShow(cartShow),
        item_category: cartShow.categories.length && cartShow.categories[0],
        ...(cartShow.categories.length && cartShow.categories[1] && { item_category2: cartShow.categories[1] }),
        ...(cartShow.categories.length && cartShow.categories[2] && { item_category3: cartShow.categories[2] }),
        ...(cartShow.categories.length && cartShow.categories[3] && { item_category4: cartShow.categories[3] }),
        ...(cartShow.categories.length && cartShow.categories[4] && { item_category5: cartShow.categories[4] }),
        item_variant: getVariant(cartShow),
        quantity: priceCategory.tickets.length,
      });
      priceTotalArray.push(
        (ticket.priceWithDiscount || ticket.price) *
          priceCategory.tickets.length
      );
    });
  });
  const priceTotal = priceTotalArray.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  return {
    items,
    priceTotal: price(priceTotal, true),
  };
};

export const dataLayerPush = (object: any): void => {
  dataLayer.push({ ecommerce: null });
  dataLayer.push(object);
};
