
/* injects from baggage-loader */

import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { ROUTES_MAP } from '../../../../routes';
import { SplashScreenContainer } from '../../../../pages/SplashScreen/SplashScreenContainer';
import { createLoadingSelector } from '../../../../selectors/loading';
import { isBoxOfficeUrl } from '../../../../utils/boxOffice/boxOffice';
import { isBoxOfficeMirror } from '../../../../selectors/boxOffice';
import { hasPOSAds } from '../../../../selectors/user';

interface IRouteHOCProps {
    isGettingUser: () => {};
    isBoxOfficeMirror: boolean;
    waitForAuthentication: boolean;
    canMirror: boolean;
    hasPOSAds: boolean;
}

class RouteHOC extends React.PureComponent<IRouteHOCProps & RouteProps> {
    static defaultProps = {
        waitForAuthentication: false,
        canMirror: false,
    };

    render() {
        const {waitForAuthentication, isBoxOfficeMirror, canMirror, isGettingUser, hasPOSAds, ...props} = this.props;

        if (
            isBoxOfficeMirror &&
            (!canMirror || props.path === ROUTES_MAP['home'] && hasPOSAds)
        ) {
            return <SplashScreenContainer />;
        }
        return (waitForAuthentication || isBoxOfficeUrl()) && isGettingUser
            ? null
            : <Route {...props} />;
    }
}

const loginSelector = createLoadingSelector(['LOGIN', 'GET_CURRENT_USER']);

const mapStateToProps = (state, props) => ({
    isGettingUser: loginSelector(state),
    isBoxOfficeMirror: isBoxOfficeMirror(state),
    hasPOSAds: hasPOSAds(state),
    ...props,
});

const RouteHOCConnected = connect(mapStateToProps)(RouteHOC as any);
export {RouteHOCConnected as RouteHOC};
