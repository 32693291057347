
/* injects from baggage-loader */

import idx from 'idx';

export const createLoadingSelector = (actions) => (state) => {
    return actions.some(action => {
        if (typeof action === 'string') {
            if (state.loading[action] instanceof Array) {
                return state.loading[action].length > 0;
            } else {
                return state.loading[action];
            }
        } else if (typeof action === 'object') {
            const target = Object.keys(action)[0];
            return idx(state.loading, _ => _[target]) && state.loading[target].includes(action[target]);
        }
    });
};
