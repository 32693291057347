
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {CLASSIFICATION} from '../actions/classification';
import {VENUE} from '../actions/venue';

class Static extends BaseReducer {
    constructor() {
        super({
            cities: [],
            citiesWithShows: [],
            categories: [],
            venueUrl: null,
            links: [],
        });

        this.actionMap = {
            [`${CLASSIFICATION.GET_CITIES}_SUCCESS`]: this.getCities.bind(this),
            [`${CLASSIFICATION.GET_CATEGORIES}_SUCCESS`]: this.getCategories.bind(this),
            [`${VENUE.GET_LINK}_SUCCESS`]: this.getVenueLink.bind(this),
            [`${CLASSIFICATION.GET_CITIES_WITH_SHOWS}_SUCCESS`]: this.getCitiesWithShows.bind(this),
            [`${CLASSIFICATION.GET_SEO_LINKS}_SUCCESS`]: this.getSeoLinks.bind(this),
        };
    }

    getSeoLinks(state, action) {
        return {
            ...state,
            links: action.payload.links,
        };
    }

    getCities(state, action) {
        return {
            ...state,
            cities: action.payload.cities,
        };
    }

    getCitiesWithShows(state, action) {
        return {
            ...state,
            citiesWithShows: action.payload.cities,
        };
    }

    getCategories(state, action) {
        return {
            ...state,
            categories: action.payload.categories,
        };
    }

    getVenueLink(state, action) {
        return {
            ...state,
            venueUrl: action.payload,
        };
    }
}
export default new Static().reducer;
