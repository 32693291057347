
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import { LANGUAGE } from '../actions/language';
import { DEFAULT_LANGUAGE } from '../constants/i18n';

class Language extends BaseReducer {
    static defaultState = DEFAULT_LANGUAGE;

    constructor() {
        super(Language.defaultState);
        this.actionMap = {
            [LANGUAGE.SET_LANGUAGE]: this.setLanguage.bind(this),
        };
    }

    setLanguage(state, action) {
        return action.payload;
    }
}

export default new Language().reducer;
