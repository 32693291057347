
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {VENUE} from '../actions/venue';
import {TICKET} from '../actions/ticket';

class Venue extends BaseReducer {
    constructor() {
        super(null);
        this.actionMap = {
            [`${VENUE.GET}_SUCCESS`]: this.getVenue.bind(this),
            [VENUE.CLEAR_VENUE]: this.clearVenue.bind(this),
            [VENUE.VENUE_COMPONENT_LOADED]: this.updateCanvasConfig.bind(this),
            [`${TICKET.REMOVE_TICKET}_SUCCESS`]: this.updateVenue.bind(this),
            [`${TICKET.ADD_TICKET}_SUCCESS`]: this.updateVenue.bind(this),
            [`${TICKET.SET_DISCOUNT}_SUCCESS`]: this.updatePriceCategories.bind(this),
            [`${TICKET.REMOVE_DISCOUNT}_SUCCESS`]: this.updatePriceCategories.bind(this),
            [`${TICKET.CHANGE_TICKET}_SUCCESS`]: this.updateVenue.bind(this),
            [`${TICKET.UPDATE_TICKETS}_SUCCESS`]: this.updateVenue.bind(this),
        };
    }

    getVenue(state, action) {
        return {
            ...state,
            ...action.payload
        };
    }

    updateVenue(state, action) {
        return {
            ...state,
            venue: action.payload.venueUpdate,
            priceCategories: action.payload.priceCategories,
        };
    }

    updatePriceCategories(state, action) {
        return {
            ...state,
            priceCategories: action.payload.priceCategories,
        };
    }

    updateCanvasConfig(state, action) {
        return {
            ...state,
            canvasConfig: action.payload,
        };
    }

    clearVenue(state, action) {
        return null;
    }
}

export default new Venue().reducer;
