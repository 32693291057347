
/* injects from baggage-loader */

import { RSAA, getJSON } from 'redux-api-middleware';

import { apiActionCreator } from '../utils/api/apiActionCreator';
import { API_URL } from '../utils/api/constants';
import { IVouchersRequest } from '../contracts/store';
import { isBoxOfficeUser } from '../selectors/user';

export const VOUCHER = {
    GET_VOUCHERS: 'GET_VOUCHERS',
    GET_SELECTED_VOUCHERS: 'GET_SELECTED_VOUCHERS',
    SELECT_VOUCHERS: 'SELECT_VOUCHERS',
    RESET_SELECTED_VOUCHERS: 'RESET_SELECTED_VOUCHERS',
    APPLY_VOUCHERS: 'APPLY_VOUCHERS',
    REMOVE_VOUCHERS: 'REMOVE_VOUCHERS',
    SEARCH_VOUCHERS: 'SEARCH_VOUCHERS',
    GET_SINGLE_VOUCHER: 'GET_SINGLE_VOUCHER',
};

export function getVouchers(categoryId: number) {
    return (dispatch) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/event/show',
            method: 'GET',
            type: VOUCHER.GET_VOUCHERS,
            body: { categories: categoryId },
            bailout: (state) => {
                return !!state.vouchers.ids.length;
            }
        }));
    };
}

export function getSingleVoucher(voucherId: string) {
    return (dispatch) => {
        dispatch(apiActionCreator({
            endpoint: `${API_URL}/giftvoucher/template/${voucherId}`,
            method: 'GET',
            type: VOUCHER.GET_SINGLE_VOUCHER,
        }));
    };
}

export function getSelectedVouchers() {
    return apiActionCreator({
        endpoint: API_URL + '/giftVoucher',
        method: 'GET',
        type: VOUCHER.GET_SELECTED_VOUCHERS,
    });
}

export function selectVouchers(body: IVouchersRequest) {
    return (dispatch, getState) => {
        body.boxOffice = isBoxOfficeUser(getState());
        dispatch(apiActionCreator({
            endpoint: API_URL + '/giftVoucher',
            type: VOUCHER.SELECT_VOUCHERS,
            method: 'PUT',
            body,
        }));
    };
}

export function resetSelectedVouchers() {
    return {
        type: VOUCHER.RESET_SELECTED_VOUCHERS
    };
}

export function applyVouchers(vouchers: Array<string>, cartId: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/cart/payment/giftVoucher/${cartId}`,
        method: 'POST',
        type: VOUCHER.APPLY_VOUCHERS,
        body: vouchers
    });
}

export function removeVoucherPayment(id: string, cartId: string) {
    return apiActionCreator({
        endpoint: `${API_URL}/cart/payment/giftVoucher/${cartId}`,
        method: 'DELETE',
        type: VOUCHER.APPLY_VOUCHERS,
        body: [id]
    });
}

export function setSearchVouchers(giftVouchers, searchHash) {
    return {
        type: VOUCHER.SEARCH_VOUCHERS,
        giftVouchers,
        searchHash
    };
}
