
/* injects from baggage-loader */

import {setApiMessage} from '../../actions/message';
import {log} from '../logger';
import { isBoxOfficeMirror, isBoxOfficeAds } from '../../selectors/boxOffice';

const ignoreActions = ['GET_CART_FAILURE'];
const ignoreNotFoundActions = ['GET_EVENT_FAILURE', 'GET_SHOW_FAILURE', 'GET_PAYMENT_INFO_FAILURE', 'GET_VENUE_FAILURE', 'DELETE_CART_FAILURE'];

export const apiErrorMiddleware = ({dispatch, getState}) => next => action => {
    const state = getState();
    const doNotThrowError = isBoxOfficeMirror(state) || isBoxOfficeAds(state);

    if (!doNotThrowError && action.type) {
        const isRejected = new RegExp('FAILURE$', 'g');
        if (action.type.match(isRejected)) {
            if (ignoreActions.includes(action.type)) {
                return next(action);
            }
            if (action.payload && action.payload.name === 'ApiError') {
                let errorText = '';
                let errorType = '';
                if (action.payload.response) {
                    if (action.payload.response.error) {
                        errorType = action.payload.response.error;
                    }
                    if (action.payload.response.error && action.payload.response.error_description) {
                        errorText = action.payload.response.error_description;
                    } else if (action.payload.response.error) {
                        errorText = action.payload.response.error;
                    } else if (action.payload.response.message) {
                        errorText = action.payload.response.message;
                    } else if (action.payload.response.resultStatus) {
                        errorText = action.payload.response.resultStatus;
                    }
                } else {
                    errorText = action.message;
                }
                const statusCode = action.payload.status;
                if (!(statusCode === 404 && ignoreNotFoundActions.includes(action.type))) {
                    dispatch(setApiMessage(
                        action.type,
                        errorType,
                        action.payload.status,
                        errorText
                    ));
                }
                log({
                    actionType: action.type,
                    errorType,
                    payloadStatus: action.payload.status,
                    errorText
                });
            }
        }
    }

    return next(action);
};
