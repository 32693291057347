
/* injects from baggage-loader */

import * as React from 'react';
import {Facebook} from '../../../../utils/api/facebook';
import {connect} from 'react-redux';
import {loginUserViaFacebook} from '../../../../actions/user';
import {setMessagePayload, IMessagePayload} from '../../../../actions/message';
import {Trans} from 'react-i18next';

interface IFacebookLogin {
    loginUserViaFacebook: (token: string) => void;
    setMessagePayload: (payload: IMessagePayload) => void;
}

class FacebookLogin extends React.Component<IFacebookLogin, {}> {
    loginUser = () => {
        Facebook.login((response) => {
            if (response.authResponse) {
                this.props.loginUserViaFacebook(response.authResponse.accessToken);
            } else {
                this.props.setMessagePayload({
                    actionType: 'FACEBOOK_LOGIN_FAILURE'
                });
            }
        });
    }

    render() {
        return (
            <button className='btn btn-facebook-login mb-2' onClick={this.loginUser}>
                <img className='icon mr-2' src='/assets/images/icon-facebook-white.svg' alt='' /> <Trans>Prisijunk su Facebook</Trans>
            </button>
        );
    }
}
const mapDispatchToProps = {
    loginUserViaFacebook,
    setMessagePayload
};

const FacebookLoginConnected = connect(null, mapDispatchToProps)(FacebookLogin as any);
export {FacebookLoginConnected as FacebookLogin};
