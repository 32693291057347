
/* injects from baggage-loader */

import Cookies from 'cookies-js';

import { STORAGE_PREFIX, COOKIE_STORAGE } from './constants';

function getItem(key) {
    return Cookies.get(STORAGE_PREFIX + key);
}

function setItem(key, value) {
    Cookies.set(STORAGE_PREFIX + key, value);
}
function removeItem(key) {
    Cookies.expire(STORAGE_PREFIX + key);
}
export default {
    getItem,
    setItem,
    removeItem,
    isAvailable: true,
    type: COOKIE_STORAGE,
};
