
/* injects from baggage-loader */

import * as React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { urls } from '../../../routes';
import { history } from '../../../history';
import { IUser } from '../../../contracts/user';
import { storeRegistry } from '../../../store/storeRegistry';
import { isAuthenticated } from '../../../selectors/user';
import { isBoxOfficeMirror, isBoxOfficeAds } from '../../../selectors/boxOffice';
import { hasPOSAds } from '../../../selectors/user';
import { createLoadingSelector } from '../../../selectors/loading';
import { NewWindow } from '../../../utils/newWindow';
import {
    getBoxOfficeMirrorUrl,
    addBOMirrorEventListener,
    removeBOMirrorEventListener,
    addSeriesBOMirrorEventListener,
    sendBOMirrorMessage
} from '../../../utils/boxOffice/boxOffice';

import { HISTORY } from '../../../actions/routeHistory';
import { VENUE } from '../../../actions/venue';
import { BOX_OFFICE } from '../../../actions/boxOffice';

interface IProps {
    isGettingUser: () => {};
    isAuthenticated: boolean;
    hasPOSAds: boolean;
    isBoxOfficeMirror: boolean;
    isBoxOfficeAds: boolean;
    user: IUser;
}

class BoxOfficeAdsWindow extends React.PureComponent<IProps> {
    render() {
        const shouldDisplayPOSAds = this.props.isAuthenticated && !this.props.isGettingUser && this.props.hasPOSAds && !this.props.isBoxOfficeMirror && !this.props.isBoxOfficeAds;
        return shouldDisplayPOSAds && <NewWindow autoClose={false} title='box office mirror' name='box-office-ads' url={urls.get('box-office:ads', {carousel_id: this.props.user.addId || 'default'})} />;
    }
}

const loginSelector = createLoadingSelector(['LOGIN', 'GET_CURRENT_USER']);

const mapStateToProps = (state) => {
    return {
        isGettingUser: loginSelector(state),
        isAuthenticated: isAuthenticated(state),
        hasPOSAds: hasPOSAds(state),
        isBoxOfficeMirror: isBoxOfficeMirror(state),
        isBoxOfficeAds: isBoxOfficeAds(state),
        user: state.user.data,
    };
};

const BoxOfficeAdsWindowConnected = connect<IProps>(mapStateToProps)(BoxOfficeAdsWindow);
export {BoxOfficeAdsWindowConnected as BoxOfficeAdsWindow};
