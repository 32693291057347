
/* injects from baggage-loader */

import * as React from 'react';
import {reduxForm, InjectedFormProps, Field} from 'redux-form';
import {required, email} from 'redux-form-validators';
import { Trans } from 'react-i18next';

import {renderInput} from '../../../../utils/forms/formComponents';
import {validations} from '../../../../constants/validations';
import {FacebookLogin} from '../buttons/FacebookLogin';
import {GoogleLogin} from '../buttons/GoogleLogin';
import {ErrorBoundary} from '../ErrorBoundary';

interface IUserLoginForm {
    remindPassword: () => void;
    toggleVisibleSide?: () => void;
    rememberMe?: boolean;
}

class UserLoginForm extends React.Component<IUserLoginForm & InjectedFormProps, {}> {
    render() {
        return (
            <>
                {this.props.toggleVisibleSide &&
                <div className='social-login-wrapper'>
                    <ErrorBoundary>
                        <FacebookLogin />
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <GoogleLogin />
                    </ErrorBoundary>
                    <div className='text-sm mt-4 d-lg-none'>
                        <Trans>Neturi prisijungimo?</Trans>{' '}
                        <button
                            onClick={this.props.toggleVisibleSide}
                            type='button'
                            className='font-weight-bold btn btn-link p-0 border-0 text-sm align-baseline'
                        >
                            <Trans>Registruokis</Trans>
                        </button>
                    </div>
                    <div className='divider'><span><Trans>arba</Trans></span></div>
                </div>}
                <form className='text-left' onSubmit={this.props.handleSubmit} noValidate={true}>

                    <div className='form-group'>
                        <label htmlFor='email'><Trans>El. paštas</Trans></label>
                        <Field
                            type='email'
                            component={renderInput}
                            name='email'
                            className='form-control'
                            id='email'
                            validate={[required({msg: validations.email_field_required}), email()]}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='password'><Trans>Slaptažodis</Trans></label>
                        <Field
                            type='password'
                            component={renderInput}
                            name='password'
                            className='form-control'
                            id='password'
                            validate={[required({msg: validations.password_field_required})]}
                        />
                    </div>
                    <div className='form-group submit-group text-center mt-2 mt-lg-auto mb-0'>
                        <div className='d-flex'>
                            <div className='custom-control custom-checkbox d-inline-block mb-4 mb-lg-2 mx-lg-auto'>
                                <Field type='checkbox' component='input' name='rememberMe' className='custom-control-input' id='rememberMe' />
                                <label className='custom-control-label' htmlFor='rememberMe'><Trans>Prisiminti mane</Trans></label>
                            </div>
                            <button type='button' className='btn btn-link p-0 text-sm d-lg-none border-0 ml-auto mb-4' onClick={this.props.remindPassword}><Trans>Pamiršau slaptažodį</Trans></button>
                        </div>
                        <button type='submit' className='btn btn-primary btn-lg w-100'><Trans>Prisijungti</Trans></button>
                    </div>
                    <div className='forgot-password-wrapper text-center d-none d-lg-block'>
                        <button type='button' className='btn btn-link p-0 text-sm' onClick={this.props.remindPassword}><Trans>Pamiršau slaptažodį</Trans></button>
                    </div>
                </form>
            </>
        );
    }
}

const createReduxForm = reduxForm<InjectedFormProps & IUserLoginForm, IUserLoginForm>({
    form: 'user_login',
    initialValues: { rememberMe: false }
})(UserLoginForm as any);

export {createReduxForm as UserLoginForm};
