
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {MODAL} from '../actions/modal';

class Modal extends BaseReducer {
    constructor() {
        super([]);
        this.actionMap = {
            [MODAL.SHOW_MODAL]: this.showModal.bind(this),
            [MODAL.HIDE_MODAL]: this.hideModal.bind(this),
            [MODAL.HIDE_TOP_MODAL]: this.hideTopModal.bind(this),
            [MODAL.CHANGE_ARGUMENTS]: this.changeModalArguments.bind(this),
            [MODAL.HIDE_ALL]: this.hideAll.bind(this),
        };
    }

    hideAll() {
        return [];
    }

    showModal(state, action) {
        return [
            ...state,
            action.payload
        ];
    }

    hideModal(state, action) {
        const index = action.index || 0;
        return [
            ...state.slice(0, index),
            ...state.slice(index + 1)
        ];
    }

    // hide modal that is on top
    hideTopModal(state) {
        return this.hideModal(state, {
            index: state.length - 1
        });
    }

    changeModalArguments(state, action) {
        return state.map((modal) => {
            if (modal.modalType !== action.payload.modalType) {
                return modal;
            }
            return {
                ...modal,
                arguments: {
                    ...modal.arguments,
                    ...action.payload.arguments,
                }
            };
        });
    }
}

export default new Modal().reducer;
