
/* injects from baggage-loader */

declare const FB: any;

export class Facebook {
    static scope = ['email'];

    static init = (locale: string) =>
        (((d, s, id) => {
            let js: any = d.getElementsByTagName(s)[0];
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = `https://connect.facebook.net/${locale}/sdk.js`;
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk'))

    static login = (cb: (response) => void) => {
        FB.login((response) => {
            if (response.authResponse) {
                if (Facebook.allRequiredPermissions(response.authResponse.grantedScopes)) {
                    cb(response);
                } else {
                    cb(false);
                }
            }
        }, {
            scope: Facebook.scope.join(','),
            auth_type: 'rerequest',
            return_scopes: true
        });
    }

    static allRequiredPermissions = (grantedScopes: string) => {
        const grantedScopesArray = grantedScopes.split(',');
        return !Facebook.scope.some((permission) => {
            return !grantedScopesArray.includes(permission);
        });
    }
}
