
/* injects from baggage-loader */

import { createStore, applyMiddleware, compose } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import {loadingBarMiddleware} from 'react-redux-loading-bar';
import thunkMiddleware from 'redux-thunk';
import * as queryString from 'query-string';

import { urls } from '../routes';
import {apiErrorMiddleware} from '../utils/api/apiErrorMiddleware';
import rootReducer from '../reducers/index';
import { boxOfficeMirrorMiddleware } from '../utils/boxOffice/boxOfficeMirrorMiddleware';

const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.hasOwnProperty('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__')
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            latency: 0
        }) : compose;

const middleware = [
    thunkMiddleware,
    apiMiddleware,
    loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE']
    }),
    boxOfficeMirrorMiddleware,
    apiErrorMiddleware,
];

export default function configureStore(initialState = {}) {
    const searchParams = queryString.parse(window.location.search);
    const route = urls.resolve(window.location.pathname);

    if ('isBoxOfficeMirror' in searchParams && searchParams.isBoxOfficeMirror === 'true') {
        initialState = Object.assign({}, initialState, {
            boxOffice: {
                isBoxOfficeMirror: true,
                isBoxOfficeAds: false,
            }
        });
    }

    if (route && route.name === 'box-office:ads') {
        initialState = Object.assign({}, initialState, {
            boxOffice: {
                isBoxOfficeMirror: false,
                isBoxOfficeAds: true,
            }
        });
    }

    return createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(...middleware)
        )
    );
}
