
/* injects from baggage-loader */

import { DEFAULT_LANGUAGE, ACTIVE_LANGUAGES, ACTIVE_LANGUAGES_OBJ } from '../../constants/i18n';
import browserStorageUtils from '../browserStorage/utils';
import { LANGUAGE } from '../browserStorage/constants';

const browserStorage = browserStorageUtils.getAvailableStorage();

export class Translation {
    static currentLanguage = DEFAULT_LANGUAGE;

    static getCurrentLanguage() {
        return Translation.currentLanguage;
    }

    static getActiveLanguages() {
        return ACTIVE_LANGUAGES;
    }

    static getLanguagePrefix(language: string = null): string {
        language = language || this.getCurrentLanguage();
        return language === DEFAULT_LANGUAGE ? '' : '/' + language;
    }

    static getCurrentLanguagePrefix() {
        return Translation.getLanguagePrefix(Translation.currentLanguage);
    }

    static setCurrentLanguage(language: string) {
        browserStorage.setItem(LANGUAGE, language);
        return Translation.currentLanguage = language;
    }
}

export type LanguageSlugs = keyof typeof ACTIVE_LANGUAGES_OBJ;
