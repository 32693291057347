
/* injects from baggage-loader */

import {apiActionCreator} from '../utils/api/apiActionCreator';
import {API_URL} from '../utils/api/constants';

export const NEWSLETTER = {
    SUBSCRIBE: 'SUBSCRIBE',
    ACTIVATE_NEWSLETTER: 'ACTIVATE_NEWSLETTER'
};

export function subscribeNewsletter(email: string, cb?: (args?) => void) {
    return apiActionCreator({
        endpoint: API_URL + '/newsletter/subscribe',
        method: 'POST',
        type: NEWSLETTER.SUBSCRIBE,
        body: email,
        onSuccess: cb
    });
}

export function activateNewsletter(activationId: string) {
    return apiActionCreator({
        endpoint: API_URL + '/newsletter/activate',
        method: 'POST',
        type: NEWSLETTER.ACTIVATE_NEWSLETTER,
        body: activationId
    });
}
