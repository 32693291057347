
/* injects from baggage-loader */

import * as React from 'react';
import { getTheme, removeTheme, isThemeRequired, isThemeLoaded } from '../../../../utils/theme';

export function themeHomeRedirect(Component) {
    return class extends React.Component {
        render() {
            if (isThemeRequired() && isThemeLoaded()) {
                const themeParams = getTheme();
                removeTheme(false);
                window.location.href = (themeParams.referrerUrl || themeParams.homeUrl);
                return null;
            }
            return <Component {...this.props} />;
        }
    };
}
