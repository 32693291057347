
/* injects from baggage-loader */

import BroadcastChannel from 'broadcast-channel';

import { Translation } from '../Translation/Translation';
import { BOX_OFFICE_SUBDOMAIN } from '../../constants/boxOffice';

interface IMessage {
    readonly type: any;
    readonly payload?: any;
    readonly meta?: any;
    readonly pathname?: any;
}

const SERIES_PAYLOAD_CHANNEL_KEY = 'seriesPayloadChannel';
const QUEUED_EVENT_CHANNEL_KEY = 'queuedEventChannel';
const QUEUED_PAYLOAD_CHANNEL_KEY = 'queuedPayloadChannel';

const broadcastChannelOptions = {webWorkerSupport: false};
const seriesPayloadChannel: BroadcastChannel<IMessage> = new BroadcastChannel(SERIES_PAYLOAD_CHANNEL_KEY, broadcastChannelOptions);
const queuedEventChannel: BroadcastChannel<IMessage> = new BroadcastChannel(QUEUED_EVENT_CHANNEL_KEY, broadcastChannelOptions);
const queuedPayloadChannel: BroadcastChannel<IMessage> = new BroadcastChannel(QUEUED_PAYLOAD_CHANNEL_KEY, broadcastChannelOptions);

const messageStack = {
    isProcessing: false,
    queue: [],
};

function processMessageStackQueue() {
    if (messageStack.isProcessing) {
        return;
    }

    messageStack.isProcessing = true;
    const message = messageStack.queue.shift();

    if (message) {
        const {payload, ...noPayload} = message;

        queuedPayloadChannel.postMessage(message);
        queuedEventChannel.postMessage({payload: '__update__', ...noPayload});

        setTimeout(() => {
            messageStack.isProcessing = false;
            return processMessageStackQueue();
        }, 300);
    } else {
        messageStack.isProcessing = false;
        return;
    }
}

export function sendBOMirrorMessage(message: IMessage) {
    seriesPayloadChannel.postMessage(message);
}

export function addSeriesBOMirrorEventListener(handler: any) {
    seriesPayloadChannel.addEventListener('message', handler);
}

export function queueBOMirrorMessage(message: IMessage) {
    if (queuedEventChannel.type === 'localstorage') {
        messageStack.queue.push(message);
        processMessageStackQueue();
    } else {
        queuedPayloadChannel.postMessage(message);
    }
}

export function addBOMirrorEventListener(handler: any) {
    if (queuedEventChannel.type === 'localstorage') {
        const wrapperHandler = (e) => {
            const item = JSON.parse(window.localStorage.getItem('pubkey.broadcastChannel-' + QUEUED_PAYLOAD_CHANNEL_KEY));
            if (item.data.data.type === e.type) {
                handler(item.data.data);
            }
        };

        queuedEventChannel.addEventListener('message', wrapperHandler);
    } else {
        queuedPayloadChannel.addEventListener('message', handler);
    }
}

export function removeBOMirrorEventListener(handler: any) {
    if (queuedEventChannel.type === 'localstorage') {
        // fixme: remove anonymous function listeners
    } else {
        queuedEventChannel.removeEventListener('message', handler);
    }
}

export function isBoxOfficeUrl(url?: string) {
    const target = url || window.location.origin;
    const pattern = new RegExp(`//${BOX_OFFICE_SUBDOMAIN}\.`);
    return !!target.match(pattern);
}

export function getBoxOfficeMirrorUrl() {
    return window.location.origin + Translation.getLanguagePrefix() + '?isBoxOfficeMirror=true';
}
