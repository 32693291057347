
/* injects from baggage-loader */

import { IFee, ITicket } from './tickets';
import { ILocation, IPicture, ICity, IVoucherCartItem } from './store';
import { IInvoice } from './user';
import { IEvent } from './event';

export interface ICardPaymentInfo {
  name: string;
  surname: string;
  email: string;
  phone: string;
}

export interface IPurchaseRequest {
  id: string;
  paymentType: string;
  email: string;
  sendCommercialAccepted: boolean;
  sendAdditionalRejected: boolean;
  needInvoice: boolean;
  invoice?: IInvoice;
  boxOffice: boolean;
  paymentInCash?: number;
  paymentByCard?: number;
  paymentByMoQ?: number;
  cardPaymentInfo?: ICardPaymentInfo;
}

export enum PaymentTypesEnum {
  NEOPAY = 'NEOPAY',
  EVERYPAY = 'EVERYPAY',
  APPLEPAY = 'APPLEPAY',
}

export enum TypeGroupEnum {
  BANK = 'BANK',
  CARD = 'CARD',
  VOUCHER = 'VOUCHER',
  PAYMENTAGENCY = 'PAYMENTAGENCY',
  BOXOFFICECASH = 'BOXOFFICECASH',
  BOXOFFICECARD = 'BOXOFFICECARD',
  MOBILEBOXOFFICECASH = 'MOBILEBOXOFFICECASH',
  MOBILEBOXOFFICECARD = 'MOBILEBOXOFFICECARD',
  APPLEPAY = 'APPLEPAY',
  BUYNOWPAYLATER = 'BUYNOWPAYLATER',
}

export enum CartStatus {
  NOTEXIST = 1,
  PAID = 150,
}

export enum CurrencyEnum {
  EUR = 'EUR',
}

export interface IPaymentType {
  paymentType: PaymentTypesEnum | string;
  typeGroup: TypeGroupEnum;
  typeGroupName: string;
  typeGroupId: number;
  name: string;
  description?: string;
  picture?: string;
  fees: Array<IFee>;
}
export interface IPaymentTypes {
  paymentNotRequired: boolean;
  paymentTypes: Array<IPaymentType>;
}

export interface ITotalByShow {
  totalTickets: number;
  totalNominal: number;
  totalDiscount: number;
  totalFees: number;
  grandTotal: number;
  fees: Array<IFee>;
}

export interface ITotalByPricingCategory {
  total: number;
  fees: Array<IFee>;
}

export interface ICartPriceCategory {
  id: number;
  colorRGB: string;
  tickets: Array<ITicket>;
  totalByPricingCategory: ITotalByPricingCategory;
}

export interface IInsuranceUpdate {
  id: string;
  hasInsurance?: boolean;
}
export interface ICartShow {
  showId: string;
  showShortId: number;
  eventShortId: number;
  eventTitle: string;
  eventTitleForAnalytics: string;
  eventPicture: IPicture;
  location: ILocation;
  city: ICity;
  startDateTime: Date;
  endDateTime: Date;
  venueAddress: string;
  priceCategories: Array<ICartPriceCategory>;
  totalByShow: ITotalByShow;
  isVenueWithPlan: boolean;
  showDate: boolean;
  physicalDelivery?: boolean;
  brandName?: string;
  categories: Array<string>;
}

export enum CartStatus {
  WAITINGFORPAYMENT = 'WAITINGFORPAYMENT',
  CANCELED = 'CANCELED',
  VALID = 'VALID',
  PURCHASED = 'PURCHASED', // maximos kasai
}

export interface IPhysicalDelivery {
  city: string;
  street: string;
  houseNumber: string;
  apartamentNumber: string;
  zipCode: string;
  comment: string;
}

export interface ICart {
  id: string;
  status?: CartStatus; // fixme
  number: number;
  timeLeft: number;
  workInProgress: boolean;

  /* tickets */
  shows: Array<ICartShow>;
  nominalTickets: number;
  grandTotalTickets: number;

  /* vouchers */
  giftVouchers: { [hash: string]: Array<IVoucherCartItem> };
  nominalGiftVouchers: number;
  grandTotalGiftVouchers: number;
  paymentGiftVouchers?: Array<IVoucherCartItem>; // voucheris, kuriuo apmokama
  paymenGiftVouchersAmountUsedTotal: number;

  /* totals */
  totalByFee: Array<IFee>;
  discountTotal: number;
  feeTotal: number;
  grandTotal: number;
  nominalTotal: number;
  payedAmount: number;

  // physical delivery
  physicalDelivery?: IPhysicalDelivery;
}
