
/* injects from baggage-loader */

const NEO_PAY_URL = 'https://psd2.neopay.lt/widget.html';

export class NeoPayPayment {
    jwtToken: string;

    constructor(bankMessage) {
        this.jwtToken = bankMessage;
    }

    submit() {
        window.location.href = `${NEO_PAY_URL}?${this.jwtToken}`;
    }
}
