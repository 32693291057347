
/* injects from baggage-loader */

import * as React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';

import { HelmetTitleHOC } from '../common/components/utils/HelmetTitleHoc';
import { hasPOSAds } from '../../selectors/user';

interface IProps {
    hasPOSAds: boolean;
}

class SplashScreenContainer extends React.PureComponent<IProps> {

    render() {
        return (
            <>
                <HelmetTitleHOC title={this.props.hasPOSAds ? 'inactive' : ''} />
                <Helmet>
                    <meta name='robots' content='noindex' />
                </Helmet>
                <section id='main' className='row mx-0 align-items-center'>
                    <div className='col text-center'>
                        <img src='/assets/images/kakava-logo.svg' alt='Kakava.lt' style={{width: '90%', maxWidth: '500px'}} />
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    hasPOSAds: hasPOSAds(state),
    ...props,
});

const SplashScreenContainerConnected = connect<IProps>(mapStateToProps)(SplashScreenContainer);
export {SplashScreenContainerConnected as SplashScreenContainer};



