
/* injects from baggage-loader */

import {Store} from 'redux';

let store: Store<any> = null;
export const storeRegistry = {
    register: (s: Store<any>) => {
        if (!store) {
            store = s;
        }
    },

    getStore: (): Store<any> => {
        return store;
    }
};
