
/* injects from baggage-loader */

export const STORAGE_PREFIX = 'kakava-';
export const COOKIE_STORAGE = 'cookie';
export const LOCAL_STORAGE = 'local';
export const SESSION_STORAGE = 'session';

export const TOKEN_KEY = 'jwt';
export const SESSION_ID = 'session_id';
export const REMEMBER_ME = 'remember';
export const COOKIES_ACCEPTANCE = 'cookies_acceptanceV4';
export const THEME = 'theme';
export const THEME_REFERRER = 'theme_referrer';
export const LANGUAGE = 'language';

// filharmonija app
export const PURCHASER_EMAIL = 'purchaser_email';
export const FILHARMONIJA_APP_THEME = 'filharmonijaapp';
