
/* injects from baggage-loader */

import * as React from 'react';
import * as StackTrace from 'stacktrace-js';
import {log} from '../../../utils/logger';

interface IErrorBoundary {
    children: any;
}

export class ErrorBoundary extends React.Component<IErrorBoundary> {

    componentDidCatch(error, info) {
        StackTrace.fromError(error).then((stackFrames) => {
            const mappedStackTrace = stackFrames.map((sf) => {
                return sf.toString();
            }).join('\n');

            log({stackTrace: mappedStackTrace, error: error.toString && error.toString(), info});
        });
    }

    render() {
        return this.props.children;
    }
}
