
/* injects from baggage-loader */

import * as hash from 'object-hash';
import { IEventFilterParams } from '../../contracts/event';

export function getSearchFilterHash( filter: IEventFilterParams ): string {
    const filterWithoutExcludedProperties = (({boxOffice, take, skip, ...others}) => ({...others}))(filter);
    return hash( filterWithoutExcludedProperties );
}
