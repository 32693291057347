
/* injects from baggage-loader */

export function isBoxOfficeMirror(state): boolean {
    return state.boxOffice.isBoxOfficeMirror;
}

export function isBoxOfficeAds(state): boolean {
    return state.boxOffice.isBoxOfficeAds;
}
