
/* injects from baggage-loader */

import * as React from 'react';
import { Organization } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';

const Logo = (props) => {
    const imageurl = '/assets/images/kakava-logo.svg';
    return (
        <>
            <img src={imageurl} alt='Kakava.lt beta' className={props.className} />
            <JsonLd<Organization>
                item={{
                    '@context': 'https://schema.org',
                    '@type': 'Corporation',
                    "name": 'Kakava LT',
                    'url': window.location.origin,
                    'logo': window.location.origin + imageurl,
                    'sameAs': [
                        'https://www.facebook.com/kakava.lt/',
                        'https://www.instagram.com/kakava.lt/'
                        ]
                }}
            />
        </>
    );
};

export { Logo };
