
/* injects from baggage-loader */

export default class BaseReducer {
    actionMap: object;
    defaultState: any;
    /**
     * Default state should be provided from child class
     * @param defaultState
     */
    constructor(defaultState) {
        if (defaultState === undefined) {
            throw new Error('Default state should be provided');
        }
        this.actionMap = {};
        this.reducer = this.reducer.bind(this);
        this.defaultState = defaultState;
    }

    /**
     *
     * @param state
     * @param action
     * @returns {*}
     */
    reducer(state, action) {
        state = state || this.defaultState;
        const actionResponse = this.actionMap[action.type];
        return (actionResponse) ? actionResponse(state, action) : state;
    }
}
