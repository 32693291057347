
/* injects from baggage-loader */

import { ACTIVE_LANGUAGES_OBJ } from '../constants/i18n';
import { IUser } from './user';
import { MODALS } from '../actions/modal';
import { IDiscounts, ITickets, IVenue } from './tickets';
import { ICart, IPaymentTypes } from './cart';
import { IReceiptInfo, IReceiptTicket } from './order';
import { ShowMarks, IShow, IShows, ISimilarShows } from './show';
import { IEvents } from './event';
import {IGroups} from './group';
import {IMessage} from './messages';

export enum MarksEnum {
    ALL = 'ALL',
    SOLDOUT = 'SOLDOUT',
    MOVED = 'MOVED',
    CANCELED = 'CANCELED',
    POPULAR = 'POPULAR',
    NEW_EVENT = 'NEW_EVENT',
    NEW_SHOW = 'NEW_SHOW',
    PROMOTION = 'PROMOTION'
}

export enum StatusEnum {
    CANCELED = 'CANCELED',
    FINISHED = 'FINISHED',
    IN_PROGRESS = 'IN_PROGRESS',
    MOVED = 'MOVED',
    SALE_EXPIRED = 'SALE_EXPIRED',
    SELLING = 'SELLING',
    SOLDOUT = 'SOLDOUT',
    ANNOUNCEMENT = 'ANNOUNCEMENT',
    PRESALE = 'PRESALE',
}

export enum ScarcityType {
    PRICE = 'PRICE',
    PROMOTION = 'PROMOTION',
    LIMITEDOFFER = 'LIMITEDOFFER'
}

export interface ICustomMark {
    name: string;
    color?: string;
    fontColor?: string;
    pictureUrl?: string;
    rowNumber?: number;
}

export interface ICity {
    id: number;
    name: string;
    isImportant: boolean;
}

export interface ICategory {
    id: number;
    name: string;
    nameInUrl: string;
    metaDescription: string;
}

export interface ILocation {
    id: string;
    name: string;
    address: string;
}

export interface ISEOLink {
    id: string; // guid
    urlSlug: string;
    name: string;
    description: string;
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string;
    eventId?: string; // guid
    dateFrom?: string; // date time
    dateTo?: string; // date time
    priceFrom?: number;
    priceTo?: number;
    marks?: Array<MarksEnum>;
    showMarks?: Array<ShowMarks>;
    eventCategories?: Array<number>;
    promoters?: Array<string>; // guid
    isVisibleInFooter: boolean;
    urlSlugs: Array<{
        language: string;
        value: string;
    }>;
}

export interface IMultilingualSlugs {
    language: keyof typeof ACTIVE_LANGUAGES_OBJ;
    value: string;
}

export interface IPicture {
    desktopPictureUrl: string;
    mobilePictureUrl: string;
    coverPictureUrl: string;
}
export enum ScarcityPlace {
    SHOW = 'SHOW',
    SELECT_SECTOR = 'SELECT_SECTOR',
    SELECT_PLACE = 'SELECT_PLACE'
};
export interface IScarcity {
    customType: ScarcityType;
    message: string;
    place: ScarcityPlace;
}

export interface IVoucher {
    id: string;
    shortId: string;
    name: string;
    nameNoFormating: string;
    description: string;
    information: string;
    pictureUrl: string;
    prices: Array<IVoucherPriceCategory>;
    promoterName: string;
    slug: string;
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string;
    brandName?: string;
    categories?: Array<string>;
}

export interface ISearchResult {
    isEventGroup: boolean;
    isGiftVoucher: boolean;
    title: string;
    eventId: string;
    showDate: boolean;
    venueName: string;
    city: string;
    startDateTime: Date;
    titleSlug: string;
    eventShortId: string;
    shortId: string;
}

export interface IVoucherPriceCategory {
    id: string;
    name: string;
    price: number;
    priceWithFees: number;
}

export interface IVoucherCartItem extends IVoucherPriceCategory {
    shortId: string;
    amountUsed: number;
    barCode: string;
    fees: any; // @fixme
    giftVoucherPriceId: string;
    giftVoucherTemplateId: string;
    giftVoucherTemplateShortId: number;
    slug: string;
    emailSent?: string;
    brandName?: string;
    categories?: Array<string>;
}

export interface IVouchersRequest {
    selectedGiftVoucherPrices: Array<string>;
    removedGiftVouchers: Array<string>;
    boxOffice?: boolean;
}

export interface IClubDataValidationRequest {
    showId: string;
    clubCode: string;
}

export interface IVouchers {
    category: ICategory;
    isGiftVoucher: boolean;
    home: Array<number>;
    ids: Array<number>;
    byId: {
        [voucherId: string]: IVoucher;
    };
    selectedVouchers: {
        [voucherId: string]: Array<IVoucherCartItem>
    };
    search: {
        [searchHash: string]: Array<number>;
    };
}

export interface IStaticData {
    cities: Array<ICity>;
    citiesWithShows: Array<ICity>;
    categories: Array<ICategory>;
    links: Array<ISEOLink>;
}

export interface IModal {
    modalType: MODALS;
    arguments?: {
        autoHideOnDesktop?: boolean;
        loginVisible?: boolean;
        guid?: string;
    };
}
export interface ITimer {
    reservation: number;
    cart: number;
}
export interface IBoxOffice {
    isBoxOfficeMirror: boolean;
    isBoxOfficeAds: boolean;
    receipt: Array<IReceiptInfo>;
    tickets: Array<IReceiptTicket>;
    returnTickets: Array<any>; // @FIXME
}
export interface IReduxStore {
    user: {
        token: any; // fixme maybe
        data: IUser;
        cookiesAcceptance: Array<string>;
        message: {
            type: string;
            error: object;
        }
    };
    events: IEvents;
    shows: IShows;
    vouchers: IVouchers;
    staticData: IStaticData;
    modal: Array<IModal>;
    venue: IVenue;
    tickets: ITickets;
    timer: ITimer;
    discounts: IDiscounts;
    cart: ICart;
    paymentTypes: IPaymentTypes;
    routeHistory: Array<string>;
    boxOffice: IBoxOffice;
    language: string;
    groups: IGroups;
    messages: Array<IMessage>;
    settings: {
        recaptchaEnabled: boolean,
        g4aMeasurementId: string
    },
    form: {
        checkout: {
            values: {
                email: string,
                email_repeat: string,
                paymentTypeGroup: string,
                needInvoice: boolean,
                sendCommercialAccepted: boolean,
                sendAdditionalRejected: boolean,
                rulesAccepted: boolean
            }
        },
        apply_locked_code: {
            values: {
                lockedSpaceCode: string
            }
        }
    }
}
