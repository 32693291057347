
/* injects from baggage-loader */

import { Translation } from './Translation';
import browserStorageUtils from '../browserStorage/utils';
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE, ACTIVE_LANGUAGES, LANGUAGE_LOCALE_MAP } from '../../constants/i18n';
import { LANGUAGE } from '../browserStorage/constants';
import { Facebook } from '../api/facebook';

const activeLanguagesRegexp = new RegExp('^\/(' + ACTIVE_LANGUAGES.join('|') + ')\/?', 'i');
const languageMatch = window.location.pathname.match(activeLanguagesRegexp);
const browserStorage = browserStorageUtils.getAvailableStorage();
const sessionLanguage = browserStorage.getItem(LANGUAGE);

let activeLanguage = DEFAULT_LANGUAGE;

if (languageMatch && languageMatch[1]) {
    activeLanguage = languageMatch[1].toLowerCase();
} else if (
    (!window.location.pathname.length || window.location.pathname === '/') &&
    sessionLanguage &&
    sessionLanguage !== activeLanguage
) {
    activeLanguage = sessionLanguage;
    window.location.href = window.location.origin + '/' + activeLanguage;
}
Translation.setCurrentLanguage(activeLanguage);

// init Facebook script with locale
const activeLocale = LANGUAGE_LOCALE_MAP[activeLanguage];
Facebook.init(activeLocale);
