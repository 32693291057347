
/* injects from baggage-loader */

import * as React from 'react';
import Select, {components} from 'react-select';
import i18next from 'i18next';


export const reactSelectInput  = (field) => {
    const {input, options, disabled = false, placeholder = i18next.t('Pasirink'), meta, defaultValue = ''} = field;
    const value = input.value ? input.value : defaultValue;
    const selectedValue = (options || []).find(option => option.value.toString() === value.toString());

    const Option = (props) => {
        return (
            <div className={props.data && props.data.className}>
                <components.Option {...props} />
            </div>
        );
    };

    return (
        <>
            <Select
                components={{Option}}
                value={selectedValue}
                isSearchable={false}
                isDisabled={disabled}
                placeholder={placeholder}
                onChange={selectedOption => input.onChange((selectedOption as any).value)}
                onBlur={() => input.onBlur(input.value)}
                options={options}
                noOptionsMessage={() => i18next.t('Nerasta')}
                className='dropdown-select'
                classNamePrefix='dropdown-select'
            />
            {meta.touched &&
            ((meta.error && <div className='feedback feedback-error'>{meta.error}</div>) ||
                (meta.warning && <div className='feedback feedback-warning'>{meta.warning}</div>))}
        </>
    );
};

export const renderInputWithAppend = (field) => {
    const {input, type, disabled = false, meta, className, id, onPaste, placeholder, autoComplete, ariaLabel, label } = field;
    const classNames = [className];
    if (meta.touched && (meta.error || meta.warning)) {
        classNames.push('invalid');
    }
    return (
        <>
            <div className='custom-input custom-input--with-append'>
                <input
                    {...input}
                    type={type}
                    className={classNames.join(' ')}
                    id={id}
                    onPaste={onPaste}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    aria-label={ariaLabel}
                />
                <label htmlFor={id}>{label}</label>
            </div>
            {meta.touched &&
            ((meta.error && <div className='feedback feedback-error'>{meta.error}</div>) ||
                (meta.warning && <div className='feedback feedback-warning'>{meta.warning}</div>))}
        </>
    );
};

export const renderInput = (field) => {
    const {input, type, disabled = false, meta, className, id, onPaste, placeholder, autoComplete, ariaLabel } = field;
    const classNames = [className];
    if (meta.touched && (meta.error || meta.warning)) {
        classNames.push('invalid');
    }
    if (meta.touched && !(meta.error || meta.warning)) {
        classNames.push('valid');
    }
    return (
        <>
            <input
                {...input}
                type={type}
                className={classNames.join(' ')}
                id={id}
                onPaste={onPaste}
                placeholder={placeholder}
                autoComplete={autoComplete}
                disabled={disabled}
                aria-label={ariaLabel}
            />
            {meta.touched &&
            ((meta.error && <div className='feedback feedback-error'>{meta.error}</div>) ||
                (meta.warning && <div className='feedback feedback-warning'>{meta.warning}</div>))}
        </>
    );
};

export const renderTextArea = (field) => {
    const {input, meta, className, id, onPaste, placeholder, rows} = field;
    const classNames = [className];
    if (meta.touched && (meta.error || meta.warning)) {
        classNames.push('invalid');
    }
    return (
        <>
            <textarea
                {...input}
                className={classNames.join(' ')}
                id={id}
                onPaste={onPaste}
                placeholder={placeholder}
                rows={rows}
            />
            {meta.touched &&
            ((meta.error && <div className='feedback feedback-error'>{meta.error}</div>) ||
                (meta.warning && <div className='feedback feedback-warning'>{meta.warning}</div>))}
        </>
    );
};


export const renderCheckbox = (field) => {
    const {input, type = 'checkbox', meta, className, id, label} = field;
    const _className = (meta.touched && meta.error) ? 'invalid' : '';
    return (
        <>
            <div className={_className}>
                <input {...input} type={type} className={className} id={id} />
                <label className='custom-control-label' htmlFor={id}>{label}</label>
            </div>
            {meta.touched &&
            ((meta.error && <div className='feedback feedback-error'>{meta.error}</div>) ||
                (meta.warning && <div className='feedback feedback-warning'>{meta.warning}</div>))}
        </>
    );
};


export const renderRadioButton = (field) => {
    const {input, meta, className, id, label, showError = true} = field;
    return (
        <>
            <div>
                <input type='radio' {...input} className={className} id={id} />
                <label className='custom-control-label' htmlFor={id}>{label}</label>
            </div>
            {showError && meta.touched &&
            ((meta.error && <div className='feedback feedback-error'>{meta.error}</div>) ||
                (meta.warning && <div className='feedback feedback-warning'>{meta.warning}</div>))}
        </>
    );
};


export const renderBankRadioButton = (field) => {
    const {input, meta, className, id, label} = field;
    return (
        <div className={className}>
            <input type='radio' {...input} id={id} />
            <label className='d-flex' htmlFor={id}>{label}</label>
            {field.description ? <div className='pr-4'>{field.description}</div> : null}
        </div>
    );
};

export const renderFileInput = (field) => {
    const {input, type, disabled = false, meta, className, label, ariaLabel } = field;
    const classNames = ['file-input', className];
    if (meta.touched && (meta.error || meta.warning)) {
        classNames.push('invalid');
    }
    if (meta.touched && !(meta.error || meta.warning)) {
        classNames.push('valid');
    }

    function handleInputChange(e) {
        input.onChange(e.target.files[0]);
    }
    return (
        <>
            <input
                onChange={handleInputChange}
                type={type}
                className={classNames.join(' ')}
                id={'file-input'}
                disabled={disabled}
                aria-label={ariaLabel}
            />
            <label htmlFor={'file-input'}>{!input.value ? i18next.t('Pasirink failą') : i18next.t('Failas pasirinktas')}</label>
            {meta.touched &&
            ((meta.error && <div className='feedback feedback-error'>{meta.error}</div>) ||
                (meta.warning && <div className='feedback feedback-warning'>{meta.warning}</div>))}
        </>
    );
};
