
/* injects from baggage-loader */

import {RSAA, getJSON} from 'redux-api-middleware';
import {apiActionCreator} from '../utils/api/apiActionCreator';
import {API_URL} from '../utils/api/constants';

export const FAVORITE = {
    GET: 'GET_FAVORITE',
    DELETE: 'DELETE_FAVORITE',
    ADD: 'ADD_FAVORITE'
};

export function getFavorites() {
    return apiActionCreator({
        endpoint: API_URL + '/favorite',
        method: 'GET',
        type: FAVORITE.GET
    });

}

export function deleteFavorite(body) {
    return apiActionCreator({
        endpoint: API_URL + '/favorite',
        method: 'DELETE',
        type: FAVORITE.DELETE,
        body
    });
}

export function addFavorite(body) {
    return apiActionCreator({
        endpoint: API_URL + '/favorite',
        method: 'POST',
        type: FAVORITE.ADD,
        body
    });
}
