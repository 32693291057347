
/* injects from baggage-loader */

import idx from 'idx';

import {IReduxStore} from '../contracts/store';
import { IPriceCategory } from '../contracts/tickets';

export const isConcatMode = (state: IReduxStore): boolean => {
    const venueSettings: Array<object> = state.venue && state.venue.venue && state.venue.venue.settings || [];

    return venueSettings.some((setting: any) => {
        return setting.isConcatMode;
    });
};

export const isClubTicketsAvailable = (state: IReduxStore): boolean => {
    return state.venue && state.venue.clubTicketsAvailable;
};

export const isClubTickets = (state: IReduxStore): boolean => {
    return state.venue && state.venue.clubTickets;
};

export const getLockedSpaceCode = (state: IReduxStore): string => {
    if (state.form.apply_locked_code && state.form.apply_locked_code.values && Object.values(state.form.apply_locked_code.values)[0]) {
        return Object.values(state.form.apply_locked_code.values)[0];
    }
}

export const hasAdditionalServices = (state: IReduxStore): boolean => {
    return (
        idx(state, _ => _.venue.priceCategories) &&
        state.venue.priceCategories.some(
            (priceCategory) => (
                priceCategory.venueSectors &&
                priceCategory.venueSectors.some((venueSector) => venueSector.additionalService)
            )
        )
    );
};

export const getAdditionalServices = (state: IReduxStore): Array<IPriceCategory> => {
    if (!hasAdditionalServices(state)) {
        return;
    }
    return state.venue.priceCategories.filter(
        (priceCategory) => priceCategory.venueSectors.every((venueSector) => venueSector.additionalService)
    );
};

export const getPriceCategoriesWithoutAdditionalServices = (state: IReduxStore): Array<IPriceCategory> => {
    if (!hasAdditionalServices(state)) {
        return idx(state, _ => _.venue.priceCategories) ? state.venue.priceCategories : null;
    }
    return state.venue.priceCategories.filter(
        (priceCategory) => priceCategory.venueSectors.some((venueSector) => !venueSector.additionalService)
    );
};

export const isNotAllPricesFree = (state: IReduxStore): boolean => {
    const priceCategories = idx(state, _ => _.venue.priceCategories) ? state.venue.priceCategories : [];
    return priceCategories.some((priceCategory) => {
        return priceCategory.priceWithDiscount > 0;
    });
};

