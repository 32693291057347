
/* injects from baggage-loader */

import {IVoucher, IVoucherCartItem} from '../../contracts/store';

export const reindexAndGroupCartVouchers = (cartVouchers: Array<IVoucherCartItem>) => {
    if (!Array.isArray(cartVouchers)) {
        return {};
    }
    return cartVouchers.reduce((vouchers, voucher) =>
        Object.assign(
            vouchers,
            {[voucher.giftVoucherTemplateShortId]: [
                ...(vouchers[voucher.giftVoucherTemplateShortId] || []),
                voucher
            ]}
        ), {}
    );
};

export const getVoucherTitle = (voucher: IVoucher): string => {
    return voucher.nameNoFormating;
};

export const getVoucherMetaTitle = (voucher: IVoucher): string => {
    return voucher.metaTitle || voucher.nameNoFormating;
};
