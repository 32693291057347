
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {ORDER} from '../actions/order';
import {TICKET} from '../actions/ticket';

class BoxOffice extends BaseReducer {
    static defaultState = {
        isBoxOfficeMirror: false,
        isBoxOfficeAds: false,
        receipt: null,
        tickets: null,
        returnTickets: [],
    };

    constructor() {
        super(BoxOffice.defaultState);
        this.actionMap = {
            [ORDER.CLEAR_RECEIPT]: this.resetState.bind(this),
            [`${ORDER.GET_RECEIPT}_SUCCESS`]: this.setReceipt.bind(this),
            [`${TICKET.GET_RETURN_TICKETS}_SUCCESS`]: this.setReturnTickets.bind(this),
            [`${TICKET.ADD_RETURN_TICKETS}_SUCCESS`]: this.setReturnTickets.bind(this),
            [`${TICKET.REMOVE_RETURN_TICKETS}_SUCCESS`]: this.setReturnTickets.bind(this),
            [`${TICKET.APPROVE_TICKETS_RETURN}_SUCCESS`]: this.resetReturnTickets.bind(this),
            [`${TICKET.REJECT_TICKETS_RETURN}_SUCCESS`]: this.resetReturnTickets.bind(this),
            [TICKET.RESET_RETURN_TICKETS]: this.resetReturnTickets.bind(this),
        };
    }

    setReturnTickets(state, action) {
        return {
            ...state,
            returnTickets: action.payload.ticketReturns,
        };
    }

    resetReturnTickets(state) {
        return {
            ...state,
            returnTickets: []
        };
    }

    setReceipt(state, action) {
        return {
            ...state,
            receipt: action.payload.receipt,
            tickets: action.payload.tickets,
        };
    }

    resetState(state) {
        return {
            ...state,
            receipt: null,
            tickets: null,
        };
    }
}

export default new BoxOffice().reducer;
