
/* injects from baggage-loader */

import gql from 'graphql-tag';

export interface IThemeGQLParams {
    name: {
        iv: string;
    };
    headerHtml: {
        iv: string;
    };
    cartButtonTemplate: {
        iv: string;
    };
    footerHtml: {
        iv: string;
    };
    homeUrl: {
        iv: string;
    };
    stylesFile: {
        iv: Array<{
            url: string;
        }>;
    };
    scripts: {
        iv: string;
    };
    colorPrimary: {
        iv: string;
    };
    colorPrimaryHover: {
        iv: string;
    };
    jsLibraries: {
        iv: string;
    };
    enableEventPage: {
        iv: boolean;
    };
    favIcon: {
        iv: Array<{
            id: number;
        }>;
    };
    sandglassImage: {
        iv: Array<{
            id: number;
        }>;
    };
    trimEventTitle: {
        iv: boolean;
    };
}
export interface ITheme {
    queryMultichannelthemeContents: Array<{
        data: IThemeGQLParams
    }>;
}

export interface IThemeVariables {
    filter: string;
}

export const buildThemeFilter = (slug: string) => {
    return `data/name/iv eq '${slug}'`;
};

export const themeQuery = gql`
    query Theme($filter: String!) {
        queryMultichannelthemeContents(filter: $filter) {
            data {
                name {
                    iv
                }
                headerHtml {
                    iv
                }
                cartButtonTemplate {
                    iv
                }
                footerHtml {
                    iv
                }
                homeUrl {
                    iv
                }
                stylesFile {
                    iv {
                        url
                    }
                }
                scripts {
                    iv
                }
                colorPrimary {
                    iv
                }
                colorPrimaryHover {
                    iv
                }
                jsLibraries {
                    iv
                }
                enableEventPage {
                    iv
                }
                favIcon {
                    iv {
                        id
                    }
                }
                sandglassImage {
                    iv {
                        id
                    }
                }
                trimEventTitle {
                    iv
                }
            }
        }
    }
`;
