
/* injects from baggage-loader */

const CURRENT_URL: string = `${window.location.protocol}//${window.location.host}`;
const CDN_URL: string = 'https://res.cloudinary.com/KakavaLT/image/fetch/';

let URL: string = 'https://app-kkv-be-test.azurewebsites.net';
let API_URL: string = `${URL}/api/v1`;
let API_URL_V2: string = `${URL}/api/v2`;
let CMS_URL = 'https://app-kkv-squidex-test.azurewebsites.net';
let BOX_OFFICE_REGISTER = 'https://app-kkv-bo-staging.azurewebsites.net/scripts/cash-reg/receipt.js';
const JQUERY_URL = 'https://code.jquery.com/jquery-3.4.1.min.js';
let SQUIDEX_CLIENT_ID = 'kakava:frontend';
let SQUIDEX_CLIENT_SECRET = 'NnWxsI4v7VxcIbMATzntvUQkGG/3To52wctR/I78pfk=';

if (process.env.KK_ENV === 'staging') {
    URL = 'https://app-kkv-be-staging.azurewebsites.net';
    API_URL = `${URL}/api/v1`;
    API_URL_V2 = `${URL}/api/v2`;
    CMS_URL = 'https://app-kkv-squidex-staging.azurewebsites.net';
    SQUIDEX_CLIENT_ID = 'kakava:frontend';
    SQUIDEX_CLIENT_SECRET = '6It3ZyknXYwaQ9Xloqdho2HViBmpxgP9OOBlL0ry5Ls=';
} else if (process.env.KK_ENV === 'production') {
    SQUIDEX_CLIENT_ID = 'kakava:frontend';
    SQUIDEX_CLIENT_SECRET = 'qKz0VgAUvAgZ0L1jvNqEbDLjeiRanq/6xrAFQ61lCNA=';
    URL = 'https://api.kakava.lt';
    API_URL = `${URL}/api/v1`;
    API_URL_V2 = `${URL}/api/v2`;
    CMS_URL = 'https://cms.kakava.lt';
    BOX_OFFICE_REGISTER = 'https://app-kkv-bo-staging.azurewebsites.net/scripts/cash-reg/receipt.js';
}

const GRAPH_QL_URI: string = CMS_URL + '/api/content/kakava/graphql';

enum STATIC_PAGE_TYPES {
    RULES = 'RULES'
}

export {
    URL,
    API_URL,
    API_URL_V2,
    CMS_URL,
    GRAPH_QL_URI,
    STATIC_PAGE_TYPES,
    CURRENT_URL,
    CDN_URL,
    BOX_OFFICE_REGISTER,
    JQUERY_URL,
    SQUIDEX_CLIENT_ID,
    SQUIDEX_CLIENT_SECRET
};
