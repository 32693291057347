
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {EVENT} from '../actions/event';
import {normalizeEventBlock} from '../utils/event/normalizeEventBlock';
import {SHOW} from '../actions/show';

class Events extends BaseReducer {
    constructor() {
        super({
            byId: {},
            // ids
            home: [],
            club: [],
            groups: {},
        });
        this.actionMap = {
            [EVENT.GET_EVENTS_SUCCESS]: this.getEvents.bind(this),
            [`${EVENT.GET_EVENT}_SUCCESS`]: this.getEvent.bind(this),
            [`${EVENT.GET_EVENT_BLOCK}_SUCCESS`]: this.getEventBlock.bind(this),
            [EVENT.CLEAR_CLUB_LODGE_EVENT_BLOCKS]: this.clearClubLodgeEventBlocks.bind(this),
            [`${EVENT.GET_CLUB_EVENT_BLOCKS}_SUCCESS`]: this.getEventBlock.bind(this),
            [`${EVENT.GET_GROUP_EVENTS}_SUCCESS`]: this.getGroupEvents.bind(this),
            [SHOW.GET_SHOWS_SUCCESS]: this.getEventsByShowCategory.bind(this),
        };
    }

    getGroupEvents(state, action) {
        if (!action.payload.events.length) {
            return state;
        }
        let eventByIds = {};
        eventByIds = action.payload.events.reduce((events, event) => {
            events[event.shortId] = event;
            return events;
        }, eventByIds);

        const events = action.payload.events;
        return {
            ...state,
            byId: {
                ...state.byId,
                ...eventByIds,
            },
            groups: {
                ...state.groups,
                [events[0].eventGroup.shortId]: action.payload.events.map(event => event.shortId),
            }
        };
    }

    getEventBlock(state, action) {
        if (!action.payload.eventBlocks) {
            return state;
        }
        let events = {};
        action.payload.eventBlocks.forEach((eventBlock) => {
            if (eventBlock.events) {
                events = eventBlock.events.reduce((events, {event}) => {
                    events[event.shortId] = event;
                    return events;
                }, events);
            }
        });

        const home = [];
        const club = [];

        switch (action.type) {
            case `${EVENT.GET_EVENT_BLOCK}_SUCCESS`:
                club.push(...state.club);
                break;
            case `${EVENT.GET_CLUB_EVENT_BLOCKS}_SUCCESS`:
                home.push(...state.home);
                break;
        }

        // normalize ids
        action.payload.eventBlocks.forEach((eventBlock) => {
            const blockEvent = normalizeEventBlock(eventBlock);
            if (blockEvent.events || blockEvent.shows) {
                switch (action.type) {
                    case `${EVENT.GET_EVENT_BLOCK}_SUCCESS`:
                        home.push(blockEvent);
                        break;
                    case `${EVENT.GET_CLUB_EVENT_BLOCKS}_SUCCESS`:
                        club.push(blockEvent);
                        break;
                }
            }
        });

        return {
            ...state,
            byId: {
                ...state.byId,
                ...events
            },
            home,
            club,
        };
    }

    clearClubLodgeEventBlocks(state, action) {
        return {
            ...state,
            home: [],
            club: [],
        };
    }

    getEvents(state, action) {
        if (!action.payload.events) {
            return state;
        }
        const events = action.payload.events.reduce((events, event) => {
            events[event.shortId] = event;
            return events;
        }, {});

        return {
            ...state,
            byId: {
                ...state.byId,
                ...events
            },
            [action.payload.eventType]: action.payload.events.map((event) => event.shortId)
        };
    }

    getEvent(state, action) {
        return {
            ...state,
            byId: {
                ...state.byId,
                [action.payload.shortId]: action.payload
            }
        };
    }

    /**
     * When event blocks comes with show response, we normalize events by id here
     * @param state
     * @param action
     */
    getEventsByShowCategory(state, action) {
        if (!action.payload.eventBlocks || action.payload.eventBlocks.length <=0) {
            return state;
        }
        let events = {};
        action.payload.eventBlocks.forEach((eventBlock) => {
            if (eventBlock.events) {
                events = eventBlock.events.reduce((events, {event}) => {
                    events[event.shortId] = event;
                    return events;
                }, events);
            }
        });
        return {
            ...state,
            byId: {
                ...state.byId,
                ...events
            }
        };
    }

}

export default new Events().reducer;
