
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import { CART } from '../actions/cart';
import { USER } from '../actions/user';
import { CartStatus } from '../contracts/cart';
import { reindexAndGroupCartVouchers } from '../utils/vouchers/utils';
import { VOUCHER } from '../actions/voucher';

class Cart extends BaseReducer {
  static defaultState = {
    timeLeft: 0,
    status: 0,
  };
  constructor() {
    super(Cart.defaultState);
    this.actionMap = {
      [`${CART.GET}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.SET_DISCOUNT}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.ADD}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.ADD_VOUCHERS}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.DELETE}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.INSURANCE}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.DELETE_VOUCHERS}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.CANCEL_BUY}_SUCCESS`]: this.changeCartStatus.bind(this),
      [CART.CLEAR_CART]: this.clearCart.bind(this),
      [`${CART.GET}_FAILURE`]: this.clearCart.bind(this),
      [USER.LOGOUT]: this.clearCart.bind(this),
      [`${VOUCHER.APPLY_VOUCHERS}_SUCCESS`]: this.getCart.bind(this),
      [`${VOUCHER.REMOVE_VOUCHERS}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.ADD_PHYSICAL_DELIVERY}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.REMOVE_PHYSICAL_DELIVERY}_SUCCESS`]: this.getCart.bind(this),
      [`${CART.GET_PAYMENT_STATUS}_SUCCESS`]: this.getCart.bind(this),
    };
  }

  changeCartStatus(state) {
    return {
      ...state,
      status: CartStatus.VALID,
    };
  }

  getCart(state, action) {
    return {
      ...action.payload,
      giftVouchers: reindexAndGroupCartVouchers(action.payload.giftVouchers),
    };
  }

  clearCart() {
    return Cart.defaultState;
  }
}

export default new Cart().reducer;
