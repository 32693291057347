
/* injects from baggage-loader */

import * as React from 'react';
import {Modal} from 'reactstrap';

interface IMessageModal {
    code?: number;
    title: string;
    message: string;
    onClose: () => void;
    button?: JSX.Element;
}

export class MessageModal extends React.Component<IMessageModal, {}> {
    render() {
        let className = 'modal-message';
        let modalClassName = '';
        if (this.props.code && this.props.code === 200) {
            // or we can build className with code, ex: className = `code-${this.props.code}`
            className = 'success';
            modalClassName += ' modal-success';
        }
        return (
            <Modal isOpen={true} className={modalClassName} toggle={this.props.onClose}>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={this.props.onClose}>
                    <span aria-hidden='true'>&times;</span>
                </button>
                <div className='modal-body'>
                    <div className='text-center'>
                        {this.props.title && <h2 className={className}>{this.props.title}</h2>}
                    </div>
                    <div className='text-center'>
                        {this.props.message}
                    </div>
                    {this.props.button && <div className='mt-4 text-center'>
                        {this.props.button}
                    </div>}
                </div>
            </Modal>
        );
    }
}
