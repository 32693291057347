
/* injects from baggage-loader */

const fileDownload = require('js-file-download');
import {apiActionCreator} from '../utils/api/apiActionCreator';
import {history} from '../history';
import {API_URL} from '../utils/api/constants';
import {
    IDiscountRequest,
    IPickMultipleTicketsRequest,
    IPickTicketRequest,
    IRemoveDiscountRequest,
    IRemoveTicketRequest
} from '../contracts/tickets';
import {hideTopModal} from './modal';
import {getVenue} from './venue';
import { isBoxOfficeUser } from '../selectors/user';
import {isClubTickets, getLockedSpaceCode} from '../selectors/venue';
import { getRecaptchaToken } from '../selectors/tickets';
import { isRecaptchaEnabled } from '../selectors/settings';
import { isTicketAllowed } from '../utils/recaptcha';
import {urls} from '../routes';

import {getPreSaleCode} from '../selectors/shows';
import {setApiMessage} from "./message";

export const TICKET = {
    ADD_TICKET: 'ADD_TICKET',
    CHANGE_TICKET: 'CHANGE_TICKET',
    REMOVE_TICKET: 'REMOVE_TICKET',
    UPDATE_TICKETS: 'UPDATE_TICKETS',
    SET_DISCOUNT: 'SET_TICKET_DISCOUNT',
    REMOVE_DISCOUNT: 'REMOVE_DISCOUNT',
    RESET_TICKETS: 'RESET_TICKETS',
    RESET_API_TICKETS: 'RESET_API_TICKETS',
    ADD_RETURN_TICKETS: 'ADD_RETURN_TICKETS',
    GET_RETURN_TICKETS: 'GET_RETURN_TICKETS',
    REMOVE_RETURN_TICKETS: 'REMOVE_RETURN_TICKETS',
    APPROVE_TICKETS_RETURN: 'APPROVE_TICKETS_RETURN',
    REJECT_TICKETS_RETURN: 'REJECT_TICKETS_RETURN',
    GET_RETURN_TICKETS_SUMMARY: {
        REQUEST: 'GET_RETURN_TICKETS_SUMMARY_REQUEST',
        SUCCESS: 'GET_RETURN_TICKETS_SUMMARY_SUCCESS',
        FAILURE: 'GET_RETURN_TICKETS_SUMMARY_FAILURE',
    },
    RESET_RETURN_TICKETS: 'RESET_RETURN_TICKETS',
    TICKET_RETURN_REQUEST: 'TICKET_RETURN_REQUEST', // for user to fill form
    ADD_RECAPTCHA_TOKEN: 'ADD_RECAPTCHA_TOKEN'
};

export function resetTickets() {
    return {
        type: TICKET.RESET_TICKETS
    };
}

export function resetApiTickets(body: IRemoveTicketRequest) {
    return (dispatch, getState) => {
        const state = getState();
        body.boxOffice = isBoxOfficeUser(state);
        dispatch(apiActionCreator({
            endpoint: API_URL + '/ticket',
            type: TICKET.RESET_API_TICKETS,
            method: 'DELETE',
            body
        }));
    };
}

export function changeTicket(body: IPickTicketRequest) {
    return (dispatch, getState) => {
        const state = getState();
        body.boxOffice = isBoxOfficeUser(getState());
        if(isTicketAllowed(body.boxOffice, isRecaptchaEnabled(getState()), getRecaptchaToken(getState()))) {
            dispatch(apiActionCreator({
                endpoint: API_URL + '/ticket',
                type: TICKET.CHANGE_TICKET,
                method: 'POST',
                body: {
                    ...body,
                    RecaptchaResponse: getRecaptchaToken(state)
                },
                onSuccess: () => {
                    dispatch(setRecaptchaToken(''));
                },
                onError: () => {
                    dispatch(setRecaptchaToken(''));
                }
            }));
        }
    };
}

export function updateTickets(body: IPickMultipleTicketsRequest) {
    return (dispatch, getState) => {
        const state = getState();
        body.boxOffice = isBoxOfficeUser(state);
        if (isClubTickets(state)) {
            body.takeClubTickets = true;
            body.clubCode = state.venue.clubCode;
        }
        if (getLockedSpaceCode(state)) {
            body.lockedSpaceCode = getLockedSpaceCode(state);
        }
        const presaleCode = getPreSaleCode(state, body.showId);
        if (presaleCode) {
            body.presaleCode = presaleCode;
        }
        if(isTicketAllowed(body.boxOffice, isRecaptchaEnabled(getState()), getRecaptchaToken(getState()))){
            dispatch(apiActionCreator({
                endpoint: API_URL + '/ticket',
                type: TICKET.UPDATE_TICKETS,
                method: 'PUT',
                body: {
                    ...body,
                    RecaptchaResponse: getRecaptchaToken(state)
                },
                onSuccess: () => {
                    dispatch(setRecaptchaToken(''));
                },
                onError: (args) => {
                    dispatch(setRecaptchaToken(''));
                    const showGuid = args.showId;
                    if (showGuid) {
                        dispatch(getVenue(showGuid));
                    }
                }
            }));
        }
    };
}

export function addTicket(body: IPickTicketRequest) {
    return (dispatch, getState) => {
        const state = getState();
        body.boxOffice = isBoxOfficeUser(state);
        if (isClubTickets(state)) {
            body.takeClubTickets = true;
            body.clubCode = state.venue.clubCode;
        }
        const presaleCode = getPreSaleCode(state, body.showId);
        if (presaleCode) {
            body.presaleCode = presaleCode;
        }
        if(isTicketAllowed(body.boxOffice, isRecaptchaEnabled(getState()), getRecaptchaToken(getState()))){
            dispatch(apiActionCreator({
                endpoint: API_URL + '/ticket',
                type: TICKET.ADD_TICKET,
                method: 'POST',
                body: {
                    ...body,
                    RecaptchaResponse: getRecaptchaToken(state)
                },
                onSuccess: (args) => {
                    if (args.error) {
                        dispatch(setApiMessage(
                            TICKET.ADD_TICKET,
                            '',
                            200,
                            args.error,
                        ));
                    }
                    dispatch(setRecaptchaToken(''));
                },
                onError: (args) => {
                    dispatch(setRecaptchaToken(''));
                    const showGuid = args.showId;
                    if (showGuid) {
                        dispatch(getVenue(showGuid));
                    }
                }
            }));
        }
    };
}

export function removeTicket(body: IRemoveTicketRequest) {
    return (dispatch, getState) => {
        const state = getState();
        body.boxOffice = isBoxOfficeUser(state);
        if (isClubTickets(state)) {
            body.takeClubTickets = true;
            body.clubCode = state.venue.clubCode;
        }
        const presaleCode = getPreSaleCode(state, body.showId);
        if (presaleCode) {
            body.presaleCode = presaleCode;
        }
        dispatch(apiActionCreator({
            endpoint: API_URL + '/ticket',
            type: TICKET.REMOVE_TICKET,
            method: 'DELETE',
            body
        }));
    };
}

export function setDiscount(body: IDiscountRequest) {
    return (dispatch, getState) => {
        body.boxOffice = isBoxOfficeUser(getState());
        dispatch(apiActionCreator({
            endpoint: API_URL + '/ticket/discount',
            type: TICKET.SET_DISCOUNT,
            method: 'PUT',
            body,
            onSuccess: () => {
                dispatch(hideTopModal());
            }
        }));
    };
}

export function removeDiscount(body: IRemoveDiscountRequest) {
    return apiActionCreator({
        endpoint: API_URL + '/ticket/discount',
        type: TICKET.REMOVE_DISCOUNT,
        method: 'DELETE',
        body
    });
}

export function addReturnTickets(tickets: Array<number>, boxOffice: boolean = true) {
    return apiActionCreator({
        endpoint: API_URL + '/ticket/return',
        method: 'PUT',
        type: TICKET.ADD_RETURN_TICKETS,
        body: {
            tickets,
            boxOffice,
        }
    });
}

export function getReturnTickets(boxOffice: boolean = true) {
    return apiActionCreator({
        endpoint: API_URL + '/ticket/return',
        method: 'GET',
        type: TICKET.GET_RETURN_TICKETS,
        body: {
            boxOffice
        }
    });
}

export function removeReturnTickets(tickets: Array<string>, boxOffice: boolean = true) {
    return apiActionCreator({
        endpoint: API_URL + '/ticket/return',
        method: 'DELETE',
        type: TICKET.REMOVE_RETURN_TICKETS,
        body: {
            tickets,
            boxOffice,
        }
    });
}

export function approveTicketsReturn(tickets: Array<string>, boxOffice: boolean = true) {
    return apiActionCreator({
        endpoint: API_URL + '/ticket/return/approve',
        method: 'POST',
        type: TICKET.APPROVE_TICKETS_RETURN,
        body: {
            tickets,
            boxOffice
        },
        onSuccess: () => {
            history.push(urls.get('home'));
        }
    });
}

export function rejectTicketsReturn(tickets: Array<string>, boxOffice: boolean = true) {
    return apiActionCreator({
        endpoint: API_URL + '/ticket/return/reject',
        method: 'POST',
        type: TICKET.APPROVE_TICKETS_RETURN,
        body: {
            tickets,
            boxOffice
        }
    });
}

export function getReturnTicketsSummary(boxOffice: boolean = true) {
    return apiActionCreator({
        endpoint: API_URL + '/ticket/return/summary',
        method: 'GET',
        type: [
            TICKET.GET_RETURN_TICKETS_SUMMARY.REQUEST,
            {
                type: TICKET.GET_RETURN_TICKETS_SUMMARY.SUCCESS,
                payload: (action, state, res) => {
                    return res.blob().then((blob) => {
                        fileDownload(blob, 'tickets-return-summary.pdf');
                    });
                }
            },
            TICKET.GET_RETURN_TICKETS_SUMMARY.FAILURE
        ]
    });
}

export function resetReturnTickets() {
    return {
        type: TICKET.RESET_RETURN_TICKETS
    };
}

export function createTicketReturnRequest(ticketReturnRequest) {
    return apiActionCreator({
        endpoint: API_URL + '/ticket/return/request',
        method: 'PUT',
        body: ticketReturnRequest,
        type: TICKET.TICKET_RETURN_REQUEST,
    });
}

export function setRecaptchaToken(token) {
    return (dispatch) => {
        dispatch({
            type: TICKET.ADD_RECAPTCHA_TOKEN,
            token
        })
    };
}
