
/* injects from baggage-loader */

import * as React from 'react';
import { history } from '../../../../history';
import { urls } from '../../../../routes';
import { getTheme, removeTheme, isThemeRequired, isThemeLoaded } from '../../../../utils/theme';
import browserStorageUtils from '../../../../utils/browserStorage/utils';

const sessionStorage = browserStorageUtils.getSessionStorage();
export function themeEventRedirect(Component) {
    return class extends React.Component {
        render() {
            if (isThemeRequired() && isThemeLoaded()) {
                const themeParams = getTheme();

                if (!themeParams.enableEventPage) {
                    window.location.href = themeParams.homeUrl;
                    removeTheme(false);
                    return null;
                }
            }
            return <Component {...this.props} />;
        }
    };
}
