
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import { SETTINGS } from '../actions/settings';

class Settings extends BaseReducer {
    static defaultState = {
        recaptchaEnabled: false
    };

    constructor() {
        super(Settings.defaultState);
        this.actionMap = {
            [SETTINGS.SET_SETTINGS]: this.setSettings.bind(this),
        };
    }

    setSettings(state, action) {
        return action.settings;
    }
}

export default new Settings().reducer;
