
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import {CART} from '../actions/cart';

class PaymentTypes extends BaseReducer {
    static defaultState = null;
    constructor() {
        super(PaymentTypes.defaultState);
        this.actionMap = {
            [`${CART.GET_PAYMENT_TYPES}_SUCCESS`]: this.getPaymentTypes.bind(this)
        };
    }
    getPaymentTypes(state, action) {
        let paymentTypes = [];
        if (action.payload.paymentTypes && action.payload.paymentTypes.length > 0) {
            paymentTypes = action.payload.paymentTypes.filter((paymentType) => {
                return paymentType.paymentType !== 'LUMINOR_NORDEA_BANKLINK';
            });
        }
        return {
            ...action.payload,
            paymentTypes: [
                ...paymentTypes
            ]
        };
    }
}

export default new PaymentTypes().reducer;
