
/* injects from baggage-loader */

import {API_URL} from '../utils/api/constants';
import {apiActionCreator} from '../utils/api/apiActionCreator';

export const SETTINGS = {
    GET_SETTINGS: 'GET_SETTINGS',
    SET_SETTINGS: 'SET_SETTINGS',
};

export function getSettings():any {
    return (dispatch) => {
        dispatch(apiActionCreator({
            endpoint: API_URL + '/system/settings',
            type: SETTINGS.GET_SETTINGS,
            method: 'GET',
            onSuccess: (args) => {
                if (args) {
                    dispatch(setSettings(args))
                }
            },
        }));
    };
}

export function setSettings(settings) {
    return (dispatch) => {
        dispatch({
            type: SETTINGS.SET_SETTINGS,
            settings
        })
    };
}
