
/* injects from baggage-loader */

import BaseReducer from './baseReducer';
import { VOUCHER } from '../actions/voucher';
import { CLASSIFICATION } from '../actions/classification';
import { reindexAndGroupCartVouchers } from '../utils/vouchers/utils';
import {EVENT} from '../actions/event';
import {IVoucher} from '../contracts/store';

class Vouchers extends BaseReducer {
    constructor() {
        super({
            category: null,
            byId: {},
            ids: [],
            selectedVouchers: [],
            home: [],
            search: {},
        });
        this.actionMap = {
            [`${VOUCHER.GET_VOUCHERS}_SUCCESS`]: this.getVouchers.bind(this),
            [`${VOUCHER.GET_SINGLE_VOUCHER}_SUCCESS`]: this.getSingleVoucher.bind(this),
            [`${VOUCHER.GET_SELECTED_VOUCHERS}_SUCCESS`]: this.updateSelectedVouchers.bind(this),
            [`${VOUCHER.SELECT_VOUCHERS}_SUCCESS`]: this.updateSelectedVouchers.bind(this),
            [VOUCHER.RESET_SELECTED_VOUCHERS]: this.resetSelectedVouchers.bind(this),
            [`${CLASSIFICATION.GET_CATEGORIES}_SUCCESS`]: this.setVouchersCategory.bind(this),
            [`${EVENT.GET_CLUB_EVENT_BLOCKS}_SUCCESS`]: this.getHomeVouchersBlock.bind(this),
            [`${EVENT.GET_EVENT_BLOCK}_SUCCESS`]: this.getHomeVouchersBlock.bind(this),
            [VOUCHER.SEARCH_VOUCHERS]: this.setSearchVoucher.bind(this),
        };
    }

    setSearchVoucher(state, action) {
        if (action.giftVouchers.length <= 0) {
            return state;
        }

        const vouchers = action.giftVouchers.reduce((vouchers, voucher) => {
            vouchers[voucher.shortId] = voucher;
            return vouchers;
        }, {});

        const ids = action.giftVouchers.map((voucher) => voucher.shortId);

        return {
            ...state,
            byId: {
                ...state.byId,
                ...vouchers
            },
            search: {
                [action.searchHash]: ids
            },
            ids: [],
        };

    }

    getHomeVouchersBlock(state, action) {
        const homeBlock: Array<{
            title: string;
            giftVouchers: Array<IVoucher>;
        }> = (action.payload.eventBlocks || []).filter((block) => {
            return Array.isArray(block.giftVouchers);
        });

        if (!homeBlock || !homeBlock.length) {
            return state;
        }
        if (homeBlock[0].giftVouchers.length <= 0) {
            return state;
        }

        const vouchers = homeBlock[0].giftVouchers;
        const home = [];
        const vouchersById = vouchers.reduce((vouchers, voucher) => {
            vouchers[voucher.shortId] = voucher;
            home.push(voucher.shortId);
            return vouchers;
        }, {});
        return {
            ...state,
            byId: {
                ...state.byId,
                ...vouchersById,
            },
            home,
        };
    }

    setVouchersCategory(state, action) {
        const category = action.payload.categories.find(category => category.isGiftVoucher === true);

        return {
            ...state,
            category,
        };
    }

    getVouchers(state, action) {
        if (!action.payload.giftVouchers) {
            return state;
        }
        const ids = action.payload.giftVouchers.map((voucher) => voucher.shortId);
        const vouchers = action.payload.giftVouchers.reduce((vouchers, voucher) => {
            vouchers[voucher.shortId] = voucher;
            return vouchers;
        }, {});

        return {
            ...state,
            byId: {
                ...state.byId,
                ...vouchers,
            },
            ids: state.ids.concat(ids),
        };
    }

    getSingleVoucher(state,action) {
        if (!action.payload.giftVoucher) {
            return state;
        }
        const vouchers = state.byId;
        vouchers[action.payload.giftVoucher.shortId] = action.payload.giftVoucher;
        return {
            ...state,
            byId: vouchers,
        };
    }

    updateSelectedVouchers(state, action) {
        if (!action.payload.giftVouchers) {
            return state;
        }

        const selectedVouchers = reindexAndGroupCartVouchers(action.payload.giftVouchers);
        return {
            ...state,
            selectedVouchers,
        };
    }

    resetSelectedVouchers(state, action) {
        if (!state.selectedVouchers.length) {
            return state;
        }
        return {
            ...state,
            selectedVouchers: [],
        };
    }
}

export default new Vouchers().reducer;
