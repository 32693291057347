
/* injects from baggage-loader */

import {RSAA, getJSON} from 'redux-api-middleware';
import {CMS_URL, SQUIDEX_CLIENT_ID, SQUIDEX_CLIENT_SECRET} from '../utils/api/constants';

export function getGraphQLToken(): any {
    const body = `grant_type=client_credentials&client_id=${encodeURIComponent(SQUIDEX_CLIENT_ID)}&client_secret=${encodeURIComponent(SQUIDEX_CLIENT_SECRET)}&scope=squidex-api`;
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({
            [RSAA]: {
                endpoint: CMS_URL + '/identity-server/connect/token',
                method: 'POST',
                types: [
                    'GRAPH_QL_ACCESS_TOKEN_REQUEST',
                    {
                        type: 'GRAPH_QL_ACCESS_TOKEN_SUCCESS',
                        payload: (action, state, res) => {
                            return getJSON(res).then(result => {
                                resolve(result.access_token);
                            });
                        }
                    },
                    {
                        type: 'GRAPH_QL_ACCESS_TOKEN_FAILURE',
                        meta: (action, state, res) => {
                            if (res) {
                                reject({
                                    status: res.status,
                                    statusText: res.statusText
                                });
                            } else {
                                reject({
                                    status: 'Network request failed'
                                });
                            }
                        },
                    }
                ],
                body,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
            }
        });
    });
}
