
/* injects from baggage-loader */

//This function was created for .pkpass/.pkpasses files, downloading them with js-file-download was not working correctly

const downloadFile = (blob: Blob, name: string) => {
    const fileURL = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = fileURL;
    link.download = name;
    link.click();
    document.removeChild(link);
}

export default downloadFile;